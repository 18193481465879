/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

export interface ITreeViewItem {
  text: string;
  value: any;
  disabled?: boolean;
  collapsed?: boolean;
  selected?: boolean;
  hideChildren?: boolean;
  children?: ITreeViewItem[];
}

export class TreeViewItem implements ITreeViewItem {
  constructor(
    public text: string,
    public value: any,
    public disabled?: boolean,
    public collapsed?: boolean,
    public selected?: boolean,
    public hideChildren?: boolean,
    public children?: ITreeViewItem[]
  ) {
    // nothing to do
  }
}
