/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
export interface IAlert {
  alertCode: string;
  alertText?: string;
  alertLevel?: AlertLevel;
  alertCountdown?: number;
  alertStart?: Date;
  alertEnd?: Date;
}

export enum AlertLevel {
  log,
  success,
  info,
  warning,
  error,
  prominent
}

export enum AlertCode {
  conferenceReportSuccess = "CONFERENCE_REPORT_SUCCESS",
  dateFailed = "DATE_FAILED",
  getSettingsDataError = "GET_SETTINGS_DATA_ERROR",
  getSettingsDataSuccess = "GET_SETTINGS_DATA_SUCCESS",
  loginFailed = "LOGIN_FAILED",
  wait = "WAIT",
  checkConnectingEp = "CHECK_CONNECTING_EP",
  getGeoLocationError = "GET_GEOLOCATION_ERROR",
  voiceServiceReady = "VOICE_SERVICE_READY",
  voiceServiceOffline = "VOICE_SERVICE_OFFLINE",
  voiceServiceError = "VOICE_SERVICE_ERROR",
  voiceServiceNotReady = "VOICE_SERVICE_NOT_READY",
  newConferenceState = "NEW_CONFERENCE_STATE:",
  snapshotFailed = "SNAPSHOT_FAILED",
  snapshotSuccess = "SNAPSHOT_SUCCESS",
  snapshotProgressing = "SNAPSHOT_PROGRESSING",
  failToGainMediaAccess = "FAIL_TO_GAIN_MEDIA_ACCESS",
  callInvitationReceived = "CALL_INVITATION_RECEIVED",
  callInvitationRevoked = "CALL_INVITATION_REVOKED",
  proceedTheCallInvitation = "PROCEED_THE_CALL_INVITATION",
  sendLogsSuccess = "SEND_LOGS_SUCCESS",
  sendLogsError = "SEND_LOGS_ERROR",
  geoAccessSuccess = "GEO_ACCESS_SUCCESS",
  geoAccessFail = "GEO_ACCESS_FAIL",
  geoAccessNotPermit = "GEO_ACCESS_NOT_PERMIT",
  mapCenterUpdate = "MAP_CENTER_UPDATE",
  getLocalizationDataSuccess = "GET_LOCALIZATION_DATA_SUCCESS",
  getLocalizationDataFail = "GET_LOCALIZATION_DATA_FAIL",
  failToLoadConferencePage = "FAIL_TO_LOAD_CONFERENCE_PAGE",
  fileReceived = "FILE_RECEIVED",
  fileSent = "FILE_SENT",
  fileProgress = "FILE_PROGRESS",
  roomLeave = "ROOM_LEAVE",
  conferenceStateUpdate = "CONFERENCE_STATE_UPDATE",
  getGuestDataFailed = "GET_GUEST_DATA_FAILED",
  failToGetThemesByPasscode = "FAIL_TO_GET_THEMES_BY_PASSCODE",
  getReportCSVFailed = "GET_REPORT_CSV_FAILED",
  getDashboardFailed = "GET_DASHBOARD_FAILED",
  getServerTimeFailed = "GET_SERVER_TIME_FAILED",
  reportPcStatsFailed = "REPORT_PC_STATS_FAILED",
  mediaStreamLoaded = "MEDIA_STREAM_LOADED",
  getMediaFailed = "GET_MEDIA_FAILED",
  recordUploading = "RECORD_UPLOADING",
  recordUploadSuccess = "RECORD_UPLOAD_SUCCESS",
  recordUploadFail = "RECORD_UPLOAD_FAIL",
  recordFinished = "RECORD_FINISHED",
  recordFinishFail = "RECORD_FINISH_FAIL",
  getPresenceStatusByNameFailed = "GET_PRESENCE_STATUS_BY_NAME_FAILED",
  join = "JOIN_QUEUE",
  joinQueueFail = "JOIN_QUEUE_FAIL",
  joinQueueSuccess = "JOIN_QUEUE_SUCCESS",
  local = "LOCAL_VIDEO_UPDATE",
  message = "MESSAGE_RECEIVED",
  tryRenegotiate = "TRY_RENEGOTIATE",
  tryReconnect = "TRY_RECONNECT",
  callFailedReconnect = "CALL_FAILED_RECONNECT",
  hangUpAndReconnect = "HANG_UP_AND_RECONNECT",
  hangUpAndReconnectFail = "HANG_UP_AND_RECONNECT_FAIL",
  serverConnected = "SERVER_CONNECTED",
  serverDisconnected = "SERVER_DISCONNECTED",
  lostServerConnection = "LOST_SERVER_CONNECTION",
  server = "SERVER_FAILED_TO_CONNECT",
  callFailed = "CALL_FAILED:",
  callDisconnected = "CALL_DISCONNECTED",
  ice = "ICE_STATE_CHANGED_FOR_RTC_ID",
  mediaConnectionLost = "MEDIA_CONNECTION_LOST",
  logAutomaticTrigger = "LOG_AUTOMATIC_TRIGGER",
  reportPresentStatusSuccess = "REPORT_PRESENT_STATUS_SUCCESS",
  reportPresentStatusFail = "REPORT_PRESENT_STATUS_FAIL",
  callEps = "CALL_EPS",
  streamReceived = "STREAM_RECEIVED",
  streamClosed = "STREAM_CLOSED",
  serverFailedToConnect = "SERVER_FAILED_TO_CONNECT",
  connectionStateChange = "CONNECTION_STATE_CHANGE",
  iceStateChange = "ICE_STATE_CHANGE",
  addToConferenceSuccess = "ADD_TO_CONFERENCE_SUCCESS",
  addToConferenceFail = "ADD_TO_CONFERENCE_FAIL",
  disconnectFromConferenceSuccess = "DISCONNECT_FROM_CONFERENCE_SUCCESS",
  disconnectFromConferenceFail = "DISCONNECT_FROM_CONFERENCE_FAIL",
  disconnectConferenceTimeout = "DISCONNECT_CONFERENCE_TIMEOUT",
  sendInvitationSuccess = "SEND_INVITATION_SUCCESS",
  sendInvitationFail = "SEND_INVITATION_FAIL",
  sendInvitationTimeout = "SEND_INVITATION_TIMEOUT",
  toggleMonitoringSuccess = "TOGGLE_MONITORING_SUCCESS",
  toggleMonitoringFail = "TOGGLE_MONITORING_FAIL",
  holdEndpointSuccess = "HOLD_ENDPOINT_SUCCESS",
  holdEndpointFail = "HOLD_ENDPOINT_FAIL",
  joinRoomSuccess = "JOIN_ROOM_SUCCESS",
  joinRoomFail = "JOIN_ROOM_FAIL",
  joinRoomRetry = "JOIN_ROOM_RETRY",
  leaveRoomSuccess = "LEAVE_ROOM_SUCCESS",
  leaveRoomFail = "LEAVE_ROOM_FAIL",
  conferenceNotificationSuccess = "CONFERENCE_NOTIFICATION_SUCCESS",
  conferenceNotificationFail = "CONFERENCE_NOTIFICATION_FAIL",
  setPresenceSuccess = "SET_PRESENCE_SUCCESS",
  setPresenceFail = "SET_PRESENCE_FAIL",
  setPresenceRetry = "SET_PRESENCE_RETRY",
  localVideoUpdate = "LOCAL_VIDEO_UPDATE",
  messageReceived = "MESSAGE_RECEIVED",
  leaveAndJoin = "LEAVE_AND_JOIN",
  notReady = "NOT_READY",
  requestInviteBroadcastSuccess = "REQUEST_INVITE_BROADCAST_SUCCESS",
  requestInviteBroadcastFail = "REQUEST_INVITE_BROADCAST_FAIL",
  readyToConnectSuccess = "READY_TO_CONNECT_SUCCESS",
  readyToConnectFail = "READY_TO_CONNECT_FAIL",
  getDevicesError = "GET_DEVICES_ERROR",
  deviceInfoUpdated = "DEVICE_INFO_UPDATED",
  add = "ADD",
  voiceParticipantIsOnHold = "VOICE_PARTICIPANT_IS_ON_HOLD",
  putOnHoldSuccess = "PUT_ON_HOLD_SUCCEEDED",
  putOnHoldFail = "PUT_ON_HOLD_FAILED",
  resumeFromHoldSuccess = "RESUME_FROM_HOLD_SUCCESS",
  resumeFromHoldFail = "RESUME_FROM_HOLD_FAIL",
  takeOverSuccess = "TAKE_OVER_SUCCESS",
  takeOverFail = "TAKE_OVER_FAIL",
  inviteToConnectSuccess = "INVITE_TO_CONNECT_SUCCESS",
  inviteToConnectFailed = "INVITE_TO_CONNECT_FAILED",
  joinedVoiceConference = "JOINED_VOICE_CONFERENCE",
  establishingConnectionWith = "ESTABLISHING_CONNECTION_WITH",
  checkAnyEndpointOnlineByThemeFailed = "CHECK_ANY_ENDPOINT_ONLINE_BY_THEME_FAILED",
  startBroadcastFailed = "START_BROADCAST_FAILED",
  socketAuthenticated = "SOCKET_AUTHENTICATED",
  makeCall = "MAKE_CALL",
  makeAllCalls = "MAKE_ALL_CALL",
  callTargets = "CALL_TARGETS",
  callTargetsFail = "CALL_TARGETS_FAIL",
  peerVideoChat = "PEER_VIDEO_CHAT",
  joinAccessibleQueuesFailed = "JOIN_ACCESSIBLE_QUEUES_FAILED",
  failToStartPresentation = "FAIL_TO_START_PRESENTATION",
  conferenceUpdateFailed = "CONFERENCE_UPDATE_FAILED",
  answerCallFailed = "ANSWER_CALL_FAILED",
  CategorySkillAddFailed = "CATEGORY_SKILL_ADD_FAILED",
  callbackFailed = "CALLBACK_FAILED"
}

export type AlertHandlerCallbackOptions = {
  seconds?: number, // Seconds to display the alert or leave to default implementation.
  dialogOptions?: BootboxDialogOptions<string>
};

export type AlertHandlerCallback = (
  alertCode: AlertCode,
  alertText?: string,
  alertLevel?: AlertLevel,
  options?: AlertHandlerCallbackOptions
) => void;
