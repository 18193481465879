/**
 * Copyright Compunetix Incorporated 2017-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
/**
 * config settings structure
 */
export interface IConfig {
  MachineId?: string;
  ICEConfig?: IICEConfig[];
  LogConfig?: ILogConfig;
  SSLConfig?: ISSLConfig;
  WebConfig?: IWebConfig;
  FTPConfig?: IFTPConfig;
  DBConfig?: IDBConfig;
  OutlookConfig?: IOutlookConfig;
  VccConfig?: IVccConfig;
  CollectLogs?: ICollectLogs;
}

/**
 * client settings structure
 */
export interface IClientSettings {
  VccConfig: IVccConfig;
  CollectLogs: ICollectLogs;
}

export interface IICEConfig {
  url?: string;
  urls?: string[];
  username?: string;
  credential?: string;
  authSecret?: string;
}

export interface ILogConfig {
  folderPath?: string;
  fileSeperateByDay?: boolean;
  maxFileSize?: number;
  maxFileCount?: number;
}

export interface ISSLConfig {
  cert?: string;
  key?: string;
  chain?: string;
}

export interface IWebConfig {
  Domain?: string;
  HttpPort?: number;
  HttpsPort?: number;
  PathRedirects?: { source?: string; target?: string }[];
  AppProxy?: { source?: string; target?: string }[];
  VersionNumber?: string;
  UseHttpRedirect?: boolean;
  UseSecureCookie?: boolean;
  StrictContentSecurity?: boolean;
  CorsWhiteList?: string[];
  CSPWhiteList?: string[];
  EmbedWhiteList?: string[];
  EntrySubmitLimiter?: number;
  SERVER_DISCONNECT_TIMEOUT?: number;
  SERVER_AUTH_SECRET?: string;
  URL_ENCRYPTION_KEY?: string;
}

export interface IFTPConfig {
  FTPProtocol?: string;
  FTPHost?: string;
  FTPPort?: number;
  FTPUsername?: string;
  FTPPassword?: string;
  FTPFolderPath?: string;
}

export declare interface IDBConfig {
  UsePubSub?: boolean;
  LogUserReports?: boolean;
  logClientEvents?: boolean;
  logChatMessages?: boolean;
  historicalTTL?: number;
}

/**
 * Config for the outlook addin.
 */
export interface IOutlookConfig {
  allowOutlookLogin?: boolean;
  appId?: string;
  appSecret?: string;
  // Microsoft tenant ID
  tenantId?: string;
}

export interface IVccConfig {
  enabled?: boolean;
  callRoutingType?: string;
  ringtonePanelDuration?: number;
  callsStatisticsSave?: boolean;
  callsStatisticsRate?: number;
  clientRefreshRate?: number;
  ewtMovingAverage?: number;
  ewtRefreshPeriod?: number;
  requireGuestEntryId?: boolean;
  redirectOnDenyEntryUrl?: string;
}

export interface ICollectLogs {
  machineId?: string;
  enabled?: boolean;
  mode?: CollectLogsMode;
  serverUrl?: string;
  authKey?: string;
  customer?: string;
  environment?: string;
  application?: string;
  maxItems?: number;
  easyrtc?: boolean;
  webrtcStatistics?: boolean;
  statFrequency?: number;
  peerConnection?: boolean;
  serverConnection?: boolean;
  iceNegotiation?: boolean;
  deviceAcquisition?: boolean;
  callCompleteTrace?: boolean;
}

export enum CollectLogsMode {
  Server2Server = "Server 2 Server",
  Client2Server = "Client 2 Server",
}

export interface IRTCConfig {
  bundlePolicy?: string
}

