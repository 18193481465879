/**
 * Copyright Compunetix Incorporated 2016-2021
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { ActivatedRoute } from "@angular/router";
import {Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild, SimpleChanges} from "@angular/core";
import {
  IRTCService,
  Companion,
  Browser,
  RecordMode,
  IUser,
  PresentationMode,
  VideoAspect
} from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { NavBarService, NavBarMenuItem, NavBarMenuItemKey, NavBarMenuPosition } from "./nav-bar.service";
import { Dispatcher, ActionType } from "../../shared/services/dispatcher";
import { AlertService } from "../../alert/alert.service";

@Component({
  selector: "nav-bar",
  templateUrl: "nav-bar.component.html",
  styleUrls: ["./nav-bar.scss"]
})

/**
 * top toolbar
 */
export class NavBarComponent implements OnInit {
  /**
   * flag if settings panel shown
   */
  settingsShown: boolean = false;

  /**
   * conference share url
   */
  shareUrl: string = "";

  /**
   * the current view style
   */
  style: string;

  /**
   * the current view mode
   */
  mode: string;

  /**
   * the current view language
   */
  language: string;

  /**
   * flag if share link view shown
   */
  shareLinkShown: boolean = false;

  /**
   * flag if mouse down on nav bar
   */
  isMouseDownOnNavBar: boolean = false;

  /**
   * nav bar drag offsets
   */
  navBarDragOffsets = {
    leftOffset: 0,
    rightOffset: 0,
    topOffset: 0,
    bottomOffset: 0
  };

  /**
   * reference to toolbar div
   */
  @ViewChild("toolbar") toolbar: ElementRef;

  /**
   * nav bar position
   */
  @Input() position: string;
  /**
   * flag if webrtc is supported in browser
   */
  @Input() isWebRTCSupport: boolean;

  /**
   * flag if advanced features should be shown
   */
  @Input() showAdvance: boolean;

  /**
   * flag if current endpoint is connected with server
   */
  @Input() connected: boolean;

  /**
   * amount of unread shared files
   */
  @Input() unreadFileCount: number;

  /**
   * total amount of shared files
   */
  @Input() totalFileCount: number;

  /**
   * view mode
   */
  @Input() viewMode: string;

  /**
   * flag if it's in locked mode
   */
  @Input() isLocked: boolean;

  /**
   * flag if it's in operator's chat room
   */
  @Input() isInOpRoom: boolean;

  /**
   * flag if it's recording
   */
  @Input() isRecording: boolean;

  /**
   * flag if toolbar shown
   */
  @Input()
  isNavShown: boolean = true;

  /**
   * flag if map shown
   */
  @Input()
  isMapShown: boolean;

  /**
   * flag if it's mobile app
   */
  @Input()
  isMobileApp: boolean;

  /**
   * height of window
   */
  @Input()
  changedWindowHeight: number;

  /**
   * toggleMic event emitter
   */
  @Output("toggleMic") toggleMicEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * toggleCamera event emitter
   */
  @Output("toggleCamera") toggleCameraEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * toggleSettingsPanel event emitter
   */
  @Output("toggleSettingsPanel") toggleSettingsPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * toggleParticipantPanel event emitter
   */
  @Output("toggleParticipantPanel") toggleParticipantPanelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
  /**
   * toggleIncomingQueue event emitter
   */
  @Output("toggleIncomingQueue")
  toggleIncomingQueueEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * toggleOperators event emitter
   */
  @Output("toggleOperators")
  toggleOperatorsEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * toggleScreenCapture event emitter
   */
  @Output("toggleScreenCapture") toggleScreenCaptureEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * togglePinpad event emitter
   */
  @Output("togglePinpad") togglePinpadEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * toggleFullScreen event emitter
   */
  @Output("toggleFullScreen") toggleFullScreenEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * toggleVideoAspect event emitter
   */
  @Output("toggleVideoAspect") toggleVideoAspectEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * leaveRoom event emitter
   */
  @Output("leaveRoom") leaveRoomEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * snapshot event emitter
   */
  @Output("snapshot") snapshotEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * toggleSelfView event emitter
   */
  @Output("toggleSelfView") toggleSelfViewEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * lock event emitter
   */
  @Output("lock") lockEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * unlock event emitter
   */
  @Output("unlock") unlockEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * enterOpRoom event emitter
   */
  @Output("enterOpRoom") enterOpRoomEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * startRecord event emitter
   */
  @Output("startRecord") startRecordEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * stopRecord event emitter
   */
  @Output("stopRecord") stopRecordEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * flag if this endpoint is currently persenting
   */
  get isPresenting(): boolean {
    return this.rtcService.rtcClient.screenShareEnabled;
  }

  /**
   * toggle map view emitter
   */
  @Output("toggleMap")
  toggleMapEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * toggle invite modal emitter
   */
  @Output("toggleInvite")
  toggleInviteEmitter: EventEmitter<void> = new EventEmitter<void>();

  /**
   * opAlert event emitter
   */
  @Output("opAlert")
  opAlertEmitter: EventEmitter<void> = new EventEmitter<void>();

  /**
   * joinQAQueue event emitter
   */
  @Output("joinQAQueue")
  joinQAQueueEmitter: EventEmitter<void> = new EventEmitter<void>();

  /**
   * leaveQAQueue event emitter
   */
  @Output("leaveQAQueue")
  leaveQAQueueEmitter: EventEmitter<void> = new EventEmitter<void>();

  rtcService: IRTCService = Companion.getRTCService();
  currentUser: IUser = Companion.getUserService().currentUser;

  get borderColor(): string {
    return this.localizationService.getValueByPath(".toolbar.borderColor");
  }

  get borderWidth(): string {
    return this.borderColor ? "5px" : "0px";
  }

  public NavBarMenuItemKey = NavBarMenuItemKey;
  public NavBarMenuPosition = NavBarMenuPosition;
  navbarTop = document.documentElement.clientHeight;

  /**
   * timer to periodically update toobar items every 0.3 min
   */
  private updateToolbarItemStatusInterval: any;

  constructor(
    public localizationService: LocalizationService,
    private route: ActivatedRoute,
    public navBarService: NavBarService,
    private alertService: AlertService
  ) {
    this.style = this.route.snapshot.params["style"] ? this.route.snapshot.params["style"] : "default";
    this.mode = this.route.snapshot.params["mode"];
    this.language = this.route.snapshot.params["language"] ? this.route.snapshot.params["language"] : "en";
    Dispatcher.register(ActionType.LeaveConference, this.leaveRoom.bind(this));
    Dispatcher.register(ActionType.ToggleIncomingQueuePanel, this.toggleIncomingQueue.bind(this));
    Dispatcher.register(ActionType.ToggleOperatorsPanel, this.toggleOperators.bind(this));
    Dispatcher.register(ActionType.ToggleShareScreen, this.toggleScreenCapture.bind(this));
    Dispatcher.register(ActionType.ToggleMapScreen, this.toggleMap.bind(this));
    Dispatcher.register(ActionType.ToggleSnapshot, this.snapshot.bind(this));
    Dispatcher.register(ActionType.ToggleAudio, this.toggleMic.bind(this));
    Dispatcher.register(ActionType.ToggleVideo, this.toggleCamera.bind(this));
    Dispatcher.register(ActionType.ToggleVirtualBackground, this.toggleVirtualBackground.bind(this));
    Dispatcher.register(ActionType.ToggleSettingsPanel, this.toggleSettings.bind(this));
    Dispatcher.register(ActionType.ToggleFullScreen, this.toggleFullScreen.bind(this));
    Dispatcher.register(ActionType.ToggleVideoAspect, this.toggleVideoAspect.bind(this));
    Dispatcher.register(ActionType.ToggleLock, this.toggleLock.bind(this));
    Dispatcher.register(ActionType.Lock, this.lock.bind(this));
    Dispatcher.register(ActionType.Unlock, this.unlock.bind(this));
    Dispatcher.register(ActionType.ToggleRecording, this.toggleRecording.bind(this));
    Dispatcher.register(ActionType.OpenDashboard, this.openDashboardTab.bind(this));
    Dispatcher.register(ActionType.ToggleOptimizeForVideo, this.toggleOptimizeForVideo.bind(this));

    this.updateToolbarItemStatus();
  }

  /**
   * trigger leave room
   */
  leaveRoom(): void {
    AlertService.createAlertWithButtons(
      this.localizationService.getValueByPath(".errorMessages.LEAVE_WARNING") || "Are you sure you want to leave?",
      {
        confirm: {
          label: this.localizationService.getValueByPath(".toolbar.toolbar_items.enter.altText") || "Leave",
          className: "btn-danger"
        },
        cancel: {
          label: this.localizationService.getValueByPath(".errorMessages.CANCEL") || "Cancel",
          className: "btn-default"
        }
      }
    ).then((result: boolean) => {
      if (result) {
        Companion.getConferenceService().preventReload = false;
        this.leaveRoomEmitter.emit("");
      }
    });
  }

  /**
   * toggle microphone
   */
  toggleMic() {
    this.toggleMicEmitter.emit(!this.rtcService.rtcClient.audioMuted);
  }

  /**
   * toggle camera
   */
  toggleCamera(): void {
    this.toggleCameraEmitter.emit(!this.rtcService.rtcClient.videoMuted);
  }

  /**
   * toggle virtual background effect
   */
   toggleVirtualBackground(): void {
    this.rtcService.toggleCameraVirtualBackground(!this.rtcService.rtcClient.filteredStreamEnabled);
  }

  /**
   * toggle settings panel
   */
  toggleSettings(): void {
    this.toggleSettingsPanelEmitter.emit(false);
  }

  /**
   * toggle participants panel
   */
  toggleParticipants(): void {
    this.toggleParticipantPanelEmitter.emit(false);
  }

  /**
   * toggle participants panel
   */
  toggleIncomingQueue(): void {
    this.toggleIncomingQueueEmitter.emit(false);
  }

  /**
   * toggle participants panel
   */
  toggleOperators(): void {
    this.toggleOperatorsEmitter.emit(false);
  }

  /**
   * toggle screen capture
   */
  toggleScreenCapture(): void {
    if ((navigator.mediaDevices as any).getDisplayMedia || Browser.whichBrowser() === "Firefox") {
      this.toggleScreenCaptureEmitter.emit(false);
    }
  }

  /**
   * toggle pin pad model
   */
  togglePinpad(): void {
    this.togglePinpadEmitter.emit(false);
  }

  /**
   * toggle full screen
   */
  toggleFullScreen(): void {
    if (!document["fullscreenElement"] && !document["webkitFullscreenElement"]) {
      Dispatcher.dispatch(ActionType.EnterFullScreen);
      this.navBarService.updateMenuItem({
        key: NavBarMenuItemKey.FullScreen,
        title: ".toolbar.toolbar_items.fullscreen.altText",
        defaultTitle: "Exit Full Screen",
        icon: "fas fa-compress"
      });
    } else {
      Dispatcher.dispatch(ActionType.ExitFullScreen);
      this.navBarService.updateMenuItem({
        key: NavBarMenuItemKey.FullScreen,
        title: ".toolbar.toolbar_items.fullscreen.text",
        defaultTitle: "Full Screen",
        icon: "fas fa-expand-arrows-alt"
      });
    }
  }

  /**
   * toggle video aspect
   */
  toggleVideoAspect(): void {
    this.toggleVideoAspectEmitter.emit(false);
    this.navBarService.updateMenuItem({
      key: NavBarMenuItemKey.VideoAspect,
      title: this.currentUser.preferedVideoAspect === VideoAspect.fill ?
        ".toolbar.toolbar_items.videoAspect.altText" : ".toolbar.toolbar_items.videoAspect.text",
      defaultTitle: "Video Aspect",
      icon: "fas fa-external-link-square-alt",
    });
  }

  /**
   * trigger take snapshot
   */
  snapshot(): void {
    this.snapshotEmitter.emit(null);
  }

  /**
   * trigger hide selfview
   */
  hideSelfView(): void {
    this.toggleSelfViewEmitter.emit(false);
  }

  /**
   * trigger show selfview
   */
  showSelfView(): void {
    this.toggleSelfViewEmitter.emit(true);
  }

  /**
   * trigger enter operator room
   */
  enterOpRoom(): void {
    this.enterOpRoomEmitter.emit(true);
  }

  /**
   * toggle recording mode
   */
  toggleRecording(): void {
    if (this.navBarService.viewModel.isRecording) {
      this.stopRecord();
    } else {
      this.startRecord();
    }
  }

  /**
   * trigger start recording
   */
  startRecord(): void {
    let messageBody: string = "";
    let currentAudioMode =
      RecordMode[
      (this.localizationService.myLocalizationData.record_panel.audioMode as keyof typeof RecordMode) || "both"
      ];
    let currentVideoMode =
      RecordMode[
      (this.localizationService.myLocalizationData.record_panel.videoMode as keyof typeof RecordMode) || "both"
      ];
    messageBody += "<p>" + this.localizationService.myLocalizationData.record_panel.warningMessage + "</p>";
    messageBody +=
      "<label>" +
      (this.localizationService.myLocalizationData.record_panel.audioModeText || "Audio Record Mode") +
      "</label>";
    messageBody += '<div class="form-check form-check-inline">';
    messageBody += '<label class="form-check-label">';
    messageBody +=
      '<input class="form-check-input" type="radio" name="audioModeRadioOptions" id="audioMode1" value="disabled" ' +
      (currentAudioMode === RecordMode.disabled ? "checked" : "") +
      "> " +
      (this.localizationService.myLocalizationData.record_panel.disabledText || "Disabled");
    messageBody += "</label>";
    messageBody += "</div>";
    messageBody += '<div class="form-check form-check-inline">';
    messageBody += '<label class="form-check-label">';
    messageBody +=
      '<input class="form-check-input" type="radio" name="audioModeRadioOptions" id="audioMode1" value="local" ' +
      (currentAudioMode === RecordMode.local ? "checked" : "") +
      "> " +
      (this.localizationService.myLocalizationData.record_panel.localOnlyText || "Local Only");
    messageBody += "</label>";
    messageBody += "</div>";
    messageBody += '<div class="form-check form-check-inline">';
    messageBody += '<label class="form-check-label">';
    messageBody +=
      '<input class="form-check-input" type="radio" name="audioModeRadioOptions" id="audioMode2" value="remote" ' +
      (currentAudioMode === RecordMode.remote ? "checked" : "") +
      "> " +
      (this.localizationService.myLocalizationData.record_panel.remoteOnlyText || "Remote Only");
    messageBody += "</label>";
    messageBody += "</div>";
    messageBody += '<div class="form-check form-check-inline">';
    messageBody += '<label class="form-check-label">';
    messageBody +=
      '<input class="form-check-input" type="radio" name="audioModeRadioOptions" id="audioMode3" value="both" ' +
      (currentAudioMode === RecordMode.both ? "checked" : "") +
      "> " +
      (this.localizationService.myLocalizationData.record_panel.bothSidesText || "Both Sides");
    messageBody += "</label>";
    messageBody += "</div>";
    messageBody += "<br />";
    messageBody +=
      "<label>" +
      (this.localizationService.myLocalizationData.record_panel.videoModeText || "Video Record Mode") +
      "</label>";
    messageBody += '<div class="form-check form-check-inline">';
    messageBody += '<label class="form-check-label">';
    messageBody +=
      '<input class="form-check-input" type="radio" name="videoModeRadioOptions" id="videoMode1" value="disabled" ' +
      (currentVideoMode === RecordMode.disabled ? "checked" : "") +
      "> " +
      (this.localizationService.myLocalizationData.record_panel.disabledText || "Disabled");
    messageBody += "</label>";
    messageBody += "</div>";
    messageBody += '<div class="form-check form-check-inline">';
    messageBody += '<label class="form-check-label">';
    messageBody +=
      '<input class="form-check-input" type="radio" name="videoModeRadioOptions" id="videoMode1" value="local" ' +
      (currentVideoMode === RecordMode.local ? "checked" : "") +
      "> " +
      (this.localizationService.myLocalizationData.record_panel.localOnlyText || "Local Only");
    messageBody += "</label>";
    messageBody += "</div>";
    messageBody += '<div class="form-check form-check-inline">';
    messageBody += '<label class="form-check-label">';
    messageBody +=
      '<input class="form-check-input" type="radio" name="videoModeRadioOptions" id="videoMode2" value="remote" ' +
      (currentVideoMode === RecordMode.remote ? "checked" : "") +
      "> " +
      (this.localizationService.myLocalizationData.record_panel.remoteOnlyText || "Remote Only");
    messageBody += "</label>";
    messageBody += "</div>";
    messageBody += '<div class="form-check form-check-inline">';
    messageBody += '<label class="form-check-label">';
    messageBody +=
      '<input class="form-check-input" type="radio" name="videoModeRadioOptions" id="videoMode3" value="both" ' +
      (currentVideoMode === RecordMode.both ? "checked" : "") +
      "> " +
      (this.localizationService.myLocalizationData.record_panel.bothSidesText || "Both Sides");
    messageBody += "</label>";
    messageBody += "</div>";
    AlertService.createAlertWithButtons(messageBody, {
      confirm: {
        label: this.localizationService.myLocalizationData.record_panel.confirmButtonText,
        className: "btn-success"
      },
      cancel: {
        label: this.localizationService.myLocalizationData.record_panel.exitButtonText,
        className: "btn-danger"
      }
    }).then((result: boolean) => {
      if (result) {
        this.localizationService.myLocalizationData.record_panel.audioMode = $(
          'input:radio[name="audioModeRadioOptions"]:checked'
        ).val() as string;
        this.localizationService.myLocalizationData.record_panel.videoMode = $(
          'input:radio[name="videoModeRadioOptions"]:checked'
        ).val() as string;
        this.navBarService.viewModel.isRecording = true;
        this.navBarService.updateMenuItem({
          key: NavBarMenuItemKey.Record,
          title: ".toolbar.toolbar_items.record.altText",
          defaultTitle: "Stop Record",
          icon: "fas fa-pause-circle",
          color: "#d9534f"
        });
        this.startRecordEmitter.emit();
      }
    });
  }

  /**
   * trigger stop recording
   */
  stopRecord(): void {
    this.navBarService.viewModel.isRecording = false;
    this.navBarService.updateMenuItem({
      key: NavBarMenuItemKey.Record,
      title: ".toolbar.toolbar_items.record.text",
      defaultTitle: "Start Record",
      icon: "fas fa-bullseye",
      color: this.localizationService.getValueByPath(".toolbar.fontColor")
    });
    this.stopRecordEmitter.emit(true);
  }

  /**
   * open dashboard tab
   */
  openDashboardTab() {
    const theme = this.localizationService.myLocalizationData.style;
    window.open(theme + "/dashboard/overview?source=vcc");
  }
  
  /**
   * toggle map
   */
  toggleMap(payload: any) {
    if (payload && payload.enabled != null) {
      this.isMapShown = payload.enabled;
    } else {
      this.isMapShown = !this.isMapShown;
    }
    if (this.isMapShown) {
      this.navBarService.updateMenuItem({
        key: NavBarMenuItemKey.Map,
        title: ".toolbar.toolbar_items.map.altText",
        defaultTitle: "Close Map",
        icon: "far fa-map"
      });
    } else {
      this.navBarService.updateMenuItem({
        key: NavBarMenuItemKey.Map,
        title: ".toolbar.toolbar_items.map.text",
        defaultTitle: "Open Map",
        icon: "fas fa-map"
      });
    }
    this.toggleMapEmitter.emit(this.isMapShown);
  }

  /**
   * menu item onclick event handler
   */
  onMenuItemClick(menuItem: NavBarMenuItem) {
    $(".collapse").collapse("hide");
    if (menuItem.clickAction != null) {
      Dispatcher.dispatch(menuItem.clickAction, menuItem.payload);
    }
  }

  // Drag toolbar around

  navBarMouseDown( event) {
    this.isMouseDownOnNavBar = true;
    const navBarBoundingRect = this.toolbar.nativeElement.getBoundingClientRect();

    this.navBarDragOffsets = {
      leftOffset: event.touches[0].clientX - navBarBoundingRect.left,
      rightOffset: navBarBoundingRect.right - event.touches[0].clientX,
      topOffset: event.touches[0].clientY - navBarBoundingRect.top,
      bottomOffset: navBarBoundingRect.bottom - event.touches[0].clientY
    };
  }

  navBarMouseUp() {
    this.isMouseDownOnNavBar = false;
  }

  navBarMove(event) {
    event.preventDefault();

    if (this.isMouseDownOnNavBar) {

      const mousePosition = {
        x : event.touches[0].clientX,
        y : event.touches[0].clientY
      };

      if (event.touches[0].clientX > this.navBarDragOffsets.leftOffset &&
        event.touches[0].clientX < (window.innerWidth  -  this.navBarDragOffsets.rightOffset)) {
        this.toolbar.nativeElement.style.left = (mousePosition.x - this.navBarDragOffsets.leftOffset) + "px";
      }
      if (event.touches[0].clientY > this.navBarDragOffsets.topOffset &&
        event.touches[0].clientY < (window.innerHeight - this.navBarDragOffsets.bottomOffset)) {
        this.toolbar.nativeElement.style.top  = (mousePosition.y - this.navBarDragOffsets.topOffset) + "px";
      }

    }
  }

  resetNavBarPosition() {
    if (this.toolbar) {
      const screenHeight = document.documentElement.clientHeight;
      this.toolbar.nativeElement.style.top = "calc(" + screenHeight + "px - 80px)";
      this.toolbar.nativeElement.style.left = null;
    }
  }

  toggleLock(payload: any) {
    let toLock: boolean = this.isLocked;
    if (payload && payload.enabled != null) {
      toLock = payload.enabled;
    } else {
      toLock = !this.isLocked;
    }
    if (toLock) {
      this.lock();
    } else {
      Dispatcher.dispatch(ActionType.OpenKioskPasscodeModal, { callback: ActionType.Unlock });
    }
  }

  lock(payload?: any) {
    this.isLocked = true;
    this.navBarService.updateMenuItem({
      key: NavBarMenuItemKey.Lock,
      title: ".toolbar.toolbar_items.lock.text",
      defaultTitle: "Locked",
      icon: "fas fa-lock"
    });
    this.lockEmitter.emit();
    Dispatcher.dispatch(ActionType.EnterFullScreen);
  }

  unlock(payload?: any) {
    this.isLocked = false;
    this.navBarService.updateMenuItem({
      key: NavBarMenuItemKey.Lock,
      title: ".toolbar.toolbar_items.lock.altText",
      defaultTitle: "Unlocked",
      icon: "fas fa-lock-open"
    });
    // Don't fire unlock emitter, it's triggered from toggle-Unlock when unlock succeeds
  }

  /**
   * handle alert operator event
   */
  alertOperator(): void {
    this.opAlertEmitter.emit();
  }

  /**
   * update toolbar menu item status
   */
  updateToolbarItemStatus() {
    clearInterval(this.updateToolbarItemStatusInterval);
    this.updateToolbarItemStatusInterval = setInterval(
      () => Dispatcher.dispatch(ActionType.AutoUpdateMenuItems),
      300
    );
  }

  onMouseEnter(menu: NavBarMenuItem) {
    menu.hover = true;
  }

  onMouseLeave(menu: NavBarMenuItem) {
    menu.hover = false;
  }

  ngOnInit() {
    this.updateToolbarItemStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.changedWindowHeight || changes.connected) && this.isMobileApp) {
      this.resetNavBarPosition();
    }
  }

  openExternalLink() {
    let externalLink = this.localizationService.getValueByPath(".toolbar.toolbar_items.openExternalLink.value");
    if (externalLink) {
      window.open(externalLink);
    }
  }

  onMenuItemChecked(menuItem: NavBarMenuItem) {
    Dispatcher.dispatch(menuItem.clickAction, menuItem);
  }

  toggleOptimizeForVideo(menuItem: NavBarMenuItem) {
    if (menuItem.isChecked) {
      this.rtcService.rtcClient.presentationMode = PresentationMode.Motion_First;
    } else {
      this.rtcService.rtcClient.presentationMode = PresentationMode.Sharpness_First;
    }
    this.rtcService.getSecondaryStream(true).then((stream) => {
      Companion.getConferenceService().streamEventHandler.updateLocalStreamListener();
    }).catch((error: any) => {
      console.log(`Failed to getSecondaryStream: ${JSON.stringify(error)}`);
    });
  }

  getArialLabelOfLogo() {
    let result;
    result = result || this.localizationService.getValueByPath(".toolbar.logoArialLabel");
    result = result || _.join(
      _.compact([
        "Logo | ",
        this.localizationService.getValueByPath(".toolbar.leftTitle"),
        this.localizationService.getValueByPath(".toolbar.rightTitle"),
      ]),
      " "
    );
    result = result || "Logo";
    return result;
  }
}
