/** 
// USAGE: Instantiate with max concurrent
const throttler = new Semaphore(2);
// pass function to run with params to callFunction. That returns a promise.
throttler.callFunction(fetch, 'www.facebook.com');
throttler.callFunction(fetch, 'www.amazon.com');
throttler.callFunction(fetch, 'www.netflix.com');
throttler.callFunction(fetch, 'www.google.com');
https://medium.com/swlh/semaphores-in-javascript-e415b0d684bc
*/

export default class GenericSemaphore<T> {
    private currentRequests;
    private runningRequests;
    private maxConcurrentRequests;
    private emptyCallbackFn;
    /**
     * Creates a semaphore that limits the number of concurrent Promises being handled
     * @param {*} maxConcurrentRequests max number of concurrent promises being handled at any time
     */
    constructor(maxConcurrentRequests = 1, emptyCallback) {
        this.currentRequests = [];
        this.runningRequests = 0;
        this.maxConcurrentRequests = maxConcurrentRequests;
        this.emptyCallbackFn = emptyCallback;
    }

    /**
     * Returns a Promise that will eventually return the result of the function passed in
     * Use this to limit the number of concurrent function executions
     * @param {*} fnToCall function that has a cap on the number of concurrent executions
     * @param  {...any} args any arguments to be passed to fnToCall
     * @returns Promise that will resolve with the resolved value as if the function passed in was directly called
     */
    public callFunction(fnToCall, ...args): Promise<T> {
        return new Promise((resolve, reject) => {
            this.currentRequests.push({
                resolve,
                reject,
                fnToCall,
                args,
            });
            this.tryNext();
        });
    }

    /**
     * First look to see if there are no pending requests and call the emptyCallbackFn if present.
     * Then check to see if we are under concurrency limit and call the pending function and update the running count. On completion, decrement the running count and recursively call tryNext().
     * Otherwise, do nothing and wait for the return of a pending request.
     */
    private tryNext() {
        if (!this.currentRequests.length) {
            if (this.emptyCallbackFn) {
                this.emptyCallbackFn();
            }
            return;
        } else if (this.runningRequests < this.maxConcurrentRequests) {
            let { resolve, reject, fnToCall, args } = this.currentRequests.shift();
            this.runningRequests++;
            let req = fnToCall(...args);
            req.then((res) => resolve(res))
                .catch((err) => reject(err))
                .finally(() => {
                    this.runningRequests--;
                    this.tryNext();
                });
        }
    }
}
