/**
 * Copyright Compunetix Incorporated 2017-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit, ViewChild} from "@angular/core";
import {ActionType, Dispatcher} from "../shared/services/dispatcher";

import {
  AlertLevel,
  Browser,
  Companion,
  Endpoint,
  EndpointService,
  GlobalService,
  IChatRoomService,
  IConferenceService,
  IDeviceService,
  IEndpoint,
  IEndpointService,
  IEntry,
  IEntryResponse,
  ILocalization,
  IMediaChannelSettingOption,
  IRoleService,
  IRTCService,
  IUser,
  IUserService,
  VideoMediaConnectionMode,
  MediaUtil,
  PresenceState,
  PresenceStatus,
  RecordMode,
  RoomType,
  SegmentMethod,
  VideoResolution,
  VideoAspect,
  IQueueSelectionItem,
  CameraPermission,
  ConnectionType,
  IQueueStatus,
  AlertCode,
  AlertHandlerCallbackOptions,
  LogUtil,
  IClientSettings,
  ISkillTags,
  ConferenceUtil,
  IConferenceExpanded,
  ExpandedQueueConference,
  IConferenceUpdate,
  IDevice,
  IEndpointRef,
  StatusReason,
  IActiveConference,
  IMonitorConference,
  IPushToTalkEvent,
  IExpandedActiveConference,
  IMessage,
  IExpandedQueueConference
} from "companion";

import {RestService} from "../shared/services/rest.service";
import {VideoListComponent} from "../conference/video/video-list.component";
import {ChatRoomComponent} from "../conference/chatroom/chatroom.component";
import {LoopbackComponent} from "../conference/loopback/loopback.component";
import {AlertService} from "../alert/alert.service";
import {LocalizationService} from "../localization/localization.service";
import {UserManagementService} from "../user-management/user-management.service";
import {CallCenterService} from "./call-center.service";
import {NavBarMenuItemKey, NavBarMenuPosition, NavBarService} from "./../layout/nav-bar/nav-bar.service";
import {NotepadComponent} from "../conference/notepad/notepad.component";
import {VoiceService} from "../shared/services/voice.service";
import {AnalyticsService} from "../shared/services/analytics";
import {ConfigService} from "client/scripts/config/config.service";
import {ClientLogService} from "client/scripts/shared/services/client-log.service";
import {Store} from "client/scripts/Store/store.service";
import {Dimensions} from "ngx-image-cropper";
import {DialOutComponent, IDialOutData} from "../dial-out/dial-out.component";
import { FileUtility } from "../shared/services/file-utility";
import { SafeHtmlPipe } from "../shared/pipes/safe-html.pipe";
import { GuestConnectComponent } from "./guest-view/guest-connect.component";
import { PassportLocalDocument } from "mongoose";
import { IThirdPartyAPIIntegration } from "../../../companion/group/group.interface";
import { PasscodeModalComponent } from "../passcode/passcode-modal.component";
import { ISharedFile, ISharedFileRef } from "companion/sharedfile/sharedfile.interface";

// Default video volume
const DEFAULT_VIDEO_VOLUME: number = 1.0;
@Component({
  selector: "call-center",
  templateUrl: "./call-center.template.html",
})
export class CallCenterComponent implements OnInit {
  /**
   * loopback child view
   */
  @ViewChild(LoopbackComponent)
  loopbackComponent: LoopbackComponent;
  /**
   * video screen component reference
   */
  @ViewChild(VideoListComponent)
  videoComponent: VideoListComponent;

  /**
   * chat room component reference
   */
  @ViewChild(ChatRoomComponent)
  chatRoomComponent: ChatRoomComponent;

  /**
   * notepad component reference
   */
  @ViewChild(NotepadComponent)
  notepadComponent: NotepadComponent;

  @ViewChild(DialOutComponent) dialOutComponent: DialOutComponent;

  @ViewChild(GuestConnectComponent) guestConnectComponent: GuestConnectComponent;

  @ViewChild('KioskUnlockPasscodeModal') unlockKioskModal: PasscodeModalComponent;
  /**
   * flag if server connected
   */
  connected: boolean = false;
  /**
   * flag if conference joined
   */
  joined: boolean = false;
  /**
   * flag if incoming queue panel is in display
   */
  incomingQueueShown: boolean = false;
  /**
   * flag if operators panel is in display
   */
  operatorsPanelShown: boolean = false;
  /**
   * flag if chat box is in display
   */
  chatBoxShown: boolean = false;
  /**
   * flag if setting panel is in display
   */
  settingsPanelShown: boolean = false;
  /**
   * flag if the inspector panel is shown
   */
  inspectorPanelShown: boolean = false;
  /**
   * flag if the settings panel is shown
   */
  menuPanelShown: boolean = false;
  /**
   * total amount of unread received files
   */
  unreadFileCount: number = 0;
  /**
   * total amout of files
   */
  totalFileCount: number = 0;
  /**
   * flag if shared folder window is in display
   */
  showSharedFolder: boolean = false;
  /**
   * flag if inspect window shown
   */
  showInspect: boolean;
  /**
   * flag if loopback window is in display
   */
  showLoopback: boolean = true;
  /**
   * current theme
   */
  style: string;
  /**
   * current mode
   */
  mode: string;
  language: string;
  location: string;
  
  /**
   * A special key used for linking a guest to remote credentials stored on the
   * server for remote control access
   */
  remoteIdentity: string;

  listTypesLeft: string[] = [];
  listTypesRight: string[] = [];
  chatroomListTypes: string[] = [];
  layoutLeft: string = "list";
  layoutRight: string = "list";
  viewMode: string = "guest";
  opSelectedGuest: IEndpoint;
  isLocked: boolean;
  isKioskUnlocked: boolean = false;
  ringingEndpoint: IEndpoint;
  /**
   * flag if user is logged in
   */
  isLoggedin: boolean;

  /**
   * flag if any operator online
   */
  hasOperatorOnline: boolean = true;

  touchlessGuestConnect: boolean = false;

  /**
   * flag if operator is selected
   */
  transferHasSelected: boolean;

  /**
   * flag if toolbar shown
   */
  isNavShown: boolean = true;

  /**
   * timer to hide toolbar
   */
  toobarHideTimer: any;

  /**
   * timer to reject an incoming call
   */
  rejectCallTimer: any;

  /**
   * timer to wait for a photo response
   */
  takePhotoTimer: any;

  /**
   * flag if it's WebRTC supported in this browser
   */
  isWebRTCSupport: boolean = false;

  /**
   * flag if it's mobile app
   */
  isMobileApp: boolean;

  /**
   * if this is a mobile device
   */
  isMobileDevice: boolean = Browser.isMobile();

  /**
   * flag if clean mode is active in mobile devices
   */
  isCleanViewMode: boolean;

  /**
   * window height
   */
  changedWindowHeight: number;

  /**
   * export PresenceStatus class to template
   */
  PresenceStatusClass = PresenceStatus;

  /**
   * conference service from Companion lib
   */
  conferenceService: IConferenceService;
  /**
   * endpoint service from Companion lib
   */
  endpointService: IEndpointService;
  /**
   * rtc service from Companion lib
   */
  rtcService: IRTCService;
  /**
   * user service from Companion lib
   */
  userService: IUserService;
  /**
   * role service from Companion lib
   */
  roleService: IRoleService;
  /**
   * chatroom service from Companion lib
   */
  chatRoomService: IChatRoomService;
  /**
   * device service from Companion lib
   */
  deviceService: IDeviceService;

  /**
   * is map view shown
   */
  isMapShown: boolean = false;

  /**
   * flag if notepad is shown
   */
  isNotepadShown: boolean;

  /**
   * holds the endpoint when it is selected for additional information
   */
  guestEndpoint: IEndpoint;

  /**
   * the endpoint which the notepad is refering
   */
  noteOnEndpoint: IEndpoint;

  /**
   * flag if the call center has been fully initialized (we can delay this until first time users
   * complete the helper wizard when enabled).
   */
  initialized: boolean;

  /**
   * flag if user has used this system to gain media before
   * or if user finished the helper wizard
   */
  isFirstTimeUser: boolean;

  /**
   * flag if media access is finished
   */
  mediaAccessFinished: boolean;

  /**
   * flag if media access failed
   */
  mediaAccessFailed: boolean = false;

  /**
   * ready to make phone call
   */
  voiceDialoutEnabled: boolean;

  /**
   * the video resolution for the primary camera source
   * provided from the host address url
   */
  primaryCameraResolutionFromUrl: VideoResolution;

  /**
   * the video resolution for the secondary camera source
   * provided from the host address url
   */
  secondaryCameraResolutionFromUrl: VideoResolution;

  /**
   * redirect url on exit
   */
  exitUrl: string;

  /**
   * entry data in html string
   */
  entryMessage: string;

  /**
   * volume of videos
   */
  videoVolume: number = 1;

  /**
   * guest to answer
   */
  guestToAnswerEp: IEndpoint;

  /**
   * guest endpoint associated to guest info dialog
   */
  infoDialogEp: IEndpoint;

  /**
   * operator endpoint associated to operator info dialog
   */
  opInfoDialogEp: IEndpoint;

  /**
   * the image to be passed to cropper
   */
  imageToBeSaved: File = null;

  /**
   * dimensions of the image to be passed to cropper
   */
  imageToBeSavedDimensions: Dimensions = {width: 0, height: 0};

  /**
   * canvas rotation
   */
  cameraRotation = 0;

  /**
   * flag if is taking photo
   */
  takingPhoto: boolean = false;

  /**
   * flag if photo editor is enabled
   */
  cropperEnabled: boolean = true;

  /**
   * type of connection
   */
  connectionType = ConnectionType;

  /**
   * guest queue info in realtime
   */
  queueStatus: IQueueStatus;

  /**
   * supplied arguments for queue entry
   */
  skillTags: ISkillTags;

  /**
   * muted status before monitoring
   */
  mutedStatusBeforeMonitoring: boolean;

  /**
   * flag indicating that the application is a guest and is the middle of a transfer
   */
  transferOccurring : boolean = false;

  /**
   * This flag is for indicating if the client has established an endpoint session with the server
   * in the event of a reconnect we should insure that we have connected correctly to the session we have already
   * established, and in the event we have not, should take different action.
   */
  hasEstablishedSession : boolean = false;

  thirdPartyAPIIntegrationSettings: IThirdPartyAPIIntegration = {};

  lastMonitorObserved : boolean = false;
  lastMonitorMonitoring : boolean = false;

  get getKnownEndpoints(): Readonly<IEndpoint>[] {
    return [...this.endpointService.endpoints.values()];
  }

  get getPanelConferenceLeft(): Readonly<IConferenceExpanded> {
    let allGuestsConf = new ExpandedQueueConference();
    allGuestsConf.roomType = RoomType.GuestWaitRoom;
    allGuestsConf.name = "PanelConferenceLeft: All Guests";
    allGuestsConf.publicWaitList = this.callCenterService.globalPublicWaitList;
    return allGuestsConf;
  }

  get getPanelConferenceRight(): Readonly<IConferenceExpanded> {
    let usersConf = new ExpandedQueueConference();
    usersConf.roomType = RoomType.GuestWaitRoom;
    usersConf.name = "PanelConferenceRight: All Users";
    usersConf.operators = this.callCenterService.visibleOperators;
    usersConf.reps = this.callCenterService.visibleReps;
    return usersConf;
  }

  /**
   * Callback function to check transfers to an operator. Pass this to op participant list via bound fn.
   * Don't log or alert or async, this gets called a lot.
   * @param endpoint Endpoint that triggered the callback.
   * @returns 
   */
  private transferCheckFn = (endpoint: IEndpoint): boolean  => {
    if (this.opSelectedGuest) {
      if (!endpoint.skillSet && !this.opSelectedGuest.skillTags) {
        return true;
      } else if (endpoint.skillSet && this.opSelectedGuest.skillTags) {
        if (endpoint.skillSet.categories && ConferenceUtil.getCategoryListFromSkillProficiencyList(endpoint.skillSet.categories).includes(this.opSelectedGuest.skillTags.category)) {
          if (endpoint.skillSet.languages && endpoint.skillSet.languages.includes(this.opSelectedGuest.skillTags.language)) {
            return true;
          }
        }
      }
    }
    return false;
  };
  // See https://medium.com/@erVikas1/angular-passing-callback-function-to-child-component-3d482ad376ee
  protected canTransferTo : (endpoint: IEndpoint) => boolean;

  /**
   * Callback function to check multi-party calls to an operator. Pass this to op participant list via bound fn.
   * Don't log or alert or async, this gets called a lot.
   * @param endpoint Endpoint that triggered the callback.
   * @returns 
   */
  private conferenceInCheckFn = (endpoint: IEndpoint): boolean  => {
    // Get all endpoints in current active conference
    const currentConference = this.activeConference;
    let result = true;
    currentConference?.active.forEach(epRef => {
      let ep = this.endpointService.getEndpointById(epRef.rtcId);
      // Check them one by one (don't count operators)
      if (!this.endpointService.isOperator(ep)) {
        if (endpoint.skillSet && ep.skillTags) {
          if ((endpoint.skillSet.categories && !ConferenceUtil.getCategoryListFromSkillProficiencyList(endpoint.skillSet.categories).includes(ep.skillTags.category)) ||
              (endpoint.skillSet.languages && !endpoint.skillSet.languages.includes(ep.skillTags.language))) {
            result = false;
          }
        } else if (endpoint.skillSet && !ep.skillTags) {
          result = false;
        } else if (!endpoint.skillSet && ep.skillTags) {
          return false;
        }
      }
    });
    return result;
  };
  // See https://medium.com/@erVikas1/angular-passing-callback-function-to-child-component-3d482ad376ee
  protected canConferenceIn : (endpoint: IEndpoint) => boolean;

  /**
   * determine if notepad view is enabled in this theme and mode
   */
  get notepadViewEnabled(): boolean {
    let result =
      this.localizationService.myLocalizationData.notepad &&
      !!this.noteOnEndpoint &&
      Endpoint.getPresenceStateByStatus(this.noteOnEndpoint.status) === PresenceState.dnd &&
      this.endpointService.connectedWithMe(this.noteOnEndpoint);

    this.isNotepadShown = this.isNotepadShown && result;
    return result;
  }

  get ngHidden_MainConferenceFullscreenContainer(): boolean {
    return !this.localizationService.myLocalizationData || !this.localizationService.myLocalizationData.page;
  }

  get ngIf_TopBar(): boolean {
    return this.localizationService.myLocalizationData.topnav && !this.isMobileApp;
  }

  get ngHidden_TopBar(): boolean {
    return this.fullScreenMode;
  }

  get ngHidden_NavBar(): boolean {
    return (!this.localizationService.myLocalizationData.toolbar || this.fullScreenMode) && 
      !this.isMobileApp || 
      (this.isMobileApp && this.isCleanViewMode) || 
      (this.isMobileApp && !this.joined);
  }

  get ngIf_GuestOfflineStatus(): boolean {
    return this.endpointService.myEndpoint?.status == PresenceStatus.offline && !this.loginRequired;
  }

  get ngIf_Loopback(): boolean {
    return this.localizationService.myLocalizationData.loopback_panel && 
      !this.activeConference?.everyone && 
      this.isWebRTCSupport && 
      this.mediaAccessFinished && 
      ((this.endpointService.myEndpoint.status === PresenceStatus.offline) || 
       (this.viewMode === 'guest' && 
       (this.endpointService.myEndpoint.status != PresenceStatus.busy &&
        this.endpointService.myEndpoint.status != PresenceStatus.alone_in_conf
       )));
  }

  get ngIf_VoiceView(): boolean {
    return !!this.localizationService.myLocalizationData.connect_screen;
  }

  get ngHidden_VoiceView(): boolean {
    return !this.isKiosk()|| !this.joined || !this.connected ||
    !ConferenceUtil.isVoiceConference(this.activeConference) || !this.ngHidden_VideoList;
  }

  get ngIf_NotepadPanel(): boolean {
    return this.activeConference
      && this.joined
      && this.isNotepadShown
      && this.notepadViewEnabled;
  }

  get ngIf_MapPanel(): boolean {
    return this.joined
      && this.activeConference
      && this.activeConference?.active
      && this.localizationService.myLocalizationData.map_panel
      && this.isMapShown;
  }

  get ng_MapPanelEndpoints(): IEndpoint[] {
    return this.activeConference?.active || [];
  }
  
  get ng_MapPanelGoogleMapsAPIKey(): string {
    return this.thirdPartyAPIIntegrationSettings.googleMapsAPIKey;
  }

  get ngIf_GuestQueueScreen(): boolean {
    return this.guestQueueScreenToShow;
  }

  get ngHidden_GuestQueueScreen(): boolean {
    return !this.mediaAccessFinished;
  }

  get ngIf_GuestConnect(): boolean {
    return !this.guestQueueScreenToShow &&
      this.localizationService.myLocalizationData.connect_screen
      && this.joined
      && this.viewMode === 'guest'
      && this.endpointService.myEndpoint.status !== PresenceStatus.busy
      && this.endpointService.myEndpoint.status !== PresenceStatus.renegotiating;
  }

  get ngHidden_GuestConnect(): boolean {
    return !this.mediaAccessFinished;
  }

  get ngIf_ParticipantPanelLeft(): boolean {
    return this.localizationService.myLocalizationData.participant_panel
      && this.joined
      && this.listTypesLeft
      && this.incomingQueueShown;
  }

  get ngIf_ParticipantPanelRight(): boolean {
    return this.localizationService.myLocalizationData.participant_panel
      && this.joined
      && this.listTypesRight
      && this.operatorsPanelShown;
  }

  get ngIf_Chatroom(): boolean {
    return this.localizationService.myLocalizationData.chat_panel
      && this.joined
      && !!this.chatroomListTypes;
  }

  get ngHidden_Chatroom(): boolean {
    return !this.chatBoxShown;
  }

  get ngIf_SettingsPanel(): boolean {
    return this.localizationService.myLocalizationData.settings_panel
      && this.settingsPanelShown
  }

  get ngIf_MenuPanel(): boolean {
    return this.localizationService.myLocalizationData.settings_panel
      && this.isWebRTCSupport
      && this.menuPanelShown;
  }

  get ngIf_VideoList(): boolean {
    return this.joined &&
      this.isWebRTCSupport;
  }

  get ngHidden_VideoList(): boolean {
    return !this.joined || 
    (this.viewMode === 'guest' && 
    (this.endpointService.myEndpoint.status !== PresenceStatus.busy && this.endpointService.myEndpoint.status !== PresenceStatus.alone_in_conf)) ||
    (this.viewMode === 'guest' && ConferenceUtil.isVoiceOnlyConference(this.activeConference, [this.endpointService.myEndpoint.rtcId]))
  }

  get isRecordingActive(): boolean {
    return this.activeConference?.recorderRtcIds.length > 0;
  }

  /**
   * determine if geolocation capture is required in this theme and mode
   */
  get geoCaptureRequired(): boolean {
    let result = false;
    if (this.localizationService.myLocalizationData.map_panel) {
      result = true;
    }
    return result;
  }

  /**
   * determine if map view is enabled in this theme and mode
   */
  get mapViewEnabled(): boolean {
    let result = false;
    if (
      this.localizationService.myLocalizationData.map_panel &&
      this.localizationService.myLocalizationData.toolbar.toolbar_items.map
    ) {
      result = true;
      if (
        this.localizationService.myLocalizationData.toolbar.toolbar_items.map.hostOnly &&
        !this.endpointService.myEndpoint.isHost
      ) {
        result = false;
      }
    }
    return result;
  }

  get fullScreenMode(): boolean {
    if (this.localizationService.getValueByPath(".page.fullScreenMode")) {
      return this.guestLoginRequired;
    } else {
      return false;
    }
  }

  get guestLoginRequired(): boolean {
    return (
      !this.loginRequired &&
      !this.joined &&
      !this.userService.currentUser.isAuthenticated
    );
  }

  get queueScreenEnabled(): boolean {
    return this.viewMode === "guest" && this.localizationService.getValueByPath(".queue_screen");
  }

  get guestQueueScreenToShow(): boolean {
    return (
      !this.loginRequired &&
      this.queueScreenEnabled &&
      this.endpointService.myEndpoint.status == PresenceStatus.available
    );
  }

  get loginRequired(): boolean {
    if (this.joined || this.route.snapshot?.params?.["location"]) { // don't support guest login at this time.
      return false;
    }
    return true;
  }

  get isPanelOpen(): boolean {
    return this.settingsPanelShown ||
      this.menuPanelShown ||
      this.chatBoxShown;
  }

  /**
   * warning message for leaving
   */
  get leavingWarningMessage(): string {
    return this.localizationService.getValueByPath(".errorMessages.LEAVE_WARNING") || "Are you sure you want to leave?";
  }

  get guestOfflineMessage(): string {
    return this.localizationService.getValueByPath(".errorMessages.GUEST_OFFLINE_MESSAGE") || "Session finished.\nPlease close/refresh the page.";
  }

  /**
   * is server connected
   */
  get isServerConnected(): boolean {
    return Companion.getRTCService().isServerConnected();
  }

  /**
   * indicates the client is not currently connected, and attempting to reconnect.
   */
    get isAttemptingReconnect(): boolean {
      // we are attem
      return !this.isServerConnected && 
        this.joined && 
        this.endpointService.myEndpoint?.status !== PresenceStatus.callback;
    }
  

  get showMediaPermissionsNeeded(): boolean {
    let result: boolean = false;
    if (!this.mediaAccessFinished)
    {
      // If we haven't acted on the permissions popup, we want the message/spinner
      result= true;
    }
    else if (this.endpointService.myEndpoint.status === PresenceStatus.busy)
    {
      // If we are already connected, let them continue with the call - although they probably are not contributing (audio/video) to the conf?!
      // This scenario handles the case where the guest disables permission while waiting in the queue and agent is ringing and thus picked up the line
      // let agent inform them to do something about their permissions
      result = false;
    }
    else if ((this.localizationService.myLocalizationData?.settings_panel?.disableAudioOnly &&
              this.endpointService.myEndpoint?.cameraPermission != CameraPermission.allowed) ||
             (this.endpointService.myEndpoint?.microphonePermission != CameraPermission.allowed &&
              this.endpointService.myEndpoint?.cameraPermission != CameraPermission.allowed))
    {
      // If camera/microphone permissions are not as desired, we want the message/spinner 
      // so they cannot proceed until they enable the permissions
      result = true;
    }
    return result;
  }

  get showSpinner(): boolean {
    // normally don't show spinner
    let result = false;
    // if there is action not completed, show spinner
    if (!this.conferenceService.actionCompleted) {
      result = true;
    }
    // if lost server connection, and attempting to reconnect show spinner
    if (this.isAttemptingReconnect) {
      result = true;
    }
    if (this.showMediaPermissionsNeeded) {
      result = true;
    }
    
    // Exceptions go after standard cases.

    // however, if this is first time user, and the user didn't finish self-test, don't show spinner
    if (this.isFirstTimeUser) {
      result = false;
    }
    // also, if this does not support webrtc at all, don't show spinner
    if (!this.isWebRTCSupport) {
      result = false;
    }
    return result;
  }

  /**
   * component constructor
   */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private alertService: AlertService,
              public localizationService: LocalizationService,
              private restService: RestService,
              private userManagementService: UserManagementService,
              public callCenterService: CallCenterService,
              private navBarService: NavBarService,
              private voiceService: VoiceService,
              private configService: ConfigService,
              private logService: ClientLogService,
              public store: Store,
              protected safeTextPipe: SafeHtmlPipe
  ) {
    this.canTransferTo = this.transferCheckFn.bind(this);
    this.canConferenceIn = this.conferenceInCheckFn.bind(this);
    Dispatcher.register(ActionType.ToggleShareFilesPanel, this.toggleSharedFolder.bind(this));
    Dispatcher.register(ActionType.ToggleInspectorPanel, this.toggleInspector.bind(this));
    Dispatcher.register(ActionType.ToggleChatPanel, this.toggleChatBox.bind(this));
    Dispatcher.register(ActionType.FinishHelperWizard, () => {
      this.userService.currentUser.helperFinished = true
      GlobalService.setSessionUser(this.userService.currentUser);
      Dispatcher.dispatch(ActionType.HideModalDialog, "helper-wizard", "helper-wizard");
      this.init().catch((error: any) => {
        console.error(`Failed to init : ${JSON.stringify(error)}`);
      });
    });
    Dispatcher.register(ActionType.OpenKioskPasscodeModal, this.openKioskUnlockModal.bind(this));
    Dispatcher.register(ActionType.TransferQueueSubmitted, (function(){ 
      console.log("Transfer occured!");
      this.transferHasSelected = false }).bind(this));
    Dispatcher.register(ActionType.UpdateSkillSetDisplay, this.updateSkillSetDisplay.bind(this), "updateSkillSet");
    // Let this component know when a conference update is received.
    this.callCenterService.setConferenceUpdateCallback((data: IConferenceUpdate) => {
      //console.log("Call-Center processing new update data");
      this.checkGuestsForAutoRecord();
      this.checkTransferEndpointStillExists();
      let sendEndpointUpdate = false;
      // Check to see if our camera permission is accurately represented.
      if (this.endpointService.myEndpoint.cameraPermission !== CameraPermission.allowed && this.rtcService.rtcClient.cameraAccessible) {
        this.endpointService.myEndpoint.cameraPermission = CameraPermission.allowed;
        sendEndpointUpdate = true;
      }
      // Check to see if our microphone permission is accurately represented.
      if (this.endpointService.myEndpoint.microphonePermission !== CameraPermission.allowed && 
          this.rtcService.rtcClient.microphoneAccessible) {
        this.endpointService.myEndpoint.microphonePermission = CameraPermission.allowed;
        sendEndpointUpdate = true;
      }
      if (sendEndpointUpdate) {
        this.endpointService.sendEndpointUpdate();
      }
    });

    this.callCenterService.setNotifyVoiceConferenceCallback(() =>
    {
      console.log("Voice Conference Update!");
      this.voiceConferenceUpdateRoutine();
    })
    
    // let this component know when to call center should mark this client offline
    this.callCenterService.setNotifyConferenceCompleteCallback(() => {

      console.log("Conference Complete");
      this.toggleScreenCapture(true); // closing capture session
      // Are we a user?
      let isUser = !!this.endpointService.myEndpoint.userId;
      if(isUser)
      { // Just do the ready timer thing.
          this.endpointService.myEndpoint.status = PresenceStatus.ready;
          this.callCenterService.startReadyTimer(PresenceStatus.ready);
      }
      else { // Must be a guest...
        // Did we go to recess?
        if (this.endpointService.myEndpoint.statusReason == StatusReason.recess) {
          if (!!this.localizationService.getValueByPath(".connect_screen.autoLeave")) {
            this.leave(false);
          } else {
            this.leave(true); // Triggers markClientReady after doing stuff it needs to.
          }
        }
        else if(this.endpointService.myEndpoint.statusReason == StatusReason.transfer)
        {
          // if we have transferred, go ahead and await invite in this new queue...
          this.endpointService.markClientReady();
          this.endpointService.awaitInvitation();
        }
      }
    });

    this.callCenterService.setNotifyMonitoringStatusCallback((ended: boolean, monitoring: boolean, observed) => {
      if (ended) {
        if (this.lastMonitorObserved) {
          // Clear stuff for no longer being a subject.
          this.videoComponent.resetBroadcastStreamPromise(RecordMode.disabled, RecordMode.disabled); // We should *NOT* be recording stuff right now.
          this.rtcService.clearBroadcastStream();
          this.rtcService.updateVideoSource(true);
        }

        if (this.lastMonitorMonitoring) {
          // Clear stuff for no longer being an observer.
          // I don't think i have to do anything.
        }

        this.lastMonitorObserved = false;
        this.lastMonitorMonitoring = false;
      } else {
        // Check based on current if we are doing the right things We should only get this callback if conference changes state!
        if (!this.videoComponent) {
          console.log("Tried to activate videos before video component!!!");
          return;
        }

        if (!this.lastMonitorObserved && observed) {
          this.videoComponent.resetBroadcastStreamPromise(RecordMode.both, RecordMode.both); // We should be recording stuff right now.
          this.rtcService.updateVideoSource(true);
        }
        if (!this.lastMonitorMonitoring && monitoring) {
          // Activate monitoring?? I don't think i have to do anything
        }

        // NOTE: These two should probably never be happening at the same time... However I guess you might be able to switch from one to the other
        this.lastMonitorMonitoring = monitoring;
        this.lastMonitorObserved = observed;
      }
    });
  }

  getSafeHtml(text: string): string {
    if (text) {
        return this.safeTextPipe.transform(text) as string;
    }
    return "";
  }

  getSafeText(text: string): string {
    if (text) {
        return this.safeTextPipe.nonScrub(text) as string;
    }
    return "";
  }

  isLogCollectionEnabled() {
    return LogUtil.getLogInstance()?.logsSettings?.enabled;
  }

  /**
   * component init event handler
   */
  ngOnInit() {
    this.configService.getClientSettings()
    .then((settings: IClientSettings) => {
      this.conferenceService.alertHandler(
        AlertCode.getSettingsDataSuccess,
        "Collect logs enabled: " + settings.CollectLogs.enabled,
        AlertLevel.info
      );
      LogUtil.getLogInstance().init(settings?.CollectLogs);
      if (settings?.CollectLogs?.webrtcStatistics) {
        this.logService.enableWebrtcStats(settings.CollectLogs.statFrequency || 1000);
      }    
    })
    .catch(error => this.conferenceService.alertHandler(AlertCode.getSettingsDataError, error.error, AlertLevel.info));

    window.addEventListener("beforeunload", (e) => {
      const isThemePreview = this.route.snapshot.queryParams["preview"];
      if (!isThemePreview && this.conferenceService.preventReload) {
        e.preventDefault();
        e.returnValue = this.leavingWarningMessage;
        return this.leavingWarningMessage;
      }
    });
    window.addEventListener("unload", (e) => {
      if (this.endpointService.myEndpoint.status !== PresenceStatus.callback) {
        this.leave(false);
      }
    });

    // check if browser support webrtc
    this.isWebRTCSupport = this.isBrowserSupport();
    this.conferenceService = Companion.getConferenceService();
    this.endpointService = Companion.getEndpointService();
    this.rtcService = Companion.getRTCService();
    this.userService = Companion.getUserService();
    this.roleService = Companion.getRoleService();
    this.chatRoomService = Companion.getChatRoomService();
    this.chatRoomService.setCurrentRoomClosedHandler(() => {
      this.toggleChatBox({forceOpen: false, forceClose: true});
    })
    this.deviceService = Companion.getDeviceService();
    this.isNavShown = !this.isMobileApp;
    this.conferenceService.actionCompleted = true;

    this.location = (this.route.snapshot.params["location"]) ? this.getSafeHtml(this.route.snapshot.params["location"]): null;

    this.exitUrl = this.route.snapshot.queryParams["leave_redirect_url"];

    this.entryMessage = (this.route.snapshot.queryParams["message"])? this.getSafeHtml(this.route.snapshot.queryParams["message"]): null;
    if (this.route.snapshot.queryParams["queueCat"] || this.route.snapshot.queryParams["queueLang"]) {
      this.skillTags = {category: (this.route.snapshot.queryParams["queueCat"]) ?  decodeURIComponent(this.route.snapshot.queryParams["queueCat"]) : null,
                        language: (this.route.snapshot.queryParams["queueLang"]) ? decodeURIComponent(this.route.snapshot.queryParams["queueLang"]): null};
      // update the endpoint object
      this.endpointService.myEndpoint.skillTags = this.skillTags;
    }

    // get the remote identity
    this.endpointService.myEndpoint.remoteIdentity = (this.route.snapshot.queryParams["remoteId"]) ? 
      this.getSafeHtml(this.route.snapshot.queryParams["remoteId"]): null;

    let entryId = this.route.snapshot.queryParams["entryId"];

    this.style = (this.route.snapshot.params["style"])? this.getSafeHtml(this.route.snapshot.params["style"]) : "default";

    this.language = this.route.snapshot.params["language"] ? this.getSafeHtml(this.route.snapshot.params["language"]) : "en";

    this.fetchThirdPartyAPIIntegrationSettings();

    if (this.route.snapshot.queryParams["primaryCameraId"]) {
      this.userService.currentUser.preferedPrimaryCameraDeviceId = this.route.snapshot.queryParams["primaryCameraId"];
      this.userService.currentUser.preferedPrimaryCameraDeviceLabel = null;
    }

    if (this.route.snapshot.queryParams["primaryCameraLabel"]) {
      this.userService.currentUser.preferedPrimaryCameraDeviceLabel = this.route.snapshot.queryParams[
        "primaryCameraLabel"
        ];
      this.userService.currentUser.preferedPrimaryCameraDeviceId = null;
    }

    if (
      this.route.snapshot.queryParams["primaryCameraWidth"] &&
      this.route.snapshot.queryParams["primaryCameraHeight"]
    ) {
      let width = parseInt(this.route.snapshot.queryParams["primaryCameraWidth"], 10);
      let height = parseInt(this.route.snapshot.queryParams["primaryCameraHeight"], 10);
      if (!isNaN(width) && !isNaN(height)) {
        this.primaryCameraResolutionFromUrl = {width: width, height: height};
        this.userService.currentUser.preferedPrimaryResolution = this.primaryCameraResolutionFromUrl;
        _.merge(this.rtcService.rtcClient.resolutionOptions, [this.primaryCameraResolutionFromUrl]);
      }
    }

    if (this.route.snapshot.queryParams["secondaryCameraId"]) {
      this.userService.currentUser.preferedSecondaryCameraDeviceId = this.route.snapshot.queryParams[
        "secondaryCameraId"
        ];
      this.userService.currentUser.preferedSecondaryCameraDeviceLabel = null;
    }

    if (this.route.snapshot.queryParams["secondaryCameraLabel"]) {
      this.userService.currentUser.preferedSecondaryCameraDeviceLabel = this.route.snapshot.queryParams[
        "secondaryCameraLabel"
        ];
      this.userService.currentUser.preferedSecondaryCameraDeviceId = null;
    }

    if (
      this.route.snapshot.queryParams["secondaryCameraWidth"] &&
      this.route.snapshot.queryParams["secondaryCameraHeight"]
    ) {
      let width = parseInt(this.route.snapshot.queryParams["secondaryCameraWidth"], 10);
      let height = parseInt(this.route.snapshot.queryParams["secondaryCameraHeight"], 10);
      if (!isNaN(width) && !isNaN(height)) {
        this.secondaryCameraResolutionFromUrl = {width: width, height: height};
        this.userService.currentUser.preferedSecondaryResolution = this.secondaryCameraResolutionFromUrl;
        _.merge(this.rtcService.rtcClient.resolutionOptions, [this.secondaryCameraResolutionFromUrl]);
      }
    }

    // fetch the device infor an apply it to the endpoint here
    let device: IDevice = {
      os: Browser.whichOS(),
      browser: Browser.whichBrowser(),
      version: Browser.whichBrowserVersion(),
      isMobile: Browser.isMobile(),
      userAgent: navigator.userAgent
    }
    this.endpointService.myEndpoint.deviceInfo = device;

    // check if this is first time user
    this.isFirstTimeUser =
      !this.userService.currentUser.helperFinished &&
      !this.userService.currentUser.preferedPrimaryCameraDeviceId &&
      !this.userService.currentUser.preferedMicrophoneDeviceId;

    // Are we guest? This early, we have to check the path params to see if location is specifieid.
    let guestAccess = !!this.location;

    // Promise Chain Starts here:
    let redirectOnEntryError;
    
    let entryCheck: Promise<any>;
    if (guestAccess) {
      // set the location as the user id
      this.userService.currentUser.username = this.location;
      this.rtcService.setCurrentUsername(this.location);
      this.endpointService.myEndpoint.name = this.location;
      entryCheck = this.callCenterService.confirmAndRetrieveEntryDataById(this.style , entryId)
      .then((entryResponse: IEntryResponse) => {
        if (!entryResponse) {
          return Promise.reject("Failed to check for admision.");
        }
        if (entryResponse.proceed) {
          if (entryResponse.entry) {
            let entryData: IEntry = entryResponse.entry;
            // Should these override the entry ID data? I wouldn't think so.
            this.location = this.getSafeHtml(entryData.name) || this.location;
            this.exitUrl = this.getSafeHtml(entryData.exitUrl)  || this.exitUrl;
            this.entryMessage = this.callCenterService.convertEntryDataToHTMLString(entryData);
            this.endpointService.myEndpoint.lastMessage = this.getSafeHtml(this.entryMessage);
            this.endpointService.myEndpoint.guestFormData = entryData.data;
          }
          this.endpointService.myEndpoint.theme = this.style;
        } else {
          redirectOnEntryError = entryResponse.redirectOnDenyUrl;
          return Promise.reject("Server rejected to admit.");
        }
      })
      .catch((error: Error) => {
        console.error(error);
        // Display ERROR Page or redirect.
        if (redirectOnEntryError) {
          window.location.href = redirectOnEntryError;
  
          // I guess we are not in this code anymore.
          console.log("Redirect failed.");
        } else {
          console.log("You must provide an entry ID in order to access this page.");
        }
        this.router.navigate(["/invalid-guest-entry"]);
      });
    } else {
      entryCheck = Promise.resolve();
    }

    // Perform entry check if needed and then proceed if possible.
    entryCheck
    .then(() => {
      // update the server with our info at this point
      this.endpointService.sendEndpointUpdate();
      this.updateLocalizationData(this.language)
      .catch((err: Error) => {
        return Promise.reject(err);
      })
      .then((data: ILocalization) => {
        this.conferenceService.alertHandler(
          AlertCode.getLocalizationDataSuccess, this.style + ", " + this.language, AlertLevel.info
        );

        const readyTimeLapse = this.localizationService.myLocalizationData.presenceStatus["readyTimelapse"] || 10;
        if (typeof readyTimeLapse == "string") {
          this.callCenterService.readyTimeLapse = parseInt(readyTimeLapse, 10);
        } else {
          this.callCenterService.readyTimeLapse = readyTimeLapse;
        }

        // Init video aspect
        const defaultVideoAspect = data.settings_panel.videoAspectDefault === VideoAspect.fill ? VideoAspect.fill : VideoAspect.fit;
        this.userService.currentUser.preferedVideoAspect = this.userService.currentUser.preferedVideoAspect || defaultVideoAspect;

        if (this.userService.currentUser.preferedVolume == null) {
          this.setDefaultVolume();
          this.volumeChanged();
        } else {
          this.videoVolume = this.userService.currentUser.preferedVolume;
        }
        Dispatcher.dispatch(ActionType.LoadConferenceBeforeJoinNavBar);
        let bindLoginLinkTimer = setTimeout(() => {
          clearTimeout(bindLoginLinkTimer);
          jQuery(".login-link").attr("data-target", "#loginModal").attr("data-toggle", "modal");
        }, 100);
        this.exitUrl = this.exitUrl || data.page.redirectUrlOnLeave;
        this.style = data.style;
        // if configured in theme, load simplified mobile view for mobile users
        this.isMobileApp = this.localizationService.getValueByPath(".page.loadSimplifiedViewForMobileUsers") && this.isMobileDevice;
        if (this.userService.currentUser.isAuthenticated && this.userService.currentUser.avatar) {
          this.rtcService.rtcClient.avatarImageSrc = this.userService.currentUser.avatar;
        } else if (this.userService.currentUser.preferedVideoMutedPlaceholder) {
          this.rtcService.rtcClient.avatarImageSrc = this.userService.currentUser.preferedVideoMutedPlaceholder;
        } else {
          this.rtcService.rtcClient.avatarImageSrc = data.settings_panel.avatarImageSrc;
        }
        if (data.settings_panel.virtualBackgroundMethod && SegmentMethod[data.settings_panel.virtualBackgroundMethod] != null) {
          this.rtcService.rtcClient.virtualBackgroundMethod = SegmentMethod[data.settings_panel.virtualBackgroundMethod];
        }
        if (this.rtcService.rtcClient.videoFilter && this.userService.currentUser.preferedVirtualBackgroundImage) {
          this.rtcService.rtcClient.videoFilter.virtualBackgroundImage = this.userService.currentUser.preferedVirtualBackgroundImage;
        }
        this.toggleMic(!!this.userService.currentUser.preferedAudioMute);
        this.rtcService.muteCamera(!!this.userService.currentUser.preferedVideoMute);
        if (
          data.settings_panel.resolution_setting.defaultPCValue &&
          data.settings_panel.resolution_setting.defaultPCValue.width &&
          data.settings_panel.resolution_setting.defaultPCValue.height
        ) {
          this.rtcService.rtcClient.PC_DEFAULT_PRIMARY_RESOLUTION = new VideoResolution(
            data.settings_panel.resolution_setting.defaultPCValue.width,
            data.settings_panel.resolution_setting.defaultPCValue.height
          );
        }
        if (
          data.settings_panel.resolution_setting.defaultMobileValue &&
          data.settings_panel.resolution_setting.defaultMobileValue.width &&
          data.settings_panel.resolution_setting.defaultMobileValue.height
        ) {
          this.rtcService.rtcClient.MOBILE_DEFAULT_PRIMARY_RESOLUTION = new VideoResolution(
            data.settings_panel.resolution_setting.defaultMobileValue.width,
            data.settings_panel.resolution_setting.defaultMobileValue.height
          );
        }
        if (
          data.settings_panel.resolution_setting.defaultPCSecondaryValue &&
          data.settings_panel.resolution_setting.defaultPCSecondaryValue.width &&
          data.settings_panel.resolution_setting.defaultPCSecondaryValue.height
        ) {
          this.rtcService.rtcClient.PC_DEFAULT_SECONDARY_RESOLUTION = new VideoResolution(
            data.settings_panel.resolution_setting.defaultPCSecondaryValue.width,
            data.settings_panel.resolution_setting.defaultPCSecondaryValue.height
          );
        }
        if (
          data.settings_panel.resolution_setting.defaultMobileSecondaryValue &&
          data.settings_panel.resolution_setting.defaultMobileSecondaryValue.width &&
          data.settings_panel.resolution_setting.defaultMobileSecondaryValue.height
        ) {
          this.rtcService.rtcClient.MOBILE_DEFAULT_SECONDARY_RESOLUTION = new VideoResolution(
            data.settings_panel.resolution_setting.defaultMobileSecondaryValue.width,
            data.settings_panel.resolution_setting.defaultMobileSecondaryValue.height
          );
        }
        if (data.settings_panel.resolution_setting.options) {
          this.rtcService.rtcClient.resolutionOptions = _.map(
            data.settings_panel.resolution_setting.options,
            (resolution: IMediaChannelSettingOption) => {
              return resolution.value;
            }
          );
        }
        _.merge(this.rtcService.rtcClient.resolutionOptions, [
          this.primaryCameraResolutionFromUrl,
          this.secondaryCameraResolutionFromUrl,
        ]);
        this.rtcService.rtcClient.resolutionOptions = _.compact(this.rtcService.rtcClient.resolutionOptions);

        if (
          data.settings_panel.resolution_setting.defaultPCValue ||
          data.settings_panel.resolution_setting.defaultMobileValue
        ) {
          this.rtcService.setToDefaultResolution(true);
        }
        if (
          data.settings_panel.resolution_setting.defaultPCSecondaryValue ||
          data.settings_panel.resolution_setting.defaultMobileSecondaryValue
        ) {
          this.rtcService.setToDefaultResolution(false);
        }
        if (this.localizationService.getValueByPath(".settings_panel.presentation_setting.sharpness_first.resolution")) {
          let resolution = this.localizationService.getValueByPath(".settings_panel.presentation_setting.sharpness_first.resolution");
          this.rtcService.rtcClient.PRESENTATION_SHARPNESS_FIRST_RESOLUTION = resolution;
        }
        if (this.localizationService.getValueByPath(".settings_panel.presentation_setting.sharpness_first.framerate")) {
          let framerate = this.localizationService.getValueByPath(".settings_panel.presentation_setting.sharpness_first.framerate");
          this.rtcService.rtcClient.PRESENTATION_SHARPNESS_FIRST_FRAMERATE = framerate;
        }
        if (this.localizationService.getValueByPath(".settings_panel.presentation_setting.motion_first.resolution")) {
          let resolution = this.localizationService.getValueByPath(".settings_panel.presentation_setting.motion_first.resolution");
          this.rtcService.rtcClient.PRESENTATION_MOTION_FIRST_RESOLUTION = resolution;
        }
        if (this.localizationService.getValueByPath(".settings_panel.presentation_setting.motion_first.framerate")) {
          let framerate = this.localizationService.getValueByPath(".settings_panel.presentation_setting.motion_first.framerate");
          this.rtcService.rtcClient.PRESENTATION_MOTION_FIRST_FRAMERATE = framerate;
        }
        if (data.settings_panel.bandwidth_setting.defaultPCValue) {
          this.rtcService.rtcClient.PC_DEFAULT_BANDWIDTH = data.settings_panel.bandwidth_setting.defaultPCValue;
        }
        if (data.settings_panel.bandwidth_setting.defaultMobileValue) {
          this.rtcService.rtcClient.MOBILE_DEFAULT_BANDWIDTH = data.settings_panel.bandwidth_setting.defaultMobileValue;
        }
        if (
          data.settings_panel.bandwidth_setting.defaultPCValue ||
          data.settings_panel.bandwidth_setting.defaultMobileValue
        ) {
          this.rtcService.setToDefaultBandwidth();
        }
        if (data.settings_panel.bandwidth_setting.options) {
          this.rtcService.rtcClient.bandwidthOptions = _.map(
            data.settings_panel.bandwidth_setting.options,
            (bandwidth: IMediaChannelSettingOption) => {
              return bandwidth.value;
            }
          );
        }
        this.rtcService.rtcClient.relayCandidateOnly = !!data.settings_panel.relayCandidateOnly;
        this.rtcService.rtcClient.disableAudioOnly = !!data.settings_panel.disableAudioOnly;
        if (data.SERVER_DISCONNECT_TIMEOUT) {
          this.rtcService.rtcClient.SERVER_DISCONNECT_TIMEOUT = data.SERVER_DISCONNECT_TIMEOUT;
        }
        this.conferenceService.setLocalVideoUpdateHandler(this.localVideoUpdateHandler.bind(this));
        if (this.isWebRTCSupport) {
          if (
            !this.isFirstTimeUser ||
            !this.localizationService.getValueByPath(".settings_panel.runTesterOnFirstVisit")
          ) {
            return this.init().catch((error: any) => {
              console.error(`Failed to init: ${JSON.stringify(error)}`);
            });
          } else {
            Dispatcher.dispatch(ActionType.OpenModalDialog, "helper-wizard", "helper-wizard");
          }
        } else {
          this.mediaAccessFinished = true;
          this.mediaAccessFailed = true;
          if (this.location != "guest") {
            this.createBrowserNotSupportAlert();
          }
        }
      })
    })
    .catch((err: Error) => {
      this.conferenceService.alertHandler(
        AlertCode.getLocalizationDataFail, this.style + ", " + this.language, AlertLevel.warning
      );
      this.router.navigate(["/not-found"]);
    });

    window["GUI"] = this;
  }

  isBrowserSupport(): boolean {
    const isWebRTCSupported = navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      window.RTCPeerConnection;

    if (navigator.userAgent.match("CriOS") || // Chrome for iOS
      navigator.userAgent.match("Edge") ||
      navigator.userAgent.match("FxiOS") || // Firefox for iOS
      navigator.userAgent.match("EdgiOS") // Edge for iOS
    ) {
      return false;
    }
    return !!isWebRTCSupported;
  }

  /**
   * create alert for browser not support
   */
  createBrowserNotSupportAlert() {
    let messageBody: string = "";
    messageBody +=
      "<p>" +
      (this.localizationService.myLocalizationData?.errorMessages?.["BROWSER_NOT_SUPPORT"] ||
        "The browser you are using does not support audio-visual communications.") +
      "</p>";
    messageBody +=
      "<p>" +
      (this.localizationService.myLocalizationData?.errorMessages?.["CONFIRM_TO_INSTALL_BROWSER"] ||
        "Press install button to upgrade or install the compatible browser") +
      "</p>";
    AlertService.createAlertWithButtons(messageBody, {
      confirm: {
        label: "Ok",
        className: "btn-success",
      },
      cancel: {
        label: "Cancel",
        className: "btn-default",
      },
    }).then((result: any) => {
      if (result) {
        window.open(
          Browser.getDownloadLink(),
          "targetWindow",
          "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1280,height=720"
        );
      } else {
        this.router.navigate(["/page/not-compatible-browser"]);
      }
    });
  }

   openKioskUnlockModal() {
    this.unlockKioskModal.open();
   }

   unlockPasscodeExited(event:any) {
    // Don't care about why it closed...
    //console.log("kiosk unlock modal exited:", event);
   }

   unlockPasscodeSumitted(passcode:string) {
    return new Promise((resolve: (response: string) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/validateKioskUnlock", { theme: this.localizationService.myLocalizationData.style, passcode: passcode})
      .subscribe({
        next : (data: any) => {resolve(data);},
        error : (error: any) => {reject(error);}
      });
    })
    .then((data: any) => {
      if (data?.success) {
        this.unlock(); // Update the UI
      } else {
        console.log("kiosk unlock denied");
      }
    })
    .catch((error) => {
      console.log("kiosk unlock validation failure", error);
    })
   }

  /**
   * user successful log in event handler
   */
  login(errorMessage: string): void {
    if (errorMessage) {
      this.conferenceService.alertHandler(AlertCode.loginFailed, errorMessage, AlertLevel.warning);
      return;
    }
    if (this.isLocked) {
      // TODO: check user permission to unlock
      this.unlock();
      return;
    }
    // check audio context ready for auto recording
    Companion.getRTCClient().prepareAudioContext();
    this.conferenceService.alertHandler(AlertCode.wait, null, AlertLevel.success);
    const currentUser: IUser = this.userService.currentUser;
    this.rtcService.setCurrentUsername(currentUser.name || currentUser.username);
    this.endpointService.myEndpoint.name = currentUser.name || currentUser.username;
    this.endpointService.myEndpoint.userId = (currentUser as PassportLocalDocument)._id;
    // update endpoint with new username
    this.endpointService.sendEndpointUpdate();
    if (currentUser.isAuthenticated) {
      this.isLoggedin = true;

      if (currentUser.avatar) {
        this.rtcService.rtcClient.avatarImageSrc = currentUser.avatar;
      }

      // Force load data (updates our own currentUser)
      this.userManagementService.getMyAccessibleUsersWithRolesAndGroups()
      .then((userData) => {
        this.callCenterService.LoadVCCNewData(userData, () => {
          this.endpointService.myEndpoint.theme = this.style;
          this.setViewMode();
          // login as away...
          let loginStatus : PresenceStatus = this.localizationService.myLocalizationData?.loginPanel?.loginAsAvailable 
            ? PresenceStatus.available : PresenceStatus.away
          this.ready(loginStatus);
        });
      })
      .catch((error) => {
        console.log("Failed to get user data: ", error);
      });
    }
  }

  themeChanged() {
    if (this.userService.currentUser.isAuthenticated) {
      const newTheme = this.route.snapshot.params["style"];
      if (newTheme) {
        this.endpointService.myEndpoint.theme = newTheme;
        this.style = newTheme;
        this.language = newTheme.language;
        this.setViewMode();
        Dispatcher.dispatch(ActionType.LoadVCCOperatorAfterJoinNavBar);
        this.endpointService.sendEndpointUpdate();
        this.fetchThirdPartyAPIIntegrationSettings();
      }
    }
  }

  /**
   * user log out event handler
   */
  logout(): void {
    Dispatcher.dispatch(ActionType.LogOut);
    this.leave(false);
  }

  /**
   * guest log in event handler
   */
  guestLogin(user: IUser): void {
    if (this.mediaAccessFailed) {
      const userData = {
        name: user.username,
        theme: this.style,
        data: this.endpointService.myEndpoint.guestFormData,
        twilioPin: user.twilioPin
      };
      this.callCenterService
      .postUserData(userData)
      .then(entryId => {
      })
      .catch((error: Error) => {
        console.error(error);
      });
      return;
    }
    // check audio context ready for auto recording
    Companion.getRTCClient().prepareAudioContext();
    this.setViewMode();

    const currentUser = this.userService.currentUser;
    this.endpointService.myEndpoint.name = this.location || currentUser.username;
    this.endpointService.myEndpoint.phoneNumber = currentUser.phone;
    this.rtcService.setCurrentUsername(this.location || user.username);
    this.settingsPanelShown = false;
    this.ready();
  }

  /**
   * check if auto record should start/pause/resume/stop
   */
  checkGuestsForAutoRecord() {

    // make sure we aren't recording anyway for no reason.
    if (this.conferenceService.isRecording() && (!this.activeConference && 0 == this.conferenceService.countMonitoringConferences())) {
      this.stopRecord();
      return;
    }

    // If we don't care about auto-record, return.
    if (
      !this.localizationService.myLocalizationData.record_panel ||
      !this.localizationService.myLocalizationData.record_panel.autoRecord ||
      (!this.userService.currentUser.isOperator && !this.userService.currentUser.isRep)
    ) {
      return;
    }

    let connectedOtherEndpoints = _.filter(
      this.activeConference?.active,
      (ep: IEndpoint) => {
        return ep.rtcId !== this.endpointService.myEndpoint.rtcId;
      }
    );

    let heldOtherEndpoints = _.filter(
      this.activeConference?.held,
      (ep: IEndpoint) => {
        return ep.rtcId !== this.endpointService.myEndpoint.rtcId;
      }
    );

    // Only if we are doing auto-record and have a conference and stuff.
    if (!this.conferenceService.isRecording()) {
      if (connectedOtherEndpoints.length > 0) {
        this.startRecord();
      }
    } else {
      if (connectedOtherEndpoints.length === 0 && heldOtherEndpoints.length === 0) {
        this.stopRecord();
      } else {
        
        if (connectedOtherEndpoints.length === 0 && heldOtherEndpoints.length > 0) {
          this.pauseRecord();
        } else if (connectedOtherEndpoints.length > 0) {
          this.resumeRecord();
        }
      }
    }
  }

  // Go look for subjects I am monitoring and exit that monitor session.
  stopAllMonitors(): void {
    _.forEach([...this.conferenceService.monitors.values()], (mon: IMonitorConference) => {
      // Look at all monitors for those that I am an observer.
      if (!!_.find(mon.observers, (observer) => {
        return observer.rtcId == this.endpointService.myEndpoint.rtcId;
      })) {
        // Walk the subjects and exit monitoring.
        _.forEach(mon.subjects, (subject) => {
          // Fetch endpoint
          let ep = this.endpointService.getEndpointById(subject.rtcId);
          if (ep) {
            this.exitMonitor(ep);
          }
        })
      }
    })
  }

  /**
   * check if ringtone should prompt
   */
  checkGuestToPromptRingTone(guestEp: IEndpoint): void {
    if (!guestEp) {
      return;
    }
    if (this.guestToAnswerEp == null) {
      this.guestToAnswerEp = guestEp;

      if (!this.localizationService.myLocalizationData.ring_tone_panel || !this.userService.currentUser.isOperator) {
        // Shortcut, OK to connect in this case, send the ack.
        this.endpointService.sendConfirmAskToAnswer(this.endpointService.myEndpoint, guestEp);
        // Don't forget to invite the caller to our conference if we are the operator!
        if (this.userService.currentUser.isOperator) {
          this.peerVideoChat(this.guestToAnswerEp, ConnectionType.inbound);
        }
        return;
      }

      // Gate the update to the ask to answer and prompt ringtone to after state update completes.
      this.endpointService.myEndpoint.status = PresenceStatus.connecting;
      let ring_duration = this.localizationService.myLocalizationData.ring_tone_panel.duration;
      if (!ring_duration) {
        ring_duration = 30;
      }
      if (ring_duration > 0) {
        this.rejectCallTimer = setTimeout(() => {
          this.rejectToAnswerRingingEndpoint(guestEp, true);
        }, ring_duration * 1000);
      }
      this.endpointService.sendConfirmAskToAnswer(this.endpointService.myEndpoint, guestEp);
      this.promptRingtone(this.guestToAnswerEp,
        () => {
          clearTimeout(this.rejectCallTimer);
          this.peerVideoChat(this.guestToAnswerEp, ConnectionType.inbound);
        },
        () => {
          clearTimeout(this.rejectCallTimer);
          this.rejectToAnswerRingingEndpoint(this.guestToAnswerEp, true);
        });
    }
  }

  /**
   * check if an endpoint we are attempting to transfer still exists (they may have left).
   */
  checkTransferEndpointStillExists(): void {
    if(this.opSelectedGuest)
    {
        const epInQueue =
        this.callCenterService.globalPublicWaitList.find(
        ep => ep.rtcId === this.opSelectedGuest.rtcId);
        if(!epInQueue)
        {
          this.opSelectedGuest = null;
          this.transferHasSelected = false;
        }
    }
  }

  
  cancelCall(rtcId: string) : void {
    this.callCenterService.cancelCall(rtcId);
  }

  /**
   * prompt ringtone
   */
  promptRingtone(endpoint: IEndpoint, confirmCallback?: () => void, cancelCallback?: () => void) {
    bootbox.hideAll();

    this.ringingEndpoint = endpoint;
    let ringMsgBody: string = this.localizationService.myLocalizationData.ring_tone_panel.title || "";
    ringMsgBody += '<h3 class="text-center">' + decodeURIComponent(endpoint.uiName) +
      (endpoint.status == PresenceStatus.callback ? '<span class="badge badge-info ml-1 vertical-middle">CALLBACK</span>' : "") + "</h3>";
    if (!!endpoint.skillTags) {
      ringMsgBody += "<p><span><b>Queue: </b></span>" + endpoint?.skillTags?.category + " / " + endpoint?.skillTags?.language + "</p>";
    } else {
      ringMsgBody += endpoint.language ? "<p><span><b>Language: </b></span>" + endpoint.language + "</p>" : "";
    }

    ringMsgBody += endpoint.lastMessage ? '<p style="padding: 0px 3em;">' + endpoint.lastMessage + "</p>" : "";
    ringMsgBody += '<audio src="/media/ringtone.mp3" autoplay playsinline loop/>';

    let confirmButton = this.localizationService.myLocalizationData.ring_tone_panel.confirmButtonText;
    const cancelButton = this.localizationService.myLocalizationData.ring_tone_panel.exitButtonText;
    if (endpoint.isVoiceEp) {
      confirmButton = this.localizationService.myLocalizationData.ring_tone_panel.confirmVoiceButtonText || "Voice Call";
    }

    AlertService.createAlertWithButtons(ringMsgBody, {
      confirm: {
        label: confirmButton,
        className: "btn-success ringtone-confirm",
      },
      cancel: {
        label: cancelButton,
        className: "btn-danger",
      },
    }).then((result: any) => {
      this.ringingEndpoint = null;
      if (result && confirmCallback) {
        confirmCallback();
      } else if (!result && cancelCallback) {
        cancelCallback();
      }
    });
  }

  
  /**
   * ready to connect rtc server
   */
  ready(joinState : PresenceStatus = PresenceStatus.available) {
    // connect the easy rtc service here.
    this.rtcService.connect();
    this.endpointService.create(this.endpointService.myEndpoint);
    if (this.userService.currentUser.isAuthenticated) {
      this.endpointService.myEndpoint.skillSet = this.userService.currentUser.skillSet; // Lock in the base skillset from login.
    
      this.callCenterService.enableConferenceUpdates(); // make sure we are getting updates.
      this.callCenterService.ensureNextEmptyConf((success) => {
        this.joinQueues(joinState);  // Join queues can check if no queue is allowed due to theme conflict
      });
    } else {
      this.callCenterService.enableConferenceUpdates(); // make sure we are getting updates.
      this.joinQueues(joinState);  // Join queues can check if no queue is allowed due to theme conflict
    }

    let shareFilesPosition = this.navBarService.isToolbarItemVisible("sharedFolder") ? NavBarMenuPosition.Left : NavBarMenuPosition.None;
    
    Dispatcher.dispatch(ActionType.UpdateMenuItem, {
      key: NavBarMenuItemKey.ShareFiles,
      position: shareFilesPosition,
      disabled: false,
    });
    // Share folder visible now
  }

  /**
   * method to join accessible queues, update status to available,
   * reset video screen,toolbar, geolocation, recording and screen lock
   */
  joinQueues(joinState : PresenceStatus = PresenceStatus.available, transferJoin: boolean = false) {
    this.callCenterService.joinVisibleQueues(this.style, this.skillTags)
    .then(() => {
      if(joinState == PresenceStatus.available)
      {
        this.endpointService.markClientReady();
      }
      else
      {
        // the default state is unready, no need to update the server just set the client here
        this.endpointService.myEndpoint.status = joinState;
      }

      this.joined = true;
      this.localVideoUpdateHandler();
      
      if (this.geoCaptureRequired) {
        this.endpointService
          .getGeoLocation()
          .then((location: any) => {
            this.endpointService.myEndpoint.location = location;
            this.endpointService.sendEndpointUpdate();
          })
          .catch((error: Error) => {
            this.conferenceService.alertHandler(AlertCode.getGeoLocationError, error.message, AlertLevel.warning);
          });
      }
      this.checkGuestsForAutoRecord();
      Dispatcher.dispatch(this.viewMode === "guest" ? ActionType.LoadVCCGuestAfterJoinNavBar : ActionType.LoadVCCOperatorAfterJoinNavBar);
      Dispatcher.dispatch(ActionType.LoadTopBarMenu);
      Dispatcher.dispatch(ActionType.ToggleMapScreen, {
        enabled:
          this.mapViewEnabled &&
          !this.localizationService.myLocalizationData.map_panel.hideByDefault,
      });
      if (
        this.localizationService.getValueByPath(".lock_panel.autoLock") &&
        this.navBarService.isToolbarItemVisible("lock") &&
        this.viewMode === "guest"
      ) {
        Dispatcher.dispatch(ActionType.Lock);
      }

      // attempt to init the voice service here, if it is not already
      if (this.voiceService.isNotInitialized() && this.localizationService.myLocalizationData.twilioConfig) {
        this.voiceService.initializationPromise = this.voiceService.init(
          !!this.userService.currentUser.preferedAudioMute, this.handleVoiceServiceUpdate.bind(this))
        .catch((error) => console.error("Unable to initialize the voice client", error));
      }

      if (this.viewMode === "guest" &&
        this.queueScreenEnabled &&
        this.skillTags &&
        (this.endpointService.myEndpoint.skillTags?.category == this.skillTags?.category &&
          this.endpointService.myEndpoint.skillTags?.language == this.skillTags?.language)) {
        console.log("Joining specified queue!");
        this.tryConnectToWaitingRoom();
      } else if (transferJoin) {
        console.log("Joining specified queue via transfer!");
        this.tryConnectToWaitingRoom();
      }
      this.endpointService.sendEndpointUpdate();
    })
    .catch((err: Error) => {
      this.conferenceService.alertHandler(AlertCode.joinAccessibleQueuesFailed, `${err.name}, ${err.message}`, AlertLevel.warning);
    });
  }

  private handleVoiceServiceUpdate(state, data?) {
    this.voiceConferenceUpdateRoutine();
    switch (state) {
      case "ready":
        this.conferenceService.alertHandler(AlertCode.voiceServiceReady, "[VoiceService] Device ready", AlertLevel.success);
        break;
      case "offline":
        if (this.viewMode != "guest") {
          this.conferenceService.alertHandler(AlertCode.voiceServiceOffline, "[VoiceService] Device Offline", AlertLevel.warning);
        }
        break;
      case "error":
        const twilioConfig = this.localizationService.myLocalizationData.twilioConfig;
        const defaultMessage = "Voice service error. Please contact the system administrator";
        const message = twilioConfig ? twilioConfig.errorMessage : null;
        if (this.userService.currentUser.isAuthenticated) {
          this.alertHandler(AlertCode.voiceServiceError, message || defaultMessage + " - " + data.code, AlertLevel.warning);
        }
        break;
      default:
        break;
    }
  }

  private voiceConferenceUpdateRoutine() {
    this.setVoiceDialOutEnabled();

    if (ConferenceUtil.isVoiceConferenceRef(this.activeConference)) {
      this.rtcService.muteMicrophone(true);
      this.voiceService.adjustVolume(this.videoVolume);
      // close the dial panel if it is open
      this.dialOutComponent.close();
    }
    else
    {
      this.rtcService.muteMicrophone(this.rtcService.rtcClient.audioMuted);
      this.voiceService.adjustVolume(this.videoVolume);
    }

    if (this.videoComponent) {
      this.videoComponent.createOrPatchAudioNodesToRecord();
    }
  }

  private setVoiceDialOutEnabled() {
    this.voiceDialoutEnabled = this.voiceService.isReady()
      && !ConferenceUtil.isVoiceConferenceRef(this.activeConference)
      && !this.maxParticipantsReached()
      // if we are already in the conference, we can only add a phone if we own it
      && (!this.endpointService.myEpConnectedAsGuest())
      && (this.endpointService.myEndpoint.status === PresenceStatus.available ||
          this.endpointService.myEndpoint.status === PresenceStatus.busy ||
          this.endpointService.myEndpoint.status === PresenceStatus.alone_in_conf)
  }


  /**
   * Reset all local component variables, leave any conferences (if we were still joined) and after 1 second, attempt to reinit the app
   */
  resetComponent() {
    console.log("YOU ARE DISCONNECTED!");
    this.restService.invalidateCsrfToken();
  
    if (this.joined) {
      this.leave(false);
    }
    this.joined = false;
    this.isLoggedin = false;
    this.transferHasSelected = false;
    this.incomingQueueShown = false;
    this.operatorsPanelShown = false;
    this.chatBoxShown = false;
    this.settingsPanelShown = false;
    this.unreadFileCount = 0;
    this.totalFileCount = 0;
    this.showSharedFolder = false;
    this.showLoopback = true;
    Dispatcher.dispatch(ActionType.ToggleMapScreen, {enabled: false});
    this.isNotepadShown = false;
    this.initialized = false;
    this.hasEstablishedSession = false;
    // we should destroy the data in the call center service.
    // so we don't rely on stale data.
    this.callCenterService.reset();
    
    this.updateCurrentVideoComponent();
    let reinitTimer = setTimeout(() => {
      clearTimeout(reinitTimer);
      this.init().catch((error: any) => {
        console.error(`Failed to init: ${JSON.stringify(error)}`);
      });
    }, 1 * 1000);
  }

  /**
   * update video screen with currently connected endpoints
   */
  updateCurrentVideoComponent() {
    if (this.videoComponent) {
      this.videoComponent.videoReset();
    }
  }

  /**
   * update view mode
   */
  private setViewMode() {
    this.listTypesLeft = [];
    this.listTypesRight = [];
    this.chatroomListTypes = [];
    if (this.userService.currentUser.isOperator) {
      this.viewMode = "op";
      // load full feature desktop view for operators
      this.isMobileApp = false;
      this.endpointService.myEndpoint.isHost = true;
      //this.chatroomListTypes.push("ep"); // Don't include or will preclude other list types
      if (this.localizationService.myLocalizationData.participant_panel?.listTypes?.["publicWait"]) {
        this.listTypesLeft.push("publicWait");
        this.chatroomListTypes.push("publicWait");
      }
      if (this.listTypesLeft.length > 1) {
        this.layoutLeft = "tabs";
      } else {
        this.layoutLeft = "list";
      }
      //if (this.localizationService.myLocalizationData.participant_panel?.listTypes?.["op"]) 
      {
        this.listTypesRight.push("op");
        this.chatroomListTypes.push("op");
      }
      if (this.localizationService.myLocalizationData.participant_panel?.listTypes?.["sp"]) {
        this.listTypesRight.push("sp");
        this.chatroomListTypes.push("sp");
      }
      this.layoutRight = "list";
      this.incomingQueueShown = true;
      this.operatorsPanelShown = true;
    } else if (this.userService.currentUser.isRep) {
      this.viewMode = "sp";
      // load full feature desktop view for specialists
      this.isMobileApp = false;
      this.endpointService.myEndpoint.isHost = true;
      //this.chatroomListTypes.push("ep"); // Don't include or will preclude other list types
      if (this.localizationService.myLocalizationData.participant_panel?.listTypes?.["publicWait"]) {
        this.listTypesLeft.push("publicWait");
        this.chatroomListTypes.push("publicWait");
      }
      if (this.listTypesLeft.length > 1) {
        this.layoutLeft = "tabs";
      } else {
        this.layoutLeft = "list";
      }
      if (this.localizationService.myLocalizationData.participant_panel?.listTypes?.["op"]) {
        this.listTypesRight.push("op");
        this.chatroomListTypes.push("op");
      }
      //if (this.localizationService.myLocalizationData.participant_panel?.listTypes?.["sp"])
      {
        this.listTypesRight.push("sp");
        this.chatroomListTypes.push("sp");
      }
      this.layoutRight = "list";
      this.incomingQueueShown = true;
      this.operatorsPanelShown = true;
    } else {
      this.viewMode = "guest";
      // if configured in theme, load simplified mobile view for mobile users
      this.isMobileApp = this.localizationService.getValueByPath(".page.loadSimplifiedViewForMobileUsers") && this.isMobileDevice;
      this.endpointService.myEndpoint.isHost = false;
      this.listTypesLeft.push("op");
      this.layoutLeft = "list";
      this.listTypesRight.push("op");
      this.layoutRight = "list";
      this.incomingQueueShown = false;
      this.operatorsPanelShown = false;
      this.chatroomListTypes.push("ep"); // Active conference only
      this.touchlessGuestConnect = this.localizationService.getValueByPath(".connect_screen.touchless");
    }
  }

  /**
   * toggle microphone
   * @param muted:boolean - flag if to mute microphone
   */
  toggleMic(muted: boolean) {
    this.rtcService.rtcClient.audioMuted = muted;
    this.userService.currentUser.preferedAudioMute = muted;
    GlobalService.setSessionUser(this.userService.currentUser);

    this.voiceService.mute(muted);

    // During voice conference rtc audio track is disabled by default
    // so the mute button does not have any effect on RTC audio
    if (!ConferenceUtil.isVoiceConferenceRef(this.activeConference)) {
      this.rtcService.muteMicrophone(muted);
    }
  }

  /**
   * toggle camera
   * @param muted:boolean - flag if to mute camera
   */
  toggleCamera(muted: boolean) {
    this.rtcService.muteCamera(muted);
  }

  /**
   * toggle participant panel
   */
  toggleParticipantPanel(isCloseButton: boolean) {
    if (isCloseButton) {
      this.incomingQueueShown = false;
      this.operatorsPanelShown = false;
    } else {
      let isEitherOpenBeforeToggle = this.incomingQueueShown || this.operatorsPanelShown;
      this.incomingQueueShown = !isEitherOpenBeforeToggle;
      this.operatorsPanelShown = !isEitherOpenBeforeToggle;
    }
    let isEitherOpenAfterToggle = this.incomingQueueShown || this.operatorsPanelShown;
    if (isEitherOpenAfterToggle) {
      this.chatBoxShown = false;
      this.settingsPanelShown = false;
    }
  }

  /**
   * toggle incoming queue
   */
  toggleIncomingQueue(isCloseButton: boolean) {
    if (isCloseButton) {
      this.incomingQueueShown = false;
    } else {
      this.incomingQueueShown = !this.incomingQueueShown;
    }
    if (this.incomingQueueShown) {
      this.chatBoxShown = false;
      this.settingsPanelShown = false;
    }
  }

  /**
   * toggle operators panel
   */
  toggleOperatorsPanel(isCloseButton: boolean) {
    if (isCloseButton) {
      this.operatorsPanelShown = false;
    } else {
      this.operatorsPanelShown = !this.operatorsPanelShown;
    }
  }

  /**
   * toggle chatbox
   */
  toggleChatBox(payload: {forceOpen: boolean, forceClose: boolean}) {
    if (!this.chatRoomComponent) {
      return;
    }
    this.chatBoxShown = !this.chatBoxShown;
    if (payload && payload.forceOpen) {
      this.chatBoxShown = true;
    }
    if (payload && payload.forceClose) {
      this.chatBoxShown = false;
    }

    if (this.chatBoxShown) {
      this.chatRoomComponent.openChatBox();

      this.incomingQueueShown = false;
      this.settingsPanelShown = false;
    }
  }

  /**
   * toggle setting panel
   */
  toggleSettingsPanel(isCloseButton: boolean) {
    if (isCloseButton) {
      this.settingsPanelShown = false;
    } else {
      this.settingsPanelShown = !this.settingsPanelShown;
    }
    if (this.settingsPanelShown) {
      this.chatBoxShown = false;
      this.incomingQueueShown = false;
    }
  }

  /**
   * toggle inspector panel
   * @param isCloseButton: boolean - flag if from close button
   */
  toggleInspectorPanel(isCloseButton: boolean) {
    if (isCloseButton) {
      this.inspectorPanelShown = false;
    } else {
      this.inspectorPanelShown = !this.inspectorPanelShown;
    }

    if (this.inspectorPanelShown) {
      this.chatBoxShown = false;
      this.settingsPanelShown = false;
    }
  }

  /**
   * toggle hamburger menu
   * @param isCloseButton: boolean - flag if from close button
   */
  toggleMenuPanel(isCloseButton: boolean) {
    if (isCloseButton) {
      this.menuPanelShown = false;
    } else {
      this.menuPanelShown = !this.menuPanelShown;
    }

    if (!this.menuPanelShown) {
      this.chatBoxShown = false;
      this.settingsPanelShown = false;
      this.toggleParticipantPanel(true);
    }
  }

  /**
   * start text chat with an endpoint
   */
  peerChat(endpoint: IEndpoint) {
    this.chatRoomComponent.createAndJoinPeerChatRoom(endpoint);
    this.incomingQueueShown = false;
    this.chatBoxShown = true;
  }

  /**
   * Call back guest
   */
  callBackGuest($event: IDialOutData) {
    this.endpointService.sendCallbackRequest($event.phone, $event.displayPhoneNumber).then(() =>
    {
      this.endpointService.myEndpoint.phoneNumber = $event.phone;
      this.endpointService.myEndpoint.displayPhoneNumber = $event.displayPhoneNumber;
      this.rtcService.disconnect(); // disconnect our RTC service so we no longer communicate with the server.
      Dispatcher.dispatch(ActionType.LoadGuestCallbackNavBar);
    }).catch((error: any) =>
    {
      this.conferenceService.alertHandler(
        AlertCode.callbackFailed, error ? error.message : null, AlertLevel.error);
    });
  }

  /**
   * start to connect waiting room
   */
  tryConnectToWaitingRoomFromQueue(item: IQueueSelectionItem) {
    // Grab the current URL tree.
    const urlTree = this.router.parseUrl(this.router.url);
    // Fix it to remove params.
    urlTree.queryParams = {};
    urlTree.fragment = null; // optional
    // Get me all the params split out
    var sitepathitems = urlTree.toString().split("/");

    // Update the language code. (mandatory param for guests)
    sitepathitems[sitepathitems.length - 2] = item.langCode; // change second from last

    // Update endpoint with new configs.
    this.endpointService.myEndpoint.language = item.languageText;
    this.endpointService.myEndpoint.skillTags = {category: item.queueCat, language: item.queueLang};

    // Create proper query params for this
    var qparams = {queueCat: item.queueCat, queueLang: item.queueLang};

    // Change the URL by merging the new params.
    this.router.navigate(sitepathitems, {queryParamsHandling: "merge", queryParams: qparams});

    this.changeRooms()
    .then(() => {
      // Start the connection process.
      this.tryConnectToWaitingRoom();
    })
    .catch((error) => {
      this.conferenceService.alertHandler(AlertCode.joinQueueFail, error ? error.message : null, AlertLevel.warning);
    });
  }

  /**
   * start to connect waiting room
   */
  tryConnectToWaitingRoom() {
    this.checkAnyOperatorOnline()
    .then((result: boolean) => {
      this.hasOperatorOnline = result;
    })
    .catch((err: Error) => {
      this.hasOperatorOnline = true;
    });
    if (Endpoint.getPresenceStateByStatus(this.endpointService.myEndpoint.status) !== PresenceState.dnd) {
      if (this.rtcService.rtcClient.cameraStream) {
        console.log("SEND AWAIT INVITATION!");
        this.endpointService.awaitInvitation();
      } else {
        this.endpointService.myEndpoint.status = PresenceStatus.invisible;
      }
    }
    if (this.localizationService.getValueByPath(".connect_screen.connectButtonAutoFullScreen")) {
      Dispatcher.dispatch(ActionType.EnterFullScreen);
    }
  }

  /**
   * disconnect from waiting room
   */
  disconnectFromWaitingRoom() {
    this.leave(!this.localizationService.getValueByPath(".connect_screen.autoLeave"));
  }

  /**
   * disconnect with current call
   */
  disconnectFromCurrentConference(status: PresenceStatus) {
    this.disconnectAll();
    this.updateCurrentVideoComponent();
    if (status && this.endpointService.myEndpoint.status !== status) {
      this.endpointService.myEndpoint.status = PresenceStatus.connecting;
    }
  }

  /**
   * disconnect current operator
   */
  disconnectCurrentOperator() {      
    this.rtcService.hangupAll();
    this.endpointService.markClientReady();
    this.updateCurrentVideoComponent();
  }

  /**
   * This is called when the agent clicks the "Disconnect" button on a endpoint item.
   * It calls remove endpoint from conference, if this was the only endpoint in the active conference,
   * we call remove from conference on ourself as well.
   */
  disconnectPeer(endpoint: IEndpoint) {
    if (endpoint.isVoiceEp && this.localizationService.myLocalizationData.twilioConfig ) {
      this.voiceService.removeVoiceParticipant(endpoint.rtcId, endpoint.twilioCallId)
      .catch(error => console.error("UNABLE TO DISCONNECT VOICE CLIENT: ", error));
      this.endpointService.connectingOperator = null;
      if (this.guestToAnswerEp &&
        this.guestToAnswerEp.rtcId === endpoint.rtcId) {
        this.guestToAnswerEp = null;
      }
    }
    else
    {
      // if this is the only other endpoint in the conference, remove ourself as well...
      if(this.activeConference?.everyone?.length <= 2)
      {
        this.endpointService.removeFromConference(this.endpointService.myEndpoint.rtcId, 
          this.conferenceService.activeConference);
      }

      // remove an endpoint from our conference...
      this.endpointService.removeFromConference(endpoint.rtcId, this.conferenceService.activeConference);
    }
  }

  /**
   * disconnect with all others
   */
  disconnectAll() {
    this.toggleMic(!!this.userService.currentUser.preferedAudioMute);
    this.voiceService.disconnect();
    this.rtcService.hangupAll();
  }

  /**
   * start video chat with an endpoint
   */
  peerVideoChat(endpoint: IEndpoint, connectionType: ConnectionType) {
    // Don't allow to establish a connection with a Twilio endpoint when the voice service is not ready
    if(!endpoint)
    {
      this.conferenceService.alertHandler(AlertCode.callFailed, "Endpoint no longer available", AlertLevel.warning);
      return;
    }

    // don't allow connecting during a transitioning state
    // (only exception is we allow connecting to endpoint we are already trying to answer...)
    if(this.endpointService.myEndpoint.status == PresenceStatus.disconnecting ||
      (this.endpointService.myEndpoint.status == PresenceStatus.connecting && endpoint.rtcId != this.guestToAnswerEp?.rtcId))
    {
      this.conferenceService.alertHandler(AlertCode.callFailed, "Not in the correct state to connect", AlertLevel.warning)
      return;
    }

    const twilioConfig = this.localizationService.myLocalizationData.twilioConfig;
    if (endpoint.isVoiceEp && twilioConfig && !this.voiceService.isReady()) {
      const defaultMessage = "Voice service is not ready. Please contact the system administrator";
      const message = twilioConfig ? twilioConfig.errorMessage : null;
      this.conferenceService.alertHandler(AlertCode.voiceServiceNotReady, message || defaultMessage, AlertLevel.warning);
      return;
    }
    let previousStatus : PresenceStatus = this.endpointService.myEndpoint.status;
    if (this.endpointService.myEndpoint.status != PresenceStatus.busy && 
        this.endpointService.myEndpoint.status != PresenceStatus.connecting) {
      this.endpointService.myEndpoint.status = PresenceStatus.connecting;
    }

    // check audio context ready for recording
    Companion.getRTCClient().prepareAudioContext();
    const user = this.userService.findUserById(endpoint.userId) || {};

    // add this into our active conference unless it doesn't exist
    let confId = this.conferenceService.activeConference ? this.conferenceService.activeConference : this.conferenceService.findEmptyOwnedConference()?.id;

    if(!confId)
    {
      this.conferenceService.alertHandler(AlertCode.callFailed, "Call dial failed", AlertLevel.error);
      console.warn("No empty conference for dial call.");
      // revert to previous status.
      this.endpointService.myEndpoint.status = previousStatus;
      return;
    }

    if (this.conferenceService.isRecording && !user.isRep && !user.isOperator) {
      this.stopRecord();
    }

    if (endpoint.isVoiceEp) {
      // if we don't have twilio config... handle externally the call externally.
      if (twilioConfig) {
        const dialOutData = {
          phone: endpoint.phoneNumber,
          displayPhoneNumber: endpoint.displayPhoneNumber,
          name: endpoint.name
        };
        // DIAL out to this endpoint... 
        this.dialTelephoneNumber(dialOutData, endpoint.rtcId);
      } else {
        this.endpointService.sendHandleCallbackExternally(endpoint.rtcId, 
          confId);
      }
    } else if (this.viewMode != "guest" && this.transferHasSelected) {
      if (this.endpointService.isOperator(endpoint)) {
        // INITIATE A transfer to this endpoint
        this.endpointService.transferToOperator(this.opSelectedGuest, endpoint);
        this.transferHasSelected = false;
        this.opSelectedGuest = null;
      }
    } 
    // NOT sure we care to check state here, we could potentially just let the server prevent this...
    else if (endpoint.status === PresenceStatus.available || 
             endpoint.status === PresenceStatus.connecting || 
             endpoint.status === PresenceStatus.ready) {
      if (this.endpointService.isOperator(endpoint)) {
        this.endpointService.connectingOperator = {
          endpoint,
          isCaller: true
        };
      } 
      // Check if we are connecting to a guest outbound but they were actually already connecting.
      else if (connectionType === ConnectionType.outbound && endpoint.status === PresenceStatus.connecting) {
        connectionType = ConnectionType.inbound; // Force this to inbound. JP: HACK TODO FIX
      }

      const targets = this.activeConference?.active || [];
      console.log("Send connect invitation to", endpoint.rtcId);
      this.stopAllMonitors(); // If we are inviting, stop monitoring.
      this.endpointService.sendInvitationToConnect(
        endpoint.rtcId,
        confId,
        () => {
          this.conferenceService.alertHandler(AlertCode.inviteToConnectSuccess,
            `Endpoint: ${endpoint.name}, Caller: ${this.endpointService.myEndpoint.name}, Targets: ${targets.map(ep => ep.name)}, isTransfer: ${false}, isResumeGuestConference: ${false}`);
          if (this?.guestToAnswerEp?.rtcId == endpoint.rtcId) {
            this.cleanRingingChime();
          }
        },
        (error) => {
          // invitation failed.
          this.conferenceService.alertHandler(AlertCode.inviteToConnectFailed,
            `Failed to invite endpoint ${endpoint.name}: ${error}`);
          
          if (this.guestToAnswerEp && 
              this.endpointService.myEndpoint.status === PresenceStatus.connecting) {
            this.rejectToAnswerRingingEndpoint(endpoint);
          } 
          else {
            // revert to previous status.
            this.endpointService.myEndpoint.status = previousStatus;
          }
        }
      );
    }
    else {
      // The endpoint is not in an appropriate state to connect to
      // revert to previous status.
      this.endpointService.myEndpoint.status = previousStatus;
    }
  }

  /**
   * reject to connect with endpoint
   * @param endpoint, the rining endpoint
   * @param goAway, to go to the away state after rejecting (otherwise we return to available.)
   */
  rejectToAnswerRingingEndpoint(endpoint: IEndpoint, goAway : boolean = false) {
    this.endpointService.sendRejectToAnswer(endpoint, () =>
    {
      this.cleanRingingChime();
      if(goAway)
      {
        this.endpointService.myEndpoint.status = PresenceStatus.away;
      }
      else
      {
        this.endpointService.myEndpoint.status = PresenceStatus.ready;
        this.callCenterService.startReadyTimer(PresenceStatus.ready);
      }
    }, (error)=>
    {
      console.warn(error);
      this.cleanRingingChime();
      // we've failed the rejection for whatever reason... set us to away.
      this.endpointService.markClientUnready(StatusReason.away);
    });
  }

  /**
   * clean ringing chime
   */
  cleanRingingChime() {
    clearTimeout(this.rejectCallTimer);
    this.guestToAnswerEp = null;
    bootbox.hideAll();
  }

  /**
   * toggle if a guest is picked to transfer
   */
  toggleTransferSelection(endpoint: IEndpoint): void {
    if (endpoint) {
      this.transferHasSelected = true;
      this.opSelectedGuest = endpoint;
    } else {
      this.transferHasSelected = false;
      this.opSelectedGuest = null;
    }
  }

  /**
   * new text message received event listener
   */
  messageReceivedHandler() {
    Dispatcher.dispatch(ActionType.ResetNavBarNotification, {
      key: NavBarMenuItemKey.Chat,
      value: this.chatRoomService.countTotalUnreadMessages(),
    });
  }

  /**
   * toggle screen capture
   */
  toggleScreenCapture(isClose: boolean) {
    this.rtcService.toggleScreenSharing(!this.rtcService.rtcClient.screenShareEnabled && !isClose);
  }

  /**
   * toggle share folder window
   */
  toggleSharedFolder(isClose: boolean) {
    this.showSharedFolder = jQuery("#sharedFolderModal").hasClass("in");
    this.showSharedFolder = !this.showSharedFolder;

    if (this.showSharedFolder) {
      this.unreadFileCount = 0;
      Dispatcher.dispatch(ActionType.ResetNavBarNotification, {key: NavBarMenuItemKey.ShareFiles});
      Dispatcher.dispatch(ActionType.OpenModalDialog, "sharedFolderModal");
    }
  }

  /**
   * toggle loopback
   */
  toggleLoopback(loopbackOn: boolean) {
    this.showLoopback = loopbackOn;
  }

  /**
   * open dial out popup
   */
  dialOut() {
    Dispatcher.dispatch(ActionType.OpenModalDialog, "dialOutModal");
    this.dialOutComponent?.refreshSkillTags();
  }

  /**
   * open cropper popup
   */
  openCropper() {
    Dispatcher.dispatch(ActionType.OpenModalDialog, "cropperModal");
  }

  /**
   * open keypad popup
   */
  openKeypad() {
    Dispatcher.dispatch(ActionType.OpenModalDialog, "keypadModal");
  }

  /**
   * open guest info modal
   */
  openGuestInfoModal(endpoint: IEndpoint) {
    this.infoDialogEp = endpoint;
    Dispatcher.dispatch(ActionType.OpenModalDialog, "guestInfoModal");
  }

  /**
   * open operator info modal
   */
  openOperatorInfoModal(endpoint: IEndpoint) {
    this.opInfoDialogEp = endpoint;
    Dispatcher.dispatch(ActionType.OpenModalDialog, "operatorInfoModal");
  }

  /**
   * call telephone number from dial out popup
   */
  dialTelephoneNumber(dialOutData: IDialOutData, rtcIdCallback = null) {
    this.endpointService.myEndpoint.status = PresenceStatus.connecting;
    const participants = this.activeConference?.active.map(p => p.rtcId);

    const conference = this.activeConference;
   
    let targetEndpoint : Endpoint;
    let dialedSkillTags: ISkillTags;
    if (rtcIdCallback) {
      targetEndpoint = this.endpointService.endpoints.get(rtcIdCallback);
      if (targetEndpoint) {
        let ep = this.endpointService.getEndpointById(targetEndpoint.rtcId);
        dialedSkillTags = ep?.skillTags;
      }
    } else if (dialOutData.skillTags) {
      dialedSkillTags = dialOutData.skillTags;
    }

    const {phone, displayPhoneNumber, name} = dialOutData;

    // use our current conference if we have one, if not... grab a free one.
    let conferenceId = this.activeConference?.id ? this.activeConference.id :
      Companion.getConferenceService().findEmptyOwnedConference()?.id;

    if(!conferenceId)
    {
      this.conferenceService.alertHandler(AlertCode.callFailed, "Call dial failed", AlertLevel.error);
      console.warn("No empty conference for dial call.");
    }

    this.voiceService.dial(phone, displayPhoneNumber, name, conferenceId, rtcIdCallback, dialedSkillTags).catch((error : any) =>
    {
      // return us to the available state.
      this.endpointService.markClientReady();
      // if call failed, remove this endpoint if it was a callback endpoint
      if (rtcIdCallback) {
        this.conferenceService.alertHandler(AlertCode.callFailed,
          `dial out service failed ${this.endpointService.myEndpoint.rtcId}: ${error}`, AlertLevel.error);
        this.rejectToAnswerRingingEndpoint(targetEndpoint, true);
      }
      // clear the dial out component on error to try again.
      this.dialOutComponent.clearSubmitLock();
    });
  }

  /**
   * hang up dial out
   */
  hangupDialOutCall(phoneNumber: string): void {
    this.voiceService.hangupRingingCall(phoneNumber)
    .catch((error) => {
      console.warn("[hangupDialOutCall] Unable to hangup ringing call: ", JSON.stringify(error));  
    });
  }

  leaveRoomClicked() {
    this.leaveRoom(true);
  }
  
  leaveRoom(clickedLeave:boolean = false) {

    if (this.endpointService.myEndpoint.status == PresenceStatus.callback) {
      // This is a noop
      return;
    }

    // Don't boot kiosks or guests that don't have auto-leave.
    let stayOnline = false;
    if (this.isKiosk() && (!this.isKioskUnlocked || this.isLocked)) {
      stayOnline = true;
    } else if (this.viewMode === "guest" && 
              !!!this.localizationService.getValueByPath(".connect_screen.autoLeave")) {
      stayOnline = true;
    }

    if (clickedLeave) {
      stayOnline = false;
    }

    this.leave(stayOnline);
  }

  isKioskUnlockedCheck(): boolean {
    return !this.isKiosk() || this.isKioskUnlocked;
  }

  clearGuestURL() {
    // Grab the current URL tree.
    const urlTree = this.router.parseUrl(this.router.url);
    const fixedQueryParams = urlTree.queryParams;
    // Fix it to remove params.
    urlTree.queryParams = {};
    urlTree.fragment = null; // optional
    // Get me all the params split out
    var sitepathitems = urlTree.toString().split("/");

    // Update the language code. (mandatory param for guests)
    sitepathitems[sitepathitems.length - 2] = "en"; // change second from last

    // Update endpoint with new configs.
    this.endpointService.myEndpoint.language = "English";
    this.endpointService.myEndpoint.skillTags = null;

    // Create proper query params for this
    if (fixedQueryParams?.queueCat) { delete fixedQueryParams.queueCat; }
    if (fixedQueryParams?.queueLang) { delete fixedQueryParams.queueLang; }

    // Change the URL by merging the new params.
    this.router.navigate(sitepathitems, {queryParams: fixedQueryParams});
  }

  /**
   * Clear chimes, leave subconferences, disconnect active calls
   * @param stayOnline, flag indicating the client should remain connected after leaving
   * @param leftByTimeout, indicates the client has left because its session has timed out from the server
   */
  leave(stayOnline: boolean, leftByTimeout : boolean = false) {
    this.cleanRingingChime();
    this.endpointService.connectingOperator = null;
    this.conferenceService.preventReload = !!stayOnline;
    if (this.guestToAnswerEp) {
      this.rejectToAnswerRingingEndpoint(this.guestToAnswerEp);
    }

    if (this.conferenceService.isRecording) {
      this.stopRecord();
    }

    this.conferenceService.sharedFiles.clear();

    if (stayOnline) {
      // remove myself from the conference... (if that conference exists)
      if(this.conferenceService?.activeConference)
      {
        this.endpointService.removeFromConference(this.endpointService.myEndpoint.rtcId, 
          this.conferenceService.activeConference);
      }

      // Clear my chats as we are sort of resetting.
      Companion.getChatRoomService().clearAll();

      let preCheckPromise: any = null;
      if (this.queueScreenEnabled) {
        this.clearGuestURL();
        this.skillTags = null;
        preCheckPromise = this.localizationService.getLocalizationData(this.style, "en");
      } else {
        // Grab the current URL tree.
        const urlTree = this.router.parseUrl(this.router.url);
        const queryParams = urlTree.queryParams;
        this.skillTags = {category: queryParams?.queueCat, language : queryParams?.queueLang};
        this.endpointService.myEndpoint.skillTags = this.skillTags;
      }
      (preCheckPromise ?? Promise.resolve()).then(() => {
        // leave the old rooms.
        let _tasks: Promise<any>[] = [];
        _.forEach(this.callCenterService.accessibleQueues, (queue: string) => {
          _tasks.push(this.endpointService.removeFromRoomPromise(ConferenceUtil.newConferenceData(RoomType.GuestWaitRoom, {name: queue})));
        });

        return Promise.all(_tasks).then(() => {
          // rejoin the queues
          this.joinQueues();
          // this.endpointService.sendMyStatusToOthers();
        });
      }).catch((error) => {
        this.conferenceService.alertHandler(AlertCode.joinQueueFail,
          `Failed to clear and reset queue screen endpoint ${this.endpointService.myEndpoint.rtcId}: ${error}`);
      });
    } else {
      console.log("Going offline!");
      this.endpointService.markClientGone();
      this.callCenterService.resetForRefresh();
      let wasJoined = this.joined;
      this.joined = false;
      this.rtcService.disconnect();

      // reject our authenticity here if we are leaving.
      if(Companion.getUserService().currentUser)
      {
        Companion.getUserService().currentUser.isAuthenticated = false;
      }

      Dispatcher.dispatch(ActionType.LogOut);
      Dispatcher.dispatch(ActionType.LoadConferenceBeforeJoinNavBar);
      this.voiceService.destroy();

      let timeoutMessage = this.localizationService?.myLocalizationData?.errorMessages?.["TIMEOUT_ACKNOWLEDGED"];
      // if we are warning the user this occurred... acknowledge the leave... 
      if(leftByTimeout && timeoutMessage)
      {
        alert(timeoutMessage);
      }
      
      // Run the exitURL but only if we were a guest, ops and others don't need to redirect.
      if (this.exitUrl && this.viewMode === "guest" && this.route.snapshot.params?.["location"]) {
        window.location.href = this.exitUrl;
      } else if (window.opener) {
        window.close();
      } else if ( this.viewMode === "guest" && this.route.snapshot.params?.["location"]) {
        this.clearGuestURL();
      }
      else if (wasJoined)
      {
        // Clear us out!
        this.resetComponent();
        window.location.href = window.location.pathname
      }
    }
  }

  /**
   * update speaker device
   */
  updateSpeaker(speakerDeviceId: string) {
    this.videoComponent.changeSinkId();
  }

  /**
   * toggle full screen mode
   */
  toggleFullScreen(): void {
    if (
      !(document as any).fullscreenElement &&
      !(document as any).webkitFullscreenElement &&
      !(document as any).mozFullScreenElement
    ) {
      Dispatcher.dispatch(ActionType.EnterFullScreen);
    } else {
      Dispatcher.dispatch(ActionType.ExitFullScreen);
    }
  }

  /**
   * toggle video aspect
   */
  toggleVideoAspect() {
    this.userService.currentUser.preferedVideoAspect
      = this.userService.currentUser.preferedVideoAspect === VideoAspect.fill ? VideoAspect.fit : VideoAspect.fill;
  }

  /**
   * lock the kiosk view
   */
  lock() {
    this.isMobileApp = false;
    this.isNavShown = true;
    this.isLocked = true;
    this.incomingQueueShown = false;
    this.operatorsPanelShown = false;
    this.settingsPanelShown = false;
    this.chatBoxShown = false;
  }

  /**
   * unlock the kiosk view
   */
  unlock() {
    this.isLocked = false;
    this.isKioskUnlocked = true;
    Dispatcher.dispatch(ActionType.Unlock); // change toolbar button
  }

  /**
   * flag if is locked kiosk mode
   */
  isKiosk(): boolean {
    return this.isLocked && this.viewMode === "guest";
  }

  /**
   * start recording
   */
  startRecord() {
    // check audio context ready for recording
    Companion.getRTCClient().prepareAudioContext();
    this.conferenceService.activeRecording = true; // make sure you update conference service flag to let everyone know
    
    this.videoComponent.startRecord(
      RecordMode[this.localizationService.myLocalizationData.record_panel?.audioMode ?? "both"  as keyof typeof RecordMode],
      RecordMode[this.localizationService.myLocalizationData.record_panel?.videoMode ?? "both" as keyof typeof RecordMode]
    );
    // Is there anyone to notify that this conference is being recorded?
    if (this.conferenceService.activeConference) {
      EndpointService.getSharedInstance().recordConferenceStarted(this.conferenceService.activeConference);
    }
  }
  
  /**
   * pause recording
   */
  pauseRecord() {
    this.videoComponent.pauseRecord();
  }

  /**
   * resume recording
   */
  resumeRecord() {
    this.videoComponent.resumeRecord();
  }

  /**
   * stop recording
   */
  stopRecord() {
    this.videoComponent?.stopRecord();
    this.conferenceService.activeRecording = false; // make sure you update conference service flag to let everyone know
    
    // If no active conference, we can't really tell the remote side that we stopped recording.
    // A remove from conf on server will remove from server data record IDs anyway.
    if (this.conferenceService.activeConference) {
      EndpointService.getSharedInstance().recordConferenceStopped(this.conferenceService.activeConference);
    }
  }

  /**
   * raise videos volume
   */
  raiseVolume() {
    this.videoVolume = this.videoVolume === 1 ? 1 : parseFloat((this.videoVolume + 0.05).toFixed(2));
    this.volumeChanged();
  }

  /**
   * turn down videos volume
   */
  turnDownVolume() {
    this.videoVolume = this.videoVolume === 0 ? 0 : parseFloat((this.videoVolume - 0.05).toFixed(2));
    this.volumeChanged();
  }

  /**
   * update user volume
   */
  resetVolume() {
    if (this.isKiosk()) {
      this.setDefaultVolume();
    }

    this.volumeChanged();
  }

  /**
   * set video volume with a default value taken from the theme (if exist)
   */
  setDefaultVolume() {
    if (this.localizationService.myLocalizationData.settings_panel &&
      this.localizationService.myLocalizationData.settings_panel.default_volume) {
      this.videoVolume = this.localizationService.myLocalizationData.settings_panel.default_volume / 100;
    } else {
      this.videoVolume = DEFAULT_VIDEO_VOLUME;
    }
  }

  /**
   * update user volume
   */
  updateVolume(value: number) {
    this.videoVolume = value;
    this.volumeChanged();
  }

  /**
   * update user volume
   */
  volumeChanged() {
    this.userService.currentUser.preferedVolume = this.videoVolume;
    GlobalService.setSessionUser(this.userService.currentUser);
    this.voiceService.adjustVolume(this.videoVolume);
  }

  /**
   * update endpoint video
   */
  toggleEndpointVideo(endpoint: IEndpoint): void {
    this.updateCurrentVideoComponent();
  }
  
  /**
   * Update the status of the client from the toolbar...
   */
  updateMyStatusFromToolbar(status: PresenceStatus) {

    switch (status)
    {
      case PresenceStatus.available:
        this.endpointService.markClientReady();
      break;
      case PresenceStatus.away:
        this.endpointService.markClientUnready(StatusReason.away);
      break;
      case PresenceStatus.custom1:
        this.endpointService.markClientUnready(StatusReason.custom1);
      break
      case PresenceStatus.custom2:
        this.endpointService.markClientUnready(StatusReason.custom2);
      break;
      case PresenceStatus.custom3:
        this.endpointService.markClientUnready(StatusReason.custom3);
      break;
      case PresenceStatus.custom4:
        this.endpointService.markClientUnready(StatusReason.custom4);
      break;
    }
    // send the unready update...
    this.endpointService.myEndpoint.status = status;
  }

  /**
   * update the associated guest endpoint
   */
  updateGuestEndpoint(endpoint: IEndpoint) {
    this.guestEndpoint = endpoint;
  }

  /**
   * handler for local video update
   */
  private localVideoUpdateHandler(): void {
    this.updateCurrentVideoComponent();
    if (this.loopbackComponent) {
      let updateLoopbackVideoTimer = setTimeout(() => {
        clearTimeout(updateLoopbackVideoTimer);
        this.loopbackComponent?.setLoopbackVideo(); // Might be gone by now.
      }, 500);
    }

    this.endpointService.myEndpoint.cameraPermission =
            this.rtcService.rtcClient.cameraAccessible ? CameraPermission.allowed : CameraPermission.disallowed;
    this.endpointService.myEndpoint.microphonePermission =
      this.rtcService.rtcClient.microphoneAccessible ? CameraPermission.allowed : CameraPermission.disallowed;
    this.endpointService.sendEndpointUpdate();

    if (this.endpointService.myEndpoint.status === PresenceStatus.invisible && this.rtcService.rtcClient.cameraAccessible) {
      this.endpointService.myEndpoint.status = PresenceStatus.connecting;
      // update the server that we are ready
      this.endpointService.markClientReady();
    }
    if (this.viewMode === "guest" && this.guestConnectComponent) {
      this.guestConnectComponent.ensureVideoCapture();
    }
  }

  /**
   * handler for creating an alert in this component when requsted from the underlying code.
   * See AlertHandlerCallback from alert.interface.ts
   * Conditionally, make a dialog with AlertService.createCustomDialog, see bootbox buttons object for more details.
   */

  private alertHandler(
    alertCode: AlertCode,
    alertText?: string,
    alertLevel: AlertLevel = AlertLevel.warning,
    options?: AlertHandlerCallbackOptions
  ): void {
    this.alertService.createAlert(this.localizationService.myLocalizationData?.errorMessages?.[alertCode] || alertCode, alertText, alertLevel, options?.seconds || 5);

    // Check if we want to create a dialog for this alert.
    let dialogOptions: BootboxDialogOptions = options?.dialogOptions;
    
    // Don't dialog for errors on non-operators.
    if (dialogOptions && this.endpointService.isOperator(this.endpointService.myEndpoint)) {
      AlertService.createCustomDialog(dialogOptions);
    }    
  }

  /**
   * handler for remote videos update
   */
  private remoteVideoUpdateHandler(): void {
    this.updateCurrentVideoComponent();
    this.checkGuestsForAutoRecord();

    let chatPosition = this.navBarService.isToolbarItemVisible("chat") ? NavBarMenuPosition.Left : NavBarMenuPosition.None;
    let remoteEndpoints = this.getActiveVideoEndpoints();
    
    // Not connected and GUEST
    if (remoteEndpoints.length == 0) {
      if (!this.userService.currentUser.isOperator && !this.userService.currentUser.isRep) {
        chatPosition = NavBarMenuPosition.None;
      }
    }

    Dispatcher.dispatch(ActionType.UpdateMenuItem, {
      key: NavBarMenuItemKey.Chat,
      position: chatPosition,
    });
    // Chat visible now
  }

  /**
   * handler for shared files updated
   */
  private sharedFilesUpdateHandler(files: ISharedFileRef[]): void {
    this.totalFileCount = files.length;
    Dispatcher.dispatch(ActionType.ResetNavBarNotification, {
      key: NavBarMenuItemKey.ShareFiles,
      value: this.totalFileCount,
      color: "info",
    });
  }

  private fileTransferComplete(file: ISharedFile): void {
    // Save the file on file-complete (if we wanted to do that...)
    if (this.localizationService.getValueByPath(".sharedFolder_panel.autosave") && !file["autoSaved"]) {
      file["autoSaved"] = true;
      Companion.getRTCService().downloadFile(file.fileBlob, file.filename);
    }
  }

  /**
   * hide all popovers
   */
  hideAllPopovers() {
    $(".popover").hide();
  }

  /**
   * on window resize
   */
  onResize(event) {
    this.changedWindowHeight = event.target.innerHeight;
  }

  /**
   * update flavor-language data
   */
  updateLocalizationData(languageCode?: string, save: boolean = true): Promise<ILocalization> {
    this.language = languageCode;
    if (!languageCode) {
      let cookieUser: IUser = GlobalService.getSessionUser();
      if (cookieUser && cookieUser.preferedLanguage) {
        this.language = cookieUser.preferedLanguage;
      }
    }
    if (languageCode && save) {
      this.userService.currentUser.preferedLanguage = languageCode;
      GlobalService.setSessionUser(this.userService.currentUser);
    }
    return this.localizationService.getLocalizationData(this.style, this.language);
  }

  /**
   * language selection changed event
   */
  languageChanged(languageCode: string) {
    if (languageCode != this.localizationService.myLocalizationData.language) {
      this.updateLocalizationData(languageCode)
      .then((data: ILocalization) => {
        this.conferenceService.alertHandler(
          AlertCode.getLocalizationDataSuccess, this.style + ", " + languageCode, AlertLevel.info
        );
      })
      .catch((err: Error) => {
        this.conferenceService.alertHandler(
          AlertCode.getLocalizationDataFail, this.style + ", " + languageCode, AlertLevel.warning
        );
      });
    }

    // Update URL path if necessary!
    
    // Grab the current URL tree.
    const urlTree = this.router.parseUrl(this.router.url);
    const fixedQueryParams = urlTree.queryParams;
    var sitepathitems = urlTree.toString().split("/");
    const isVccMode = (element) => element === "operators";
    let modeOpIdx = sitepathitems.findIndex(isVccMode);
    let doNav = false;
    if (-1 < modeOpIdx) {
      if (sitepathitems.length == modeOpIdx+3) {
        // we have lang and location.
        if (sitepathitems[sitepathitems.length - 2] != languageCode)
        {
          sitepathitems[sitepathitems.length - 2] = languageCode;
          doNav = true;
        }
      } else if (sitepathitems.length == modeOpIdx+2) {
        // we have lang but no location.
        if (sitepathitems[sitepathitems.length - 1] != languageCode)
        {
          sitepathitems[sitepathitems.length - 1] = languageCode;
          doNav = true;
        }
      }
    }
    if (doNav) {
      this.router.navigate(sitepathitems, {queryParamsHandling: "merge", queryParams: fixedQueryParams});
    }
  }

  themeFilterChanged($event) {
    const selectedTheme = $event;
    if (selectedTheme) {
      const commands = this.router.url.split("/");
      if (commands.length > 1) {
        const curTheme = this.style;
        if (curTheme != commands[1] && curTheme == "default") {
          commands.splice(1, 0, "default");
        }
        commands[1] = selectedTheme;
        this.router.navigate(commands).then();
      }
    }
    this.style = selectedTheme;
    this.changeRooms()
    .catch((error) => {
      this.conferenceService.alertHandler(AlertCode.joinQueueFail, error ? error.message : null, AlertLevel.warning);
    });
  }

  changeRooms(): Promise<void>{
    const previousQueues: string[] = _.clone(this.callCenterService.visibleQueues);

    return this.callCenterService.getAccessibleQueues(this.style, this.endpointService.myEndpoint.skillTags)
    .then(() => {
      const nextQueues: string[] = this.callCenterService.visibleQueues;
      const intersection = _.intersection(previousQueues, nextQueues);
      const toLeaveQueues = previousQueues.filter(x => !intersection.includes(x));
      const toJoinQueues = nextQueues.filter(x => !intersection.includes(x));
      console.log("Room Change!", JSON.stringify({style: this.style, toLeave: toLeaveQueues, toJoin: toJoinQueues}));

      let leaveRoomPromises = [];
      toLeaveQueues.forEach(queue => {
        console.log("Attempting to leave room: ", queue);
        leaveRoomPromises.push(
          this.endpointService.removeFromRoomPromise(ConferenceUtil.newConferenceData(RoomType.GuestWaitRoom, {name: queue}))
          .catch((error: any) => {
            console.log(`Failed to removeFromRoom ${JSON.stringify(queue)}: ${JSON.stringify(error)}`);
            return Promise.resolve(); // Don't destroy us if we failed to leave a room.
          })
        );
      });
      let joinRoomPromises = [];
      toJoinQueues.forEach(queue => {
        console.log("Attempting to join room: ", queue);
        joinRoomPromises.push(new Promise((resolve, reject) => {
          Companion.getEndpointService().addToRoom(ConferenceUtil.newConferenceData(RoomType.GuestWaitRoom, {name: queue}), 
          (roomName: string) => {
            if (joinRoomPromises.length === 1) {
              Companion.getEndpointService().myEndpoint.skillTags == ConferenceUtil.getSkillTagsFromQueueRoomName(roomName);
            }
            resolve(roomName);
          }, (errorCode: string, errorText: string, roomName: string) => {
            console.log("Failed to join room!", roomName, errorCode, errorText);
            reject(errorText);
          });
        }));
      });

      return Promise.all(leaveRoomPromises).then(() => {
        console.log("Leave room chain finished.");
        return Promise.all(joinRoomPromises).then(() => {
          Dispatcher.dispatch(ActionType.LoadVCCNewData, null, "updateAccessibleQueues");
          Dispatcher.dispatch(ActionType.LoadVCCNewData, null, "loadParticipants");
          Dispatcher.dispatch(ActionType.UpdateSkillSetDisplay, null, "updateSkillSet");
          console.log("Current SkillSet: ", Companion.getEndpointService().myEndpoint.skillSet);
          this.endpointService.sendEndpointUpdate();
          return Promise.resolve();
        }).catch((error) => {
          console.log("Failed to join all rooms! ", JSON.stringify(error));
          return Promise.reject();
        });
      }); // rejection handled by each promise and changed to a resolve.

    }).catch((error) => {
      console.log("Failed to changeRooms: ", error);
    });
  }

  /**
   * check any operator online
   */
  checkAnyOperatorOnline(): Promise<boolean> {
    return new Promise((resolve: (data: boolean) => void, reject: (error: Error) => void) => {
      this.restService
      .post("/checkAnyEndpointOnlineByTheme", {theme: this.style, skillTags: this.endpointService.myEndpoint.skillTags})
      .subscribe(
        (data: any) => {
          resolve(data.result === true);
        },
        (error: Error) => {
          this.conferenceService.alertHandler(
            AlertCode.checkAnyEndpointOnlineByThemeFailed,
            error ? error.message : null,
            AlertLevel.warning
          );
          reject(error);
        }
      );
    });
  }

  /**
   * invite to monitor
   */
  startMonitor(ep: IEndpoint) {
    this.endpointService.setTransmitModeToEndpointByRtcId(ep.rtcId, VideoMediaConnectionMode.None);
    this.mutedStatusBeforeMonitoring = this.rtcService.rtcClient.audioMuted;
    this.toggleMic(true);
    this.rtcService.rtcClient.monitoring = true;
    // For now, send null to auto-create a new monitor session.
    this.endpointService.toggleMonitoring(null, ep.rtcId,
      (session: IMonitorConference) => {
        this.conferenceService.alertHandler(AlertCode.toggleMonitoringSuccess, `Successfully started monitoring: ${ep.rtcId}. Session: ${JSON.stringify(session)}`);
      },
      (error: string) => {
        this.conferenceService.alertHandler(AlertCode.toggleMonitoringFail, `Failed to start monitoring ${ep.rtcId}: ${error}`);
      }
    );
  }

  /**
   * exit from monitoring
   */
  exitMonitor(ep: IEndpoint) {
    this.toggleMic(this.mutedStatusBeforeMonitoring);
    this.rtcService.rtcClient.monitoring = false;
    // Get the monitor conference id.
    let monitorConference = this.conferenceService.getMonitorFromEndpoint(ep);
    if (!monitorConference) {
      // Can't undo a thing not done!
      return;
    }
    this.endpointService.toggleMonitoring(monitorConference.id, ep.rtcId,
      (session: IMonitorConference) => {
        this.conferenceService.alertHandler(AlertCode.toggleMonitoringSuccess, `Successfully stopped monitoring: ${ep.rtcId}. Session: ${JSON.stringify(session)}`);
        this.endpointService.setTransmitModeToEndpointByRtcId(ep.rtcId, VideoMediaConnectionMode.None);
      },
      (error: string) => {
        this.conferenceService.alertHandler(AlertCode.toggleMonitoringFail, `Failed to stop monitoring ${ep.rtcId}: ${error}`);
      });
  }

  pushToTalk(event: IPushToTalkEvent) {
    this.toggleMic(!event.talk);
    // TODO: Open/close audio track to endpoint, can we even do that?
  }

  /**
   * toggle inspect modal
   */
  toggleInspector() {
    this.showInspect = jQuery("#inspectModal").hasClass("in");
    this.showInspect = !this.showInspect;
    if (this.showInspect) {
      if (this.isMobileApp) {
        this.toggleInspectorPanel(false);
      } else {
        Dispatcher.dispatch(ActionType.OpenModalDialog, "inspectModal");
      }

    }
  }

  ngAfterViewInit() {
    Dispatcher.dispatch(ActionType.LoadConferenceBeforeJoinNavBar);
  }

  /**
   * toggle map view
   */
  toggleMap(shown: boolean) {
    this.isMapShown = shown;
    if (this.isMapShown) {
      this.isNotepadShown = false;
    }
  }

  /**
   * toggle notepad
   */
  toggleNotepad(endpoint: IEndpoint) {
    this.noteOnEndpoint = endpoint;
    this.isNotepadShown = !!endpoint || !this.isNotepadShown;
    if (this.isNotepadShown) {
      Dispatcher.dispatch(ActionType.ToggleMapScreen, {enabled: false});
      this.isMapShown = false;
    }
  }

  requestToSendLogs(endpoint: IEndpoint) {
    if (this.endpointService.myEndpoint.rtcId != endpoint.rtcId) 
    {
       this.endpointService.sendRequestToSendLogs(endpoint.rtcId);
    }
    else
    {
       this.logService.sendLogs(true).catch((error) => console.error("Failed to sendLogs: ", error));
    }
  }

  requestToTakePhoto(rtcId: string) {
    this.cropperEnabled = GlobalService.getSessionUser().preferredCropperEnabled;
    this.takingPhoto = true;
    this.takePhotoTimer = setTimeout(() => {
      clearTimeout(this.takePhotoTimer);
      this.takingPhoto = false;
    }, 20 * 1000);
    Companion.getRTCService().sendServerMessage("photo_request", {targetRtcId: rtcId});
  }

  responseToTakePhoto(rtcId: string, success: boolean, image?: Blob, error?: string) : Promise<void> {
    return new Promise<void>((resolve, reject) => {
      Companion.getRTCService().sendServerMessage("photo_response", {
        targetRtcId: rtcId,
        image: image,
        success: success,
        error: error
      }, () => {
        resolve()
      },
      (error) => {
        reject(error);
      });
    })
    .finally(() => {
      this.deviceService.selectPrimaryCamera(this.rtcService.rtcClient.selectedPrimaryCameraOption);
    });
  }

  takePhoto(srcRtcId: string) {
    _.keys(this.endpointService.myEndpoint.streams).forEach(key => {
      const stream = this.endpointService.myEndpoint.streams[key];
      const tracks = stream.getVideoTracks();
      if (tracks.length === 0) {
        this.responseToTakePhoto(srcRtcId, false, null, "No video track available")
        .catch((error) => {
          console.log("Failed to take photo: ", error);
        });
      }
      const track: MediaStreamTrack = tracks[0];
      const photoResolution = this.localizationService.myLocalizationData.take_photo.resolution;
      const photoFramerate = this.localizationService.myLocalizationData.take_photo.frameRate;
      const newConstraints: MediaTrackConstraints = {
        width: {ideal: photoResolution.width},
        height: {ideal: photoResolution.height},
        frameRate: {ideal: photoFramerate}
      };

      MediaUtil.applyVideoConstraintsOnTrack(newConstraints, track)
      .then(() => {
        const imageCapture = new ImageCapture(track);
        imageCapture.takePhoto() // this is fired in the background but we don't care.
        .then(blob => {
          this.responseToTakePhoto(srcRtcId, true, blob, null)
          .catch((error) => {
            console.log("Failed to take photo: ", error);
          });
        })
        .catch(error => {
          let message = "Failed to capture image";
          if (error && error.message) {
            message += `: ${error.message}`;
          }
          this.responseToTakePhoto(srcRtcId, false, null, message)
          .catch((error) => {
            console.log("Failed to take photo: ", error);
          });
        });
      })
      .catch(error => {
        let message = "Failed to apply constraints";
        if (error && error.message) {
          message += `: ${error.message}`;
        }
        this.responseToTakePhoto(srcRtcId, false, null, message)
        .catch((error) => {
          console.log("Failed to take photo: ", error);
        });
      });
    });
  }

  /**
   * take snapshot
   */
  snapshot() {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById("snapshot-canvas");
    if (!canvas) {
      this.conferenceService.alertHandler(
        AlertCode.snapshotFailed,
        "Failed to take a snapshot.",
        AlertLevel.warning
      );
      return;
    }
    this.videoComponent.drawSnapshot(
      RecordMode[this.localizationService.getValueByPath(".snapshot_panel.videoMode") as string]
    );
    this.conferenceService.alertHandler(AlertCode.snapshotProgressing, "Taking a snapshot...", AlertLevel.info);
    canvas.toBlob((result: Blob) => {
      if (this.notepadComponent) {
        this.notepadComponent.insertImageWithBlobData(result);
      }
      saveAs(result, this.callCenterService.getSnapshotFileBasename() + ".jpg");
      Companion.getConferenceService().alertHandler(
        AlertCode.snapshotSuccess,
        "Successfully took a snapshot. The image file will be downloaded automatically.",
        AlertLevel.success
      );
    }, "image/jpeg");
  }

  init(): Promise<void> {

    if(this.initialized)
    {
      // already initialized, don't need to do anything.
      return Promise.resolve();
    }

    if (GlobalService.getSessionUser().preferredCropperEnabled === undefined) {
      this.userService.currentUser.preferredCropperEnabled = true;
      GlobalService.setSessionUser(this.userService.currentUser);
    }
    this.conferenceService.sharedFiles.clear();
    this.cropperEnabled = this.userService.currentUser.preferredCropperEnabled;
    let data: ILocalization = this.localizationService.myLocalizationData;
    return this.restService.getNewRtcId()
    .then((rtcId) => {
      this.endpointService.myEndpoint.rtcId = rtcId;
      this.conferenceService.init(
        rtcId,
        this.alertHandler.bind(this),
        this.localVideoUpdateHandler.bind(this),
        this.remoteVideoUpdateHandler.bind(this),
        this.sharedFilesUpdateHandler.bind(this),
        this.messageReceivedHandler.bind(this),
        this.localizationService.getValueByPath(".settings_panel.audio_setting.codecs"),
        this.localizationService.getValueByPath(".settings_panel.video_setting.codecs"),
        this.localizationService.getValueByPath(".settings_panel.secondary_video_setting.codecs"),
        AnalyticsService.getSharedInstance().peerConnectionCreatedHandler.bind(AnalyticsService.getSharedInstance())
      );
      if (data.rtcConfig) {
        this.rtcService.setRTCConfig(data.rtcConfig);
      }
      if (data.iceConfigs) {
        this.rtcService.useCustomIceConfig(data.iceConfigs);
      }
      this.conferenceService.setFileCompleteNotify(this.fileTransferComplete.bind(this)); // Notify us on file transfer complete
      AnalyticsService.getSharedInstance().initializeCallAnalytics(rtcId, data);
      this.setServerMessageHandlers();
      this.endpointService.myEndpoint.cameraPermission = CameraPermission.pending;
      this.endpointService.myEndpoint.microphonePermission = CameraPermission.pending;
      return this.rtcService
      .gainMediaAccess()
      .then(() => {
        this.mediaAccessFailed = false;
        if (this.rtcService.rtcClient.videoFilter && this.userService.currentUser.preferedVirtualBackgroundEnabled) {
          this.rtcService.toggleCameraVirtualBackground(true);
        }
      })
      .catch((err: Error) => {
        this.mediaAccessFailed = true;
        this.conferenceService.alertHandler(AlertCode.failToGainMediaAccess, err.message, AlertLevel.error);
      })
      .then(() => {
        this.mediaAccessFinished = true;
        this.endpointService.myEndpoint.cameraPermission =
          this.rtcService.rtcClient.cameraAccessible ? CameraPermission.allowed : CameraPermission.disallowed;
        this.endpointService.myEndpoint.microphonePermission =
          this.rtcService.rtcClient.microphoneAccessible ? CameraPermission.allowed : CameraPermission.disallowed;
        this.endpointService.sendEndpointUpdate();
        // only guest will use the location parameter.
        // so check if this is a guest by looking at the location parameter.
          if (this.location) {
            this.userService.currentUser.username = decodeURIComponent(this.location);
            this.guestLogin(this.userService.currentUser);
          }
      });
    }).catch((error: any) => {
      Promise.reject(error);
    }).then(() => {
      this.initialized = true;
      // once we have gone through the full init process, we are no longer a first time user
      this.isFirstTimeUser = false;
    });
  }

  setServerMessageHandlers() {
    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "chatMessage",
      (msgData: any) => {
        let content: IMessage = msgData.content;
        content.sendDate = new Date(content.sendDate); // Ensure it is correct date format.
        let targets = _.compact(_.map(content.toEndpointIds, (id) => {
          return this.endpointService.getEndpointById(id);
        }));
        if (targets.length != content.toEndpointIds.length) {
          console.log("Failed to process chat message. Could not find all endpoints. Abort!");
          return;
        }
        let existingRoom = this.chatRoomService.findChatRoomById(msgData.roomId);
        if (!existingRoom) {
          // Try to locate an empty room by targets.
          existingRoom = this.chatRoomService.findExistingRoomByTargets(targets);
          if (existingRoom) {
              // This is the same room now!
              existingRoom.roomId = msgData.roomId;
              existingRoom.ownerRtcId = content.roomOwnerRtcId;
          }

          // did I still not find one we want to use?
          if (!existingRoom) {
            existingRoom = this.chatRoomService.createChatRoomByMessageTargets(targets, content.roomOwnerRtcId, msgData.roomId);
          }
        } else {
          // Ensure proper members are updated.
          existingRoom.targets = targets;
        }
        // Add msg to room.
        this.chatRoomService.addMessageToChatRoom(existingRoom, content, true);
        this.messageReceivedHandler();
      }
    )
    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "update_voice_audio_device",
      (msgData: any) => {
        this.voiceService.updateInputDevice();
      });

    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "invite_to_connect",
      (msgData: any) => {
        this.conferenceService.alertHandler(AlertCode.callInvitationReceived, null, AlertLevel.info);

      // remove getter only property
      delete msgData?.inviterEp?.state;
      delete msgData?.inviterEp?.uiName;
      delete msgData?.inviterEp?.isInCustomState;

      // get the conf ID
      let inviter : IEndpointRef = msgData.inviterEp;
      let confId : string = msgData.conferenceId;

      const ep = Object.assign(new Endpoint(msgData.inviterEp), msgData.inviterEp);

      // guest view does not take 2nd call invite.
      if (Endpoint.getPresenceStateByStatus(this.endpointService.myEndpoint.status) === 
          PresenceState.dnd && this.viewMode === "guest" && !msgData.isTakeover) {
        this.conferenceService.alertHandler(AlertCode.callTargetsFail,
          `Can't proceed the call invitation. My endpoint is in dnd. And My view mode is guest.`,
          AlertLevel.warning);
        return;
      }

      if (!this.localizationService.myLocalizationData.ring_tone_panel || msgData.expectingACall ||
          (this.viewMode === "guest" && this.localizationService.myLocalizationData.connect_screen.autoAnswer)) 
      {
        
        this.endpointService.acceptInvitation(inviter.rtcId, confId, true);
      }
      else
      {
        this.endpointService.myEndpoint.status = PresenceStatus.connecting;
        this.promptRingtone(ep,
          () => {
            // ACK accept
            this.stopAllMonitors(); // If we are accepting, stop monitoring.
            this.endpointService.acceptInvitation(inviter.rtcId, confId, true);
          },
          () => {
            // ACK reject
            this.endpointService.acceptInvitation(inviter.rtcId, confId, false);
          }
        );
      }
    });

    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "invitation_rejected",
      (msgData: any) => {
        console.log("invitation_rejected", msgData);

        if(this.endpointService.myEndpoint.status === PresenceStatus.connecting)
        {
          this.endpointService.myEndpoint.status = PresenceStatus.away;
          this.dialOutComponent.close();
        }

        if (!!msgData?.inviteeEp) {
          if (this.endpointService?.connectingOperator &&
              this.endpointService.connectingOperator.isCaller &&
              this.endpointService.connectingOperator.endpoint.rtcId == msgData.inviteeEp.rtcId) {
            this.endpointService.connectingOperator = null;
          }
        }
      }
    )

    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "invitation_revoked",
      (msgData: any) => {
        this.conferenceService.alertHandler(AlertCode.callInvitationRevoked, null, AlertLevel.info);
        console.log("invitation_revoked", msgData);
      // get the conf ID
      let inviter : IEndpointRef = msgData.inviterEp;
      let confId : string = msgData.conferenceId;
      let status : PresenceStatus = msgData.status;
      // TODO: maybe validate the msg data?
      this.cleanRingingChime();
      this.endpointService.myEndpoint.status = status;
    });

    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "answer_revoked",
      (msgData: any) => {
        this.conferenceService.alertHandler(AlertCode.answerCallFailed, null, AlertLevel.info);
        console.log("answer_revoked", msgData);
        if (!!msgData?.guestEp) {
          if (this.endpointService?.connectingOperator &&
            this.endpointService.connectingOperator.isCaller &&
            this.endpointService.connectingOperator.endpoint.rtcId == msgData.guestEp.rtcId) {
            this.endpointService.connectingOperator = null;
          }
        }
        this.cleanRingingChime();
        let status : PresenceStatus = msgData.status;
        // go to unavailable.
        this.endpointService.myEndpoint.status = status;
    });

    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "ask_to_answer",
      (msgData: any) => {
        // remove getter only property
        delete msgData.ep.state;
        delete msgData.ep.uiName;
        delete msgData.ep.isInCustomState;

        const ep = Object.assign(new Endpoint(msgData.ep), msgData.ep);
        this.checkGuestToPromptRingTone(ep);
      });

      this.conferenceService.serverMessageHandler.setServerMessageHandler(
        "ask_to_answer_transfer",
        (msgData: any) => {
          // locate the guest we are receiving
          const ep = Object.assign(new Endpoint(msgData.ep), msgData.ep);
          // Find a conference of ours to accept a transfer into
          this.promptRingtone(ep,
            () => {
              // ACK accept
              this.endpointService.sendConfirmTransfer(true, ep, this.conferenceService.findEmptyOwnedConference())
            },
            () => {
              // ACK reject
              this.endpointService.sendConfirmTransfer(false, ep, null);
              this.endpointService.myEndpoint.status = PresenceStatus.away;
            }
          );
        });

    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "queue_status",
      (msgData: any) => {
        this.queueStatus = msgData.queueStatus;
      });

    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "refresh_participants_list",
      (msgData: any) => {
        this.callCenterService.refreshVCCData();
      });

    this.conferenceService.setServerMessageCodeHandler("ENDPOINT_NOT_EXIST", (msgData: any) => {
      // do nothing
    });
    this.conferenceService.setServerMessageCodeHandler("OTHER_ERROR", (msgData: any) => {
      // do nothing
    });

    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "delete",
      (msgData: any) => {
        this.leave(false);
      });

    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "request_file_transfer",
      (msgData: any) => {
        let fileId = msgData?.fileId;
        let requestorId = msgData?.requestorId;
        this.conferenceService.handleFileTransferRequest(fileId, requestorId);
      }
    )

    // register authenticated command handler
    this.conferenceService.serverMessageHandler.setServerMessageHandler(
      "socket_authenticated",
      (msgData: any) => {
        const myEndpoint = this.endpointService.myEndpoint;

        const oldServerNodeId = myEndpoint.serverNodeId;
        const newServerNodeId = msgData.serverNodeId;

        const oldVersion = localStorage.getItem("version");
        const newVersion = msgData.version;
        localStorage.setItem("version", newVersion);

        this.conferenceService.alertHandler(
          AlertCode.socketAuthenticated,
          `rtcId: ${myEndpoint.rtcId}, nodeId: ${myEndpoint.serverNodeId}, newNodeId: ${newServerNodeId}, joined: ${this.joined}`,
          AlertLevel.log
        );

        console.log("esthablishing connection", { newlyCreated: msgData.newlyCreated, esthablishedSession: this.hasEstablishedSession});

        // WE want to leave in the event that
        // 1. The client expected to use a existing session but the server had to create a new one (session Timed out.)
        // 2. The server has migrated to a new node.
        if (this.hasEstablishedSession && msgData.newlyCreated ||
          (newServerNodeId && oldServerNodeId && newServerNodeId !== oldServerNodeId)) {

          // console log
          console.log("Re-esthablished connection with server, but previous session was invalid");

          // if enabled send logs to logs collector server
          if (this.store.getState().settings.CollectLogs.serverConnection) {
            this.logService.sendLogs().catch((error) => console.error("Failed to sendLogs: ", error));
          }

          // refresh the page if there is a new software version
          if (oldVersion && newVersion && newVersion !== oldVersion) {
            this.conferenceService.preventReload = false;
            window.location.reload();
            return;
          }

          myEndpoint.serverNodeId = null;
          let stayOnline = false;
          if (this.isKiosk() && (!this.isKioskUnlocked || this.isLocked)) {
            stayOnline = true;
          } else if (this.viewMode === "guest" && this.route.snapshot.params?.["location"] && 
                    !!!this.localizationService.getValueByPath(".connect_screen.autoLeave")) {
            stayOnline = true;
          }
          if (this.joined) {
            // only need to leave if i was joined.
            this.leave(stayOnline, true);
          }
          return;
        }

        // mark as having an esthablished session
        this.hasEstablishedSession = true;
        // This is not a reconnection. This is fresh page load.
        if (!myEndpoint.serverNodeId) {
          myEndpoint.serverNodeId = newServerNodeId;
          // reset volume for fresh page load
          this.resetVolume();
          return;
        }
        // update the rtcId (to leave the correct endpoint) and the new serverNodeId
        myEndpoint.rtcId = myEndpoint.rtcId || msgData.rtcId;
        myEndpoint.serverNodeId = newServerNodeId;
        // if it's already joined, this is a rejoin
        if (this.joined) {
          this.callCenterService.checkIfEndpointInAllAccessibleQueues(myEndpoint)
          .catch((error) => {
            console.error("FAIL_TO_CHECK_IF_IN_QUEUE", error);
            return Promise.resolve(false);
          })
          .then((result: boolean) => {
            if (!result) {
              this.leave(true);
              this.joinQueues();
            }
          });
        }
      });

      this.conferenceService.serverMessageHandler.setServerMessageHandler(
        "photo_request",
        (msgData: any) => {
          if (msgData?.srcRtcId) {
            this.takePhoto(msgData.srcRtcId);
          }
        });
  
      this.conferenceService.serverMessageHandler.setServerMessageHandler(
        "photo_response",
        (msgData: any) => {
          clearTimeout(this.takePhotoTimer);
          this.takingPhoto = false;
          if (msgData?.success && msgData?.srcRtcId) {
            const ep = this.endpointService.getEndpointById(msgData.srcRtcId);
            const blobImage = new Blob([msgData.image],  {type: "image/png"});
            createImageBitmap(blobImage).then(img => this.imageToBeSavedDimensions = {width: img.width, height: img.height})
            .catch((error) => {
              // Filed to figure out dimensions... oh well...
            });
  
            let filenamePattern: string =
            this.localizationService.getValueByPath(".snapshot_panel.filenamePattern") ||
              "[DATE]_[TIME]_[REMOTE_NAME]_[LOCAL_NAME]";
            this.imageToBeSaved = new File(
              [blobImage],
              FileUtility.generateFileName(filenamePattern, this.localizationService, ".png"),
              {type: blobImage.type}
            );
            let canvasRotation = 0;
            if (ep && ep.cameraRotation) {
              switch (ep.cameraRotation) {
                case "90":
                  canvasRotation = 1;
                  break;
                case "-90":
                  canvasRotation = -1;
                  break;
                case "180":
                  canvasRotation = 2;
                  break;
                default:
                  canvasRotation = 0;
              }
            }
            this.cameraRotation = canvasRotation;
            this.openCropper();
          } else {
            const message = msgData.error || "A problem occurred while taking the photo";
            this.alertService.createAlert("TAKE_PHOTO_FAIL", message, AlertLevel.prominent, 10);
          }
        });
  
      this.conferenceService.serverMessageHandler.setServerMessageHandler(
        "request_to_send_logs",
        (msgData: any) => {
          if (msgData?.result && msgData?.srcRtcId) {
            if (msgData.result.success) {
              const ep: IEndpoint = this.endpointService.getEndpointById(msgData.srcRtcId);
              let message = `Logs of ${ep?.name || "guest"} sent`;
              this.alertHandler(AlertCode.sendLogsSuccess, message, AlertLevel.success);
            } else {
              let message = "Request to send logs failed";
              this.alertHandler(AlertCode.sendLogsError, message, AlertLevel.warning);
            }
            return;
          }
  
          this.logService.sendLogs(false)
          .then((res) => {
            this.endpointService.sendRequestToSendLogs(msgData.srcRtcId, {
              success: true
            });
          })
          .catch(err => {
            this.endpointService.sendRequestToSendLogs(msgData.srcRtcId, {
              success: false,
              err: err?.message
            });
          });
        }
      );
  }

  dialVoiceDtmf(key: string) {
    this.voiceService.sendDigit(key);
  }

  /**
   * Determine if we have the maximum number of endpoints participanting in a conference 
   * for this session, (Endpoints we are being monitored by do not count)
   * @returns flag indicating if we are (or exceed) configured maximum
   */
  maxParticipantsReached() : boolean
  {
    return this.callCenterService.maxParticipantsReached();
  }

   updateSkillSetDisplay() {
    //console.log("CallCenterComponent updateSkillSetDisplay...");
  }

  get activeConference() : IExpandedActiveConference
  {
    return this.conferenceService?.currentActiveConference;
  }

  get chattableConference(): Readonly<IExpandedQueueConference> {
    if (this.chatroomListTypes.includes("ep")) {
      return this.activeConference;
    } else {
      let chattableConf = new ExpandedQueueConference();
      chattableConf.roomType = RoomType.GuestWaitRoom;
      chattableConf.name = "Chattable Conference: All Visibles";
      chattableConf.publicWaitList = _.filter(this.activeConference?.everyone, (ep) => {
        return ep.rtcId != this.endpointService.myEndpoint.rtcId;
      }) || [];
      chattableConf.operators = _.filter(this.callCenterService.visibleOperators, (ep) => {
        return ep.rtcId != this.endpointService.myEndpoint.rtcId;
      }) || [];
      chattableConf.reps = _.filter(this.callCenterService.visibleReps, (ep) => {
        return ep.rtcId != this.endpointService.myEndpoint.rtcId;
      }) || [];
      return chattableConf;
    }
  }

  private fetchThirdPartyAPIIntegrationSettings() {
    this.restService
      .post("/getThirdPartyAPIIntegrationSettings", {
        theme: this.style
      })
      .subscribe(
        (data: IThirdPartyAPIIntegration) => {
          this.thirdPartyAPIIntegrationSettings = data;
        },
        (error: any) => {
          console.log("call-center component fetchThirdPartyAPIIntegrationSettings theme:", this.style, " error");
        }
      );
  }


  private getActiveVideoEndpoints() : IEndpointRef[] {
    let result: IEndpointRef[] = [];
    let myId = this.endpointService.myEndpoint.rtcId;

    // Check if I have an active conference.
    if (this.activeConference) {
      // Add all the non-me actives
      result = result.concat(
        _.map(_.filter(this.activeConference.active, (ep: IEndpoint) => {
          return ep.rtcId !== myId;
        }), (ep) => {
          return Endpoint.toRef(ep);
        })
      );
    }

    // Look inside each monitor conference I have.
    _.forEach([...this.conferenceService?.monitors.values()], (monitorConf) => {
      // Don't add observers if I'm a subject... This means I should not change anything on my screen.
      if (_.some(monitorConf.subjects, (subjRef) => {
        return subjRef.rtcId == myId;
      })) { // If I'm in the subjects list... add observers (for audio, gets hidden in the UI)
        result = result.concat(monitorConf.observers);
      } else if (_.some(monitorConf.observers, (obsRef) => {
        return obsRef.rtcId == myId;
      })) { // If I'm in the observers list...
        // Add all the subjects and the observers that are not me.
        result = result.concat(
          monitorConf.subjects,
          _.filter(monitorConf.observers, (obsRef) => {
            return obsRef.rtcId != myId;
          })
        );
      }
    });
    
    return _.compact(result);
  }
}
