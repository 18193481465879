/**
 * Copyright Compunetix Incorporated 2018-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { FlavorParameterKey, IFlavor } from "./flavor.interface";
import { ILocalization } from "./localization.interface";
/**
 * Flavor util
 */
export class FlavorUtil {
  /**
   * map from flavor to localization
   */
  public static flavorToLocalization: {
    [flavorKey: string]: string;
  };

  /**
   * map from localization to flavor
   */
  public static localizationToFlavor: {
    [localizationPath: string]: string;
  };

  /**
   * panel to toolbar toggle
   */
  public static panelToToolbarToggle: {
    [panelId: string]: string;
  };

  public static register() {
    this.flavorToLocalization = {};
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.mode_mode]] = ".mode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.mode_groupId]] = ".groupId";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_enabled]] = ".page";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_simplified_view_for_mobile_user]] = ".page.loadSimplifiedViewForMobileUsers";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_title]] = ".page.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_font_color]] = ".page.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_font_family]] = ".page.fontFamily";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_font_size]] = ".page.fontSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_button_prefer_sharp_edge]] =
      ".page.preferSharpEdgeButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_background_color]] = ".page.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_background_image]] = ".page.backgroundImage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_redirect_url_on_leave]] = ".page.redirectUrlOnLeave";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.page_full_screen_mode]] = ".page.fullScreenMode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_enabled]] = ".topnav";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_font_color]] = ".topnav.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_background_color]] =
      ".topnav.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_language_selector_options]] =
      ".topnav.language_selector.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_language_selector_default]] =
      ".topnav.language_selector.default";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.topnav_menu_items]] = ".topnav.menu";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_enabled]] = ".loginPanel";
    this.flavorToLocalization["login_panel_enabled"] = ".loginPanel";
    this.flavorToLocalization["login_menu_enabled"] = ".loginMenu";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_menu_login_text]] = ".loginMenu.loginText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_menu_logout_text]] = ".loginMenu.logoutText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_menu_after_login_menu]] =
      ".loginMenu.afterLoginMenu";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_title]] = ".loginPanel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_exit_button]] =
      ".loginPanel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_email_field_title]] =
      ".loginPanel.emailFieldTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_password_field_title]] =
      ".loginPanel.passwordFieldTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_username_field_title]] =
      ".loginPanel.usernameFieldTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_submit_button_text]] =
      ".loginPanel.submitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_help_message_1]] =
      ".loginPanel.helpMessage1";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_help_message_2]] =
      ".loginPanel.helpMessage2";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_font_color]] =
      ".loginPanel.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_background_color]] =
      ".loginPanel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_submit_button_color]] =
      ".loginPanel.submitButtonColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_require_login]] =
      ".loginPanel.requireLogin";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_forgot_username_button_text]] =
      ".loginPanel.forgotUsernameButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_forgot_password_button_text]] =
      ".loginPanel.forgotPasswordButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_send_button_text]] =
      ".loginPanel.sendButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_cancel_button_text]] =
      ".loginPanel.cancelButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_openidc_url]] =
      ".loginPanel.loginWithOpenIDCUrl";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_openidc_button]] =
      ".loginPanel.loginWithOpenIDCButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_desktop_only_text]] =
      ".loginPanel.desktopOnlyText";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.login_panel_login_as_available]] =
      ".loginPanel.loginAsAvailable";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_enabled]] = ".toolbar";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_auto_hide]] = ".toolbar.autoHide";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_font_color]] = ".toolbar.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_font_hover_color]] = ".toolbar.hoverColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_font_active_color]] =
      ".toolbar.activeColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_font_family]] = ".toolbar.fontFamily";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_background_color]] =
      ".toolbar.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_border_color]] = ".toolbar.borderColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_title_left]] = ".toolbar.leftTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_title_right]] = ".toolbar.rightTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_title_color]] = ".toolbar.titleColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_logo]] = ".toolbar.logo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_brand_logo_arial_label]] = ".toolbar.logoArialLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_settings_toggle]] =
      ".toolbar.toolbar_items.settings";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_enter_toggle]] =
      ".toolbar.toolbar_items.enter";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_enter_toggle_host_only]] =
      ".toolbar.toolbar_items.enter.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_enter_toggle_guest_only]] =
      ".toolbar.toolbar_items.enter.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_toggle]] =
      ".toolbar.toolbar_items.lock";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_toggle_host_only]] =
      ".toolbar.toolbar_items.lock.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_toggle_guest_only]] =
      ".toolbar.toolbar_items.lock.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_chat_toggle]] =
      ".toolbar.toolbar_items.chat";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_chat_toggle_host_only]] =
      ".toolbar.toolbar_items.chat.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_chat_toggle_guest_only]] =
      ".toolbar.toolbar_items.chat.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_participants_toggle]] =
      ".toolbar.toolbar_items.participants";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_participants_toggle_host_only]] =
      ".toolbar.toolbar_items.participants.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_participants_toggle_guest_only]] =
      ".toolbar.toolbar_items.participants.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_qa_panel]] =
      ".toolbar.toolbar_items.qaPanel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_incoming_queue_toggle]] =
      ".toolbar.toolbar_items.incomingQueue";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_incoming_queue_toggle_host_only]] =
      ".toolbar.toolbar_items.incomingQueue.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_incoming_queue_toggle_guest_only]] =
      ".toolbar.toolbar_items.incomingQueue.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_operators_toggle]] =
      ".toolbar.toolbar_items.operators";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_operators_toggle_host_only]] =
      ".toolbar.toolbar_items.operators.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_operators_toggle_guest_only]] =
      ".toolbar.toolbar_items.operators.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_screen_toggle]] =
      ".toolbar.toolbar_items.shareScreen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_screen_toggle_host_only]] =
      ".toolbar.toolbar_items.shareScreen.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_screen_toggle_guest_only]] =
      ".toolbar.toolbar_items.shareScreen.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_content_share_toggle]] =
      ".toolbar.toolbar_items.contentShare";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_content_share_toggle_host_only]] =
      ".toolbar.toolbar_items.contentShare.hostOnly";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_content_share_toggle_guest_only]] =
      ".toolbar.toolbar_items.contentShare.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_optimize_for_video_toggle]] =
      ".toolbar.toolbar_items.optimizeForVideo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_inspector_toggle]] =
      ".toolbar.toolbar_items.inspector";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_inspector_toggle_host_only]] =
      ".toolbar.toolbar_items.inspector.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_inspector_toggle_guest_only]] =
      ".toolbar.toolbar_items.inspector.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_record_toggle]] =
      ".toolbar.toolbar_items.record";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_record_toggle_host_only]] =
      ".toolbar.toolbar_items.record.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_record_toggle_guest_only]] =
      ".toolbar.toolbar_items.record.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_audio_toggle]] =
      ".toolbar.toolbar_items.audio";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_video_toggle]] =
      ".toolbar.toolbar_items.video";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_full_screen_toggle]] =
      ".toolbar.toolbar_items.fullscreen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_video_aspect_toggle]] =
      ".toolbar.toolbar_items.videoAspect";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_map_toggle]] =
      ".toolbar.toolbar_items.map";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_map_toggle_host_only]] =
      ".toolbar.toolbar_items.map.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_map_toggle_guest_only]] =
      ".toolbar.toolbar_items.map.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_dashboard_open]] =
      ".toolbar.toolbar_items.dashboard";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_virtual_background_toggle]] =
      ".toolbar.toolbar_items.virtualBackground";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_snapshot_toggle]] =
      ".toolbar.toolbar_items.snapshot";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_op_alert_toggle]] =
      ".toolbar.toolbar_items.opAlert";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_conference_toggle]] =
      ".toolbar.toolbar_items.lockConference";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_conference_toggle_host_only]] =
      ".toolbar.toolbar_items.lockConference.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_join_qa_toggle]] =
      ".toolbar.toolbar_items.joinQAQueue";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_start_qa_toggle]] =
      ".toolbar.toolbar_items.startQA";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_start_qa_toggle_host_only]] =
      ".toolbar.toolbar_items.startQA.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_snapshot_toggle_host_only]] =
      ".toolbar.toolbar_items.snapshot.hostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_snapshot_toggle_guest_only]] =
      ".toolbar.toolbar_items.snapshot.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_folder_toggle]] =
      ".toolbar.toolbar_items.sharedFolder";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_folder_toggle_host_only]] =
      ".toolbar.toolbar_items.sharedFolder.hostOnly";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.toolbar_items_share_folder_toggle_guest_only]] =
      ".toolbar.toolbar_items.sharedFolder.guestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_enabled]] = ".title_screen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_font_color]] =
      ".title_screen.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_background_color]] =
      ".title_screen.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_title]] = ".title_screen.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.title_screen_logo]] = ".title_screen.logo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.queue_screen_enabled]] = ".queue_screen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.queue_screen_list]] =
      ".queue_screen.list";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_enabled]] = ".connect_screen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_font_color]] =
      ".connect_screen.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_font_size]] =
      ".connect_screen.fontSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_top_position]] =
      ".connect_screen.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_background_color]] =
      ".connect_screen.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_title]] =
      ".connect_screen.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_logo]] =
      ".connect_screen.logo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_logo_size]] =
      ".connect_screen.logoSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_image]] =
      ".connect_screen.connectingImage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_subtitle]] =
      ".connect_screen.subtitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button]] =
      ".connect_screen.connectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_text_color]] =
      ".connect_screen.connectButtonTextColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_background_color]] =
      ".connect_screen.connectButtonBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_bottom]] =
      ".connect_screen.connectPanelBottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_background_color]] =
      ".connect_screen.connectPanelBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_info_visible]] =
      ".connect_screen.connectInfoVisible";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_waittime_label]] =
      ".connect_screen.connectPanelWaitTimeLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_panel_position_label]] =
      ".connect_screen.connectPanelPositionLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button_visible]] =
      ".connect_screen.callBackButtonVisible";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button_visible]] =
      ".connect_screen.dropCallButtonVisible";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button]] =
      ".connect_screen.dropCallButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button_text_color]] =
      ".connect_screen.dropCallButtonTextColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_dropcall_button_background_color]] =
      ".connect_screen.dropCallButtonBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button]] =
      ".connect_screen.callBackButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button_text_color]] =
      ".connect_screen.callBackButtonTextColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_button_background_color]] =
      ".connect_screen.callBackButtonBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_phone_number_text]] =
      ".connect_screen.callBackPhoneNumberText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_send_request_Button]] =
      ".connect_screen.callBackSendRequestButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_cancel_Button]] =
      ".connect_screen.callBackCancelButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_callback_close_browser_label]] =
      ".connect_screen.callBackCloseBrowserMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_reject_camera_permission_label]] =
      ".connect_screen.guestRejectCameraPermissionMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_auto_full_screen]] =
      ".connect_screen.connectButtonAutoFullScreen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connect_button_hidden]] =
      ".connect_screen.connectButtonHidden";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_use_rectangle_button]] =
      ".connect_screen.useRectangleButton";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_status_label]] =
      ".connect_screen.connectingStatusLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_status_audio]] =
      ".connect_screen.connectingStatusAudio";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_onhold_status_label]] =
      ".connect_screen.onholdStatusLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_image]] =
      ".connect_screen.waitImage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_video]] =
      ".connect_screen.waitVideo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_screen_embedded_url]] =
      ".connect_screen.waitScreenEmbeddedUrl";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_screen_embedded_title]] =
    ".connect_screen.waitScreenEmbeddedTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_screen_embedded_style]] =
    ".connect_screen.waitScreenEmbeddedStyle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_wait_background_color]] =
      ".connect_screen.waitBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_auto_connect]] =
      ".connect_screen.autoConnect";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_no_operator_available_label]] =
      ".connect_screen.noOperatorAvailableLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_info_position_top]] =
      ".connect_screen.waitTop";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_info_position_width]] =
      ".connect_screen.waitWidth";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_invisible_status_label]] =
      ".connect_screen.invisibleStatusLabel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_label_color]] =
      ".connect_screen.connectingLabelColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_label_size]] =
      ".connect_screen.connectingLabelSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_connecting_label_background_color]] =
      ".connect_screen.connectingLabelBackgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_voice_call_screen_title]] =
      ".connect_screen.voiceCallScreenTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_auto_leave]] =
      ".connect_screen.autoLeave";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_auto_answer]] =
      ".connect_screen.autoAnswer";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless]] =
      ".connect_screen.touchless";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_message]] =
      ".connect_screen.touchless_message";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection]] =
      ".connect_screen.touchless_detection";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection_ratio]] =
      ".connect_screen.touchless_detection_ratio";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection_movement]] =
      ".connect_screen.touchless_detection_movement";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_detection_confidence]] =
      ".connect_screen.touchless_detection_confidence";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_autodrop]] =
      ".connect_screen.touchless_autodrop";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_touchless_autodrop_message]] =
      ".connect_screen.touchless_autodrop_message";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.connect_screen_mobile_footer]] =
      ".connect_screen.mobileFooter";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_enabled]] = ".video_screen";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_avatar_image_src]] =
      ".video_screen.avatarImageSrc";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_pip_position_fixed
      ]] =
      ".video_screen.pipPositionFixed";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_mic_mute_warning_message]] =
      ".video_screen.micMuteWarningMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_font_color]] =
      ".video_screen.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_background_color]] =
      ".video_screen.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_top]] = ".video_screen.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_bottom]] = ".video_screen.bottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_left]] = ".video_screen.left";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_right]] = ".video_screen.right";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_width]] = ".video_screen.width";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_mcu_title]] = ".video_screen.mcuTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.video_screen_data_title]] = ".video_screen.dataTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.loopback_panel_enabled]] = ".loopback_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_enabled]] = ".settings_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_title]] = ".settings_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_relay_candidate_only]] =
      ".settings_panel.relayCandidateOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_disable_audio_only]] =
      ".settings_panel.disableAudioOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_disable_video_only]] =
      ".settings_panel.disableVideoOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_audio_codecs]] =
      ".settings_panel.audio_setting.codecs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_audio_toggle]] =
      ".settings_panel.audio_setting.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_primary_video_codecs]] =
      ".settings_panel.video_setting.codecs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_secondary_video_codecs]] =
      ".settings_panel.secondary_video_setting.codecs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_video_toggle]] =
      ".settings_panel.video_setting.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_microphone_test_toggle]] =
      ".settings_panel.microphone_setting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_speaker_test_toggle]] =
      ".settings_panel.speaker_setting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_default_volume]] =
      ".settings_panel.default_volume";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_camera_rotation]] =
      ".settings_panel.camera_rotation";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_title]] =
      ".settings_panel.resolution_setting.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_auto_title]] =
      ".settings_panel.resolution_setting.autoTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_options]] =
      ".settings_panel.resolution_setting.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_pc_value]] =
      ".settings_panel.resolution_setting.defaultPCValue";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_mobile_value]] =
      ".settings_panel.resolution_setting.defaultMobileValue";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_pc_secondary_value]
    ] = ".settings_panel.resolution_setting.defaultPCSecondaryValue";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_resolution_default_mobile_secondary_value]
    ] = ".settings_panel.resolution_setting.defaultMobileSecondaryValue";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_sharpness_first_resolution]
    ] = ".settings_panel.presentation_setting.sharpness_first.resolution";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_sharpness_first_framerate]
    ] = ".settings_panel.presentation_setting.sharpness_first.framerate";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_motion_first_resolution]
    ] = ".settings_panel.presentation_setting.motion_first.resolution";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.settings_panel_presentation_motion_first_framerate]
    ] = ".settings_panel.presentation_setting.motion_first.framerate";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_title]] =
      ".settings_panel.bandwidth_setting.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_auto_title]] =
      ".settings_panel.bandwidth_setting.autoTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_options]] =
      ".settings_panel.bandwidth_setting.options";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_default_pc_value]] =
      ".settings_panel.bandwidth_setting.defaultPCValue";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bandwidth_default_mobile_value]] =
      ".settings_panel.bandwidth_setting.defaultMobileValue";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_device_names]] = ".deviceNames";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_font_color]] =
      ".settings_panel.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_background_color]] =
      ".settings_panel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_top]] = ".settings_panel.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_bottom]] = ".settings_panel.bottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_left]] = ".settings_panel.left";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_right]] = ".settings_panel.right";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_width]] = ".settings_panel.width";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_run_tester_on_first_visit]] =
      ".settings_panel.runTesterOnFirstVisit";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_show_customized_video_muted_placeholder]] =
    ".settings_panel.showCustomizedVideoMutedPlaceholder";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_virtual_background_method]] =
      ".settings_panel.virtualBackgroundMethod";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_video_aspect_title]] =
      ".settings_panel.videoAspectTitle";

this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.settings_panel_video_aspect_default]] = ".settings_panel.videoAspectDefault";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_enabled]] = ".diagnostic_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_watchrtc_active]] =
      ".diagnostic_panel.watchRtcActive";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.diagnostic_panel_watchrtc_api_key]] =
      ".diagnostic_panel.watchRtcApiKey";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_enabled]] = ".chat_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_title]] = ".chat_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_with_api_user]] = ".chat_panel.chatWithAPIUser";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_with_api_user_title]] =
      ".chat_panel.chatWithAPIUserTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_disable_chat_with_web_user]] =
      ".chat_panel.disableChatWithWebUser";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_exit_button]] =
      ".chat_panel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_edit_button]] =
      ".chat_panel.editButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_done_button]] =
      ".chat_panel.doneButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_back_button]] =
      ".chat_panel.backbuttonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_start_chat_button]] =
      ".chat_panel.startChatButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_send_message_title]] =
      ".chat_panel.sendMessageFieldTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_font_color]] =
      ".chat_panel.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_font_size]] =
      ".chat_panel.fontSize";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_font_family]] =
      ".chat_panel.fontFamily";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_background_color]] =
      ".chat_panel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_top]] = ".chat_panel.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_bottom]] = ".chat_panel.bottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_left]] = ".chat_panel.left";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_right]] = ".chat_panel.right";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_width]] = ".chat_panel.width";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_add_participant_button_tooltip]] =
      ".chat_panel.addParticipantButtonTooltip";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_add_participant_button_text]] =
      ".chat_panel.addParticipantButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_start_group_chat_button]] =
      ".chat_panel.startGroupChatButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_disabled_message]] =
      ".chat_panel.chatDisabledText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_option]] =
      ".chat_panel.chatAudioAlertOption";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_type]] =
      ".chat_panel.chatAudioAlertType";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_frequency]] =
      ".chat_panel.chatAudioAlertFrequency";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_duration]] =
      ".chat_panel.chatAudioAlertDuration";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_chat_audio_alert_interval]] =
      ".chat_panel.chatAudioAlertInterval";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_silence_chat_audio_alerts]] =
      ".chat_panel.silenceChatAudioAlerts.text";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_silence_chat_audio_alerts_host_only]] =
      ".chat_panel.silenceChatAudioAlertsHostOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.chat_panel_silence_chat_audio_alerts_guest_only]] =
      ".chat_panel.silenceChatAudioAlertsGuestOnly";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_enabled]] = ".participant_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_exit_button]] =
      ".participant_panel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_connect_button]] =
      ".participant_panel.connectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_disconnect_button]] =
      ".participant_panel.disconnectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_start_monitor_button]] =
      ".participant_panel.startMonitorButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_whisper_button]] =
      ".participant_panel.whisperButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_exit_monitor_button]] =
      ".participant_panel.exitMonitorButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_select_button]] =
      ".participant_panel.selectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_unselect_button]] =
      ".participant_panel.unselectButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_hold_button]] =
      ".participant_panel.holdButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_resume_button]] =
      ".participant_panel.resumeButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_take_over_button]] =
      ".participant_panel.takeOverButtonText";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_transfer_to_queue_button_disabled]] =
      ".participant_panel.transferToQueueButtonDisabled";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_transfer_to_queue_button]] =
      ".participant_panel.transferToQueueButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_chat_with_conjunction_text]] =
      ".participant_panel.chatWithConjunctionText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_hold_conference_button]] =
      ".participant_panel.holdConferenceButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_resume_conference_button]] =
      ".participant_panel.resumeGuestConferenceButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_callback_confirm_button]] =
      ".participant_panel.callbackConfirmButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_callback_handle_external_button]] =
      ".participant_panel.callbackHandleExternalText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_participant_status]] =
      ".participant_panel.participantStatusText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_list_type]] =
      ".participant_panel.listTypes";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_list_sort_by_enter_time]] =
      ".participant_panel.sortByEnterTime";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_list_show_offline_endpoints]] =
      ".participant_panel.showOfflineEndpoints";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_list_show_offline_users]] =
      ".participant_panel.showOfflineUsers";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_font_color]] =
      ".participant_panel.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_background_color]] =
      ".participant_panel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_top]] = ".participant_panel.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_bottom]] =
      ".participant_panel.bottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_left]] =
      ".participant_panel.left";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_right]] =
      ".participant_panel.right";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_width]] =
      ".participant_panel.width";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_max_participants_to_connect]] =
      ".participant_panel.maxParticipantsToConnect";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_disable_operator_guest_connect]] =
      ".participant_panel.disableOperatorGuestConnect";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.participant_panel_hide_guests_with_device_problems]] =
      ".participant_panel.hideGuestsWithDeviceProblems";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_enabled]] = ".presenceStatus";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_offline]] =
      ".presenceStatus.offline";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_online]] = ".presenceStatus.online";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_available]] =
      ".presenceStatus.available";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_alone_in_conf]] =
      ".presenceStatus.alone_in_conf";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_busy]] = ".presenceStatus.busy";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_invisible]] =
      ".presenceStatus.invisible";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_away]] = ".presenceStatus.away";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_work]] = ".presenceStatus.work";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_connecting]] =
      ".presenceStatus.connecting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_ringing]] =
      ".presenceStatus.ringing";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_onhold]] = ".presenceStatus.onhold";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_recording]] =
      ".presenceStatus.recording";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_ready]] =
      ".presenceStatus.ready";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_ready_timelapse]] =
      ".presenceStatus.readyTimelapse";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_custom1]] =
      ".presenceStatus.custom1";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_custom2]] =
      ".presenceStatus.custom2";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_custom3]] =
      ".presenceStatus.custom3";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.presence_status_custom4]] =
      ".presenceStatus.custom4";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_screen_panel_enabled]] = ".shareScreen_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_screen_panel_title]] =
      ".shareScreen_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_screen_panel_exit_button]] =
      ".shareScreen_panel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.content_share_panel_enabled]] = ".contentShare_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.content_share_panel_cursor_image]] = ".contentShare_panel.cursorImage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.content_share_panel_cursor_px]] = ".contentShare_panel.cursorPx";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_enabled]] =
      ".sharedFolder_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_title]] =
      ".sharedFolder_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_conference_files_title]] =
      ".sharedFolder_panel.conference_files";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_remove_conf_text]] =
      ".sharedFolder_panel.remove_conf";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_request_file_text]] =
      ".sharedFolder_panel.request_file";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_request_all_text]] =
      ".sharedFolder_panel.request_all";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_local_files_title]] =
      ".sharedFolder_panel.local_files";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_remove_local_text]] =
      ".sharedFolder_panel.remove_local";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_offer_file_text]] =
      ".sharedFolder_panel.offer_file";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_load_file_text]] =
      ".sharedFolder_panel.load_file";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_idle]] =
      ".sharedFolder_panel.idle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_started]] =
      ".sharedFolder_panel.started";
    this.flavorToLocalization[
      FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_transmitting]
    ] = ".sharedFolder_panel.transmitting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_finished]] =
      ".sharedFolder_panel.finished";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_file_sharing_status_failed]] =
      ".sharedFolder_panel.failed";
      this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.share_folder_panel_autosave]] =
      ".sharedFolder_panel.autosave";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.inspector_panel_enabled]] = ".inspector_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_enabled]] = ".record_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_title]] = ".record_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_warning_message]] =
      ".record_panel.warningMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_confirm_button]] =
      ".record_panel.confirmButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_audio_mode_text]] =
      ".record_panel.audioModeText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_video_mode_text]] =
      ".record_panel.videoModeText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_disabled_text]] =
      ".record_panel.disabledText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_local_only_text]] =
      ".record_panel.localOnlyText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_remote_only_text]] =
      ".record_panel.remoteOnlyText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_both_sides_text]] =
      ".record_panel.bothSidesText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_exit_button]] =
      ".record_panel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_auto_record]] =
      ".record_panel.autoRecord";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_send_to_ftp]] =
      ".record_panel.sendToFTP";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_audio_mode]] =
      ".record_panel.audioMode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_video_mode]] =
      ".record_panel.videoMode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_send_alert]] =
      ".record_panel.sendAlert";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.record_panel_filename_pattern]] =
      ".record_panel.filenamePattern";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_enabled]] = ".lock_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_auto_lock]] = ".lock_panel.autoLock";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_modalTitle]] =
      ".lock_panel.modalTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_exitText]] =
      ".lock_panel.exitText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_submitText]] =
      ".lock_panel.submitText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_submitBgColor]] =
      ".lock_panel.submitBgColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_submitTextColor]] =
      ".lock_panel.submitTextColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_backgroundColor]] =
      ".lock_panel.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_textColor]] =
      ".lock_panel.textColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.lock_panel_entryPlaceholder]] =
      ".lock_panel.entryPlaceholder";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_enabled]] = ".footer";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_font_color]] = ".footer.fontColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_font_family]] = ".footer.fontFamily";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_background_color]] =
      ".footer.backgroundColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_border_color]] = ".footer.borderColor";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_copyright]] = ".footer.copyright";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_menu]] = ".footer.menu";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.footer_social_menu]] = ".footer.social_menu";

    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.reporting_enabled]] = ".reporting";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.reporting_max_preview]] = ".reporting.maxPreview";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.reporting_max_download]] = ".reporting.maxDownload";

    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.error_message_enabled]] = ".errorMessages";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.error_message_list]] = ".errorMessages";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ice_config_enabled]] = ".iceConfigs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ice_config_list]] = ".iceConfigs";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.snapshot_panel_enabled]] = ".snapshot_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.snapshot_panel_video_mode]] =
      ".snapshot_panel.videoMode";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.snapshot_panel_file_name_pattern]] =
      ".snapshot_panel.filenamePattern";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.take_photo_enabled]] = ".take_photo";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.take_photo_resolution]] = ".take_photo.resolution";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.take_photo_framerate]] = ".take_photo.frameRate";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_enabled]] = ".map_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_hide_by_default]] =
      ".map_panel.hideByDefault";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_access_geo_title]] =
      ".map_panel.accessGeoTitle";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_default_latitude]] =
      ".map_panel.defaultLatitude";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_default_longitude]] =
      ".map_panel.defaultLongitude";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.map_panel_default_zoom]] = ".map_panel.defaultZoom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.notepad_enabled]] = ".notepad";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.notepad_template]] = ".notepad.template";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_enabled]] = ".ring_tone_panel";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_title]] = ".ring_tone_panel.title";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_confirm_button]] =
      ".ring_tone_panel.confirmButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_confirm_voice_button]] =
      ".ring_tone_panel.confirmVoiceButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_exit_button]] =
      ".ring_tone_panel.exitButtonText";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.ring_tone_panel_duration]] =
      ".ring_tone_panel.duration";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.loopback_panel_top]] = ".loopback_panel.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.loopback_panel_bottom]] = ".loopback_panel.bottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.loopback_panel_left]] = ".loopback_panel.left";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.loopback_panel_right]] = ".loopback_panel.right";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.loopback_panel_width]] = ".loopback_panel.width";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.inspector_panel_top]] = ".inspector_panel.top";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.inspector_panel_bottom]] =
      ".inspector_panel.bottom";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.inspector_panel_left]] = ".inspector_panel.left";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.inspector_panel_right]] = ".inspector_panel.right";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.inspector_panel_width]] = ".inspector_panel.width";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_enabled]] = ".twilioConfig";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_number]] = ".twilioConfig.number";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_allow_dial_out]] = ".twilioConfig.allowOperatorDialout";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_error_message]] = ".twilioConfig.errorMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_active]] = ".twilioConfig.gatherActive";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_message]] = ".twilioConfig.gatherMessage";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_message_delay]] = ".twilioConfig.gatherMessageDelay";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_timeout]] = ".twilioConfig.gatherTimeout";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.twilio_gather_pin_length]] = ".twilioConfig.pinLength";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.custom_css_enabled]] = ".customCSS";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.custom_css_apply_ada_styles]] = ".customCSS.applyADAStyles";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.custom_css_content]] = ".customCSS.content";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.rtc_config_enabled]] = ".rtcConfig";
    this.flavorToLocalization[FlavorParameterKey[FlavorParameterKey.rtc_config_bundle_policy]] = ".rtcConfig.bundlePolicy";

    // register localization to flavor
    this.localizationToFlavor = {};
    _.forEach(this.flavorToLocalization, (path: string, flavorKey: string) => {
      this.localizationToFlavor[path] = flavorKey;
    });
    // register panel to toolbar toggle
    this.panelToToolbarToggle = {};
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.settings_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_settings_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.lock_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_lock_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.chat_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_chat_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.participant_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_participants_toggle] ||
      FlavorParameterKey[FlavorParameterKey.toolbar_items_incoming_queue_toggle] ||
      FlavorParameterKey[FlavorParameterKey.toolbar_items_operators_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.share_screen_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_share_screen_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.inspector_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_inspector_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.record_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_record_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.map_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_map_toggle];
    this.panelToToolbarToggle[FlavorParameterKey[FlavorParameterKey.snapshot_panel_enabled]] =
      FlavorParameterKey[FlavorParameterKey.toolbar_items_snapshot_toggle];
  }
  public static setLocalizationValueByFlavorKey(localizationData: ILocalization, flavorKey: string, value: any): void {
    if (!localizationData || !flavorKey) {
      return;
    }
    if (!this.flavorToLocalization) {
      this.register();
    }
    let path: string = this.flavorToLocalization[flavorKey];
    if (!path) {
      return;
    }
    if (Array.isArray(value) && _.isEmpty(value)) {
      value = undefined;
    }
    let pathList: string[] = _.compact(path.split("."));
    var current: any = localizationData;
    let childPath: string;
    while (pathList.length) {
      childPath = pathList.shift();
      if (value == null && current[childPath] == null) {
        break;
      }
      if ((flavorKey.endsWith("_host_only") || flavorKey.endsWith("_guest_only")) && typeof value !== "boolean") {
        break;
      }
      if (pathList.length > 0) {
        if (current[childPath] == null || typeof current[childPath] !== "object") {
          current[childPath] = {};
        }
        current = current[childPath];
      } else {
        if (flavorKey === "login_enabled") {
          this.setLocalizationValueByFlavorKey(localizationData, "login_panel_enabled", value);
          this.setLocalizationValueByFlavorKey(localizationData, "login_menu_enabled", value);
        } else if (value == null) {
          current[childPath] = undefined;
          if (flavorKey.endsWith("_enabled") && this.panelToToolbarToggle[flavorKey]) {
            this.setLocalizationValueByFlavorKey(localizationData, this.panelToToolbarToggle[flavorKey], value);
          }
        } else if (flavorKey.endsWith("_enabled")) {
          current[childPath] = value ? current[childPath] || {enabled: true} : null;
        } else if (flavorKey === "topnav_menu_items" || flavorKey.endsWith("_menu")) {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forOwn(value, (key: string, v: string) => {
            current[childPath].push({ url: key, text: v });
          });
        } else if (flavorKey === "settings_panel_resolution_options") {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forEach(value, (resolution: any) => {
            if (resolution.width && resolution.height) {
              current[childPath].push({
                text: resolution.width + " x " + resolution.height,
                value: {
                  width: resolution.width,
                  height: resolution.height,
                },
              });
            }
          });
        } else if (flavorKey === "settings_panel_bandwidth_options") {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forEach(value, (bandwidth: string) => {
            if (!bandwidth || isNaN(Number(bandwidth))) {
              return;
            }
            current[childPath].push({ text: bandwidth, value: Number(bandwidth) });
          });
        } else if (flavorKey === "ice_config_list") {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forEach(value, (ice_server: any) => {
            if (!ice_server.host) {
              return;
            }
            if (ice_server.username || ice_server.authSecret) {
              current[childPath].push({
                url: ice_server.host,
                username: ice_server.username,
                credential: ice_server.password,
                authSecret: ice_server.authSecret
              });
            } else {
              current[childPath].push({ url: ice_server.host });
            }
          });
        } else if (value.hasOwnProperty("trueValue")) {
          if (flavorKey === "settings_panel_audio_toggle" || flavorKey === "settings_panel_video_toggle") {
            current[childPath] = {};
            current[childPath]["on"] = {};
            current[childPath]["on"].text = value.trueValue;
            current[childPath]["on"].value = true;
            current[childPath]["off"] = {};
            current[childPath]["off"].text = value.falseValue;
            current[childPath]["off"].value = false;
          } else if (
            flavorKey === "settings_panel_microphone_test_toggle" ||
            flavorKey === "settings_panel_speaker_test_toggle"
          ) {
            current[childPath] = {};
            current[childPath].startTestingText = value.trueValue;
            current[childPath].endTestingText = value.falseValue;
          } else if (value.trueValue) {
            current[childPath] = {};
            current[childPath].text = value.trueValue;
            current[childPath].altText = value.falseValue;
          }
        } else {
          current[childPath] = value;
        }
      }
    }
  }
  public static setFlavorValuesByLocalizationData(
    flavor: IFlavor,
    currentDataEntry: any,
    currentEntryPath: string = ""
  ) {
    if (!flavor || !currentDataEntry) {
      return;
    }
    if (!this.flavorToLocalization) {
      this.register();
    }
    for (var property in currentDataEntry) {
      if (currentDataEntry.hasOwnProperty(property)) {
        if (
          this.localizationToFlavor[currentEntryPath + "." + property] &&
          flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]]
        ) {
          flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value =
            currentDataEntry[property];
        }
        if (typeof currentDataEntry[property] === "object") {
          if (flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]]) {
            if (property.toLowerCase().endsWith("menu")) {
              if (currentDataEntry[property] && !_.isEmpty(currentDataEntry[property])) {
                flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = {};
                _.forEach(currentDataEntry[property], (menu: { text: string; url: string }) => {
                  flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value[menu.text] =
                    menu.url;
                });
              }
            } else if (currentEntryPath.endsWith("toolbar_items")) {
              flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = {
                trueValue: currentDataEntry[property] ? currentDataEntry[property].text : null,
                falseValue: currentDataEntry[property] ? currentDataEntry[property].altText : null,
              };
              this.setFlavorValuesByLocalizationData(
                flavor,
                currentDataEntry[property],
                currentEntryPath + "." + property
              );
            } else if (
              currentEntryPath + "." + property === ".settings_panel.audio_setting.options" ||
              currentEntryPath + "." + property === ".settings_panel.video_setting.options"
            ) {
              flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = {
                trueValue: currentDataEntry[property]["on"].text,
                falseValue: currentDataEntry[property]["off"].text,
              };
            } else if (
              currentEntryPath + "." + property === ".settings_panel.microphone_setting" ||
              currentEntryPath + "." + property === ".settings_panel.speaker_setting"
            ) {
              flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = {
                trueValue: currentDataEntry[property].startTestingText,
                falseValue: currentDataEntry[property].endTestingText,
              };
            } else if (currentEntryPath + "." + property === ".settings_panel.resolution_setting.options") {
              if (currentDataEntry[property] && !_.isEmpty(currentDataEntry[property])) {
                flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = [];
                _.forEach(currentDataEntry[property], (resolution: any) => {
                  flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value.push({
                    width: resolution.value.width,
                    height: resolution.value.height,
                  });
                });
              }
            } else if (currentEntryPath + "." + property === ".settings_panel.bandwidth_setting.options") {
              if (currentDataEntry[property] && !_.isEmpty(currentDataEntry[property])) {
                flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = [];
                _.forEach(currentDataEntry[property], (bandwidth: any) => {
                  flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value.push(
                    bandwidth.value
                  );
                });
              }
            } else if (currentEntryPath + "." + property === ".iceConfigs") {
              if (currentDataEntry[property] && !_.isEmpty(currentDataEntry[property])) {
                flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value = [];
                _.forEach(currentDataEntry[property], (iceServer: any) => {
                  flavor.parameters[this.localizationToFlavor[currentEntryPath + "." + property]].value.push({
                    host: iceServer.url,
                    username: iceServer.username,
                    password: iceServer.credential,
                    authSecret: iceServer.authSecret
                  });
                });
              }
            } else {
              this.setFlavorValuesByLocalizationData(
                flavor,
                currentDataEntry[property],
                currentEntryPath + "." + property
              );
            }
          } else {
            this.setFlavorValuesByLocalizationData(
              flavor,
              currentDataEntry[property],
              currentEntryPath + "." + property
            );
          }
        }
      }
    }
  }
  public static resolve(obj: any, path: string): any {
    let pathList: string[] = _.compact(path.split("."));
    var current = obj;
    while (pathList.length) {
      if (typeof current !== "object") {
        return undefined;
      }
      current = current[pathList.shift()];
    }
    return current;
  }
  public static iterate(obj: any, stack: string): void {
    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] === "object") {
          this.iterate(obj[property], stack + "." + property);
        } else {
          console.error("flavor-util missing", property + "   " + obj[property], stack + "." + property);
        }
      }
    }
  }
}
