/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { IEndpointRef } from "../endpoint/endpoint.interface";
import { IPosition } from "./position.interface";

export interface IVideo {
  endpoint: IEndpointRef;
  stream: any;
  position: IPosition;
  style?: any;
}

export enum VideoAspect {
  fit = "fit",
  fill = "fill"
}

export interface CropperPosition {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}
