<!--
    Copyright Compunetix Incorporated 2018
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng
-->
<ul class="tree">
  <tree-item
    *ngFor="let item of items; let last = last;"
    [item]="item"
    [isLast]="last"
    [unselectAvailable]="unselectAvailable"
    [multiSelectAvailable]="multiSelectAvailable"
    [deleteItemAvailable]="deleteItemAvailable"
    (select)="onSelect($event)"
    (remove)="treeViewRemoveTreeViewItem($event)"
    [levelSelectionAvailable]="levelSelectionAvailable"
    [levelOptions]="levelOptions"
    (levelSelectionChanged)="treeViewLevelSelectionChangedTreeViewItem($event)"
  ></tree-item>
</ul>
