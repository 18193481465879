<!--
    Copyright Compunetix Incorporated 2022
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  amaggi, frivolta
-->

<div class="agent-container">
  <last-update
    [lastUpdate]="lastUpdate | formatDate : 'hh:mm:ss a'"
    (refresh)="refreshData(true)">
  </last-update>

  <div class="agent-body">
    <div class="agent-outer">
      <!--  AGENTS SELECTION-->
      <div class="agents-selection d-none d-lg-block">
        <div class="card agents-selection-card">
          <div class="card-body agents-selection-card-body">
            <div class="agent-selection-header header-text">
              <div>Agents</div>
              <div class="agents-search">
                <button class="btn search-button"><i class="fas fa-search"></i></button>
                <input #agentSearch class="form-control" type="search" placeholder="Search Agent...">
              </div>
            </div>
            <div class="agent-selection-agents">
              <ul class="agents-ul">
                <li
                  class="agents-list"
                  *ngFor="let agent of agents | nameFilter : agentSearch.value; let i = index"
                  tabIndex="{{i}}"
                  (click)="selectAgent(agent.id)"
                  [ngClass]="{
                  'agent-selected' : agent.id === selectedAgent?.id,
                  'all-agents' : agent.id === 'all-agents'
                  }"
                >
                  <div
                    class="status-bullet"
                    [ngClass]="{'bullet-green': agent.status === agentStatus.Conferencing,
                            'bullet-blue' : agent.status === agentStatus.Available,
                            'bullet-yellow' : agent.status === agentStatus.Unavailable,
                            'bullet-transparent' : agent.status === null}"
                  ></div>
                  <div class="avatar fallback">
                    <img
                      class="avatar-image"
                      alt="Avatar Image"
                      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                      [ngStyle]="{'background-image': 'url(' + agent.avatarImageUrl + ')'}"
                    >
                  </div>
                  <div class="agent-info">
                    <div class="agent-name">{{agent.username}}</div>
                    <div class="agent-role">{{agent.role}}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="agent-selection-legend">
              <hr>
              <div class="legend">
                <div>
                  <div class="legend-item">
                    <div class="status-bullet legend-bullet bullet-green"></div>
                    <div>Conferencing</div>
                  </div>
                  <div class="legend-item">
                    <div class="status-bullet legend-bullet bullet-yellow"></div>
                    <div>Unavailable</div>
                  </div>
                </div>
                <div>
                  <div class="legend-item">
                    <div class="status-bullet legend-bullet bullet-blue"></div>
                    <div>Available</div>
                  </div>
                  <div class="legend-item">
                    <div class="status-bullet legend-bullet"></div>
                    <div>Offline</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TRENDS-->
      <div class="trends container-fluid">
        <!-- Header-->
        <div class="card header-card">
          <div class="card-body trends-header">
            <div class="row justify-content-between">
              <div class="col-lg-auto col-12">
                <div class="d-none d-lg-block">
                  <div class="header-text">Selected Agent</div>
                  <div class="header-agent-name">{{selectedAgent?.username}}</div>
                </div>

                <div class="d-lg-none">
                  <div class="form-group">
                    <select class="form-control"
                            [(ngModel)]="selectedAgentId"
                            (change)="selectAgent(selectedAgentId)">
                      <option
                        *ngFor="let agent of agents"
                        [ngValue]="agent.id">{{agent.username}}</option>
                    </select>
                  </div>
                </div>

              </div>

              <div class="col-lg-auto col-6">
                <bar-chart
                  *ngIf="selectedAgent?.id === 'all-agents'"
                  class="trends-header-all-agent-status"
                  [items]="barChartItems"
                ></bar-chart>
                <div
                  *ngIf="selectedAgent?.id !== 'all-agents'"
                  class="trends-header-agent-status"
                >
                  <div>
                    <div class="header-text">Status</div>
                    <div class="legend-item">
                      <div
                        class="status-bullet legend-bullet"
                        [ngClass]="{'bullet-green': selectedAgent?.status === agentStatus.Conferencing,
                            'bullet-blue' : selectedAgent?.status === agentStatus.Available,
                            'bullet-yellow' : selectedAgent?.status === agentStatus.Unavailable}"
                      ></div>
                      <div class="trends-agent-status">{{agentStatus[selectedAgent?.status]}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-auto col-6">
                <div class="trends-header-status-duration" *ngIf="selectedAgent?.id !== 'all-agents'">
                  <div class="header-text">Status Duration</div>
                  <div class="trends-status-duration">{{statusDurationTimeString}}</div>
                </div>
              </div>
            </div>
            <div class="row justify-content-between" *ngIf="selectedAgent?.id !== 'all-agents'">
              <div class="col-lg-auto col-12">
                <div class="d-none d-lg-block">
                  <div class="header-agent-skillset">SkillSet: {{selectedAgent?.skillSet || ""}}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- Charts-->
        <div class="calls-chart h-100">
          <div class="calls-card-header">
            <div class="calls-card-header-title">Trends</div>
            <range-picker
              [selectedAggregation]="selectedAggregation"
              [selectedDates]="selectedDates"
              (rangePickerChanged)="getTrends($event)">

            </range-picker>
          </div>

          <div class="card calls-card trend-card">
            <div class="card-body">
              <trends-bar-chart [chartData]="trendsCallsData"
                                [backgroundColors]="callsBackgroundColors"
                                [hoverBackgroundColors]="callsHoverBackgroundColors"
                                [chartTitle]="'Calls'"></trends-bar-chart>
            </div>
          </div>
          <div class="card calls-card trend-card">
            <div class="card-body">
              <trends-bar-chart class="d-block h-100"
                                [chartData]="trendsTimeData"
                                [chartTitle]="'Time (min)'"
                                [backgroundColors]="timeBackgroundColors"
                                [hoverBackgroundColors]="timeHoverBackgroundColors"
                                [tooltipFormatterFn]="formatTime"></trends-bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
