/**
 * Copyright Compunetix Incorporated 2018-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { IConfig } from "./config.interface";
import { IParameter } from "../form/parameter.interface";
/**
 * Config util
 */
export class ConfigUtil {
  /**
   * map from config data to form key
   */
  public static configToForm: {
    [configPath: string]: string;
  };

  /**
   * map from form key to config data
   */
  public static formToConfig: {
    [formKey: string]: string;
  };

  public static register() {
    // register form key to config path
    this.formToConfig = {};
    this.formToConfig["ice_enabled"] = ".ICEConfig";
    this.formToConfig["ice_servers"] = ".ICEConfig";
    this.formToConfig["pws_enabled"] = ".PWSConfig";
    this.formToConfig["pws_host_ip"] = ".PWSConfig.PWSHostIP";
    this.formToConfig["pws_host_port"] = ".PWSConfig.PWSHostPort";
    this.formToConfig["pws_username"] = ".PWSConfig.PWSUsername";
    this.formToConfig["pws_password"] = ".PWSConfig.PWSPassword";
    this.formToConfig["pws_redirect_paths"] = ".PWSConfig.PWSPaths";
    this.formToConfig["ssl_enabled"] = ".SSLConfig";
    this.formToConfig["ssl_cert"] = ".SSLConfig.cert";
    this.formToConfig["ssl_key"] = ".SSLConfig.key";
    this.formToConfig["ssl_chain"] = ".SSLConfig.chain";
    this.formToConfig["web_enabled"] = ".WebConfig";
    this.formToConfig["web_proxy_paths"] = ".WebConfig.AppProxy";
    this.formToConfig["web_redirect_paths"] = ".WebConfig.PathRedirects";
    this.formToConfig["web_cors_white_list"] = ".WebConfig.CorsWhiteList";
    this.formToConfig["web_strict_content_security"] = ".WebConfig.StrictContentSecurity";
    this.formToConfig["web_csp_white_list"] = ".WebConfig.CSPWhiteList";
    this.formToConfig["web_embed_white_list"] = ".WebConfig.EmbedWhiteList";
    this.formToConfig["web_use_http_redirect"] = ".WebConfig.UseHttpRedirect";
    this.formToConfig["web_domain"] = ".WebConfig.Domain";
    this.formToConfig["web_use_secure_cookie"] = ".WebConfig.UseSecureCookie";
    this.formToConfig["web_entry_submit_limiter"] = ".WebConfig.EntrySubmitLimiter";
    this.formToConfig["web_version_number"] = ".WebConfig.VersionNumber";
    this.formToConfig["web_http_port"] = ".WebConfig.HttpPort";
    this.formToConfig["web_https_port"] = ".WebConfig.HttpsPort";
    this.formToConfig["web_server_disconnect_timeout"] = ".WebConfig.SERVER_DISCONNECT_TIMEOUT";
    this.formToConfig["ftp_enabled"] = ".FTPConfig";
    this.formToConfig["ftp_protocol"] = ".FTPConfig.FTPProtocol";
    this.formToConfig["ftp_host"] = ".FTPConfig.FTPHost";
    this.formToConfig["ftp_port"] = ".FTPConfig.FTPPort";
    this.formToConfig["ftp_account_username"] = ".FTPConfig.FTPUsername";
    this.formToConfig["ftp_account_password"] = ".FTPConfig.FTPPassword";
    this.formToConfig["ftp_upload_folder_path"] = ".FTPConfig.FTPFolderPath";
    this.formToConfig["db_enabled"] = ".DBConfig";
    this.formToConfig["db_use_pubsub"] = ".DBConfig.UsePubSub";
    this.formToConfig["db_log_reports"] = ".DBConfig.LogUserReports";
    this.formToConfig["db_log_client_events"] = ".DBConfig.logClientEvents";
    this.formToConfig["db_log_chat_messages"] = ".DBConfig.logChatMessages";
    this.formToConfig["db_historical_ttl"] = ".DBConfig.historicalTTL";
    this.formToConfig["outlook_enabled"] = ".OutlookConfig";
    this.formToConfig["outlook_allowOutlookLogin"] = ".OutlookConfig.allowOutlookLogin";
    this.formToConfig["outlook_appId"] = ".OutlookConfig.appId";
    this.formToConfig["outlook_appSecret"] = ".OutlookConfig.appSecret";
    this.formToConfig["outlook_tenantId"] = ".OutlookConfig.tenantId";
    this.formToConfig["vcc_enabled"] = ".VccConfig";
    this.formToConfig["vcc_call_routing_type"] = ".VccConfig.callRoutingType";
    this.formToConfig["vcc_calls_statistics_save"] = ".VccConfig.callsStatisticsSave";
    this.formToConfig["vcc_calls_statistics_rate"] = ".VccConfig.callsStatisticsRate";
    this.formToConfig["vcc_calls_client_refresh_rate"] = ".VccConfig.clientRefreshRate";
    this.formToConfig["vcc_ewt_moving_average"] = ".VccConfig.ewtMovingAverage";
    this.formToConfig["vcc_ewt_refresh_period"] = ".VccConfig.ewtRefreshPeriod";
    this.formToConfig["collect_logs_enabled"] = ".CollectLogs";
    this.formToConfig["collect_logs_mode"] = ".CollectLogs.mode";
    this.formToConfig["collect_logs_server_url"] = ".CollectLogs.serverUrl";
    this.formToConfig["collect_logs_auth_key"] = ".CollectLogs.authKey";
    this.formToConfig["collect_logs_customer"] = ".CollectLogs.customer";
    this.formToConfig["collect_logs_environment"] = ".CollectLogs.environment";
    this.formToConfig["collect_logs_application"] = ".CollectLogs.application";
    this.formToConfig["collect_logs_max_items"] = ".CollectLogs.maxItems";
    this.formToConfig["collect_logs_webrtc_statistics"] = ".CollectLogs.webrtcStatistics";
    this.formToConfig["collect_logs_stat_frequency"] = ".CollectLogs.statFrequency";
    this.formToConfig["collect_logs_trigger_peer_connection"] = ".CollectLogs.peerConnection";
    this.formToConfig["collect_logs_trigger_server_connection"] = ".CollectLogs.serverConnection";
    this.formToConfig["collect_logs_trigger_ice_negotiation"] = ".CollectLogs.iceNegotiation";
    this.formToConfig["collect_logs_trigger_device_acquisition"] = ".CollectLogs.deviceAcquisition";
    this.formToConfig["collect_logs_trigger_call_complete_trace"] = ".CollectLogs.callCompleteTrace";

    // register config data to form key
    this.configToForm = {};
    _.forEach(this.formToConfig, (path: string, formKey: string) => {
      this.configToForm[path] = formKey;
    });
  }
  public static setConfigValueByFormKey(configData: IConfig, formKey: string, value: any): void {
    if (!configData || !formKey) {
      return;
    }
    if (!this.formToConfig) {
      this.register();
    }
    let path: string = this.formToConfig[formKey];
    if (!path) {
      return;
    }
    let pathList: string[] = _.compact(path.split("."));
    var current: any = configData;
    let childPath: string;
    while (pathList.length) {
      childPath = pathList.shift();
      if (pathList.length > 0) {
        if (current[childPath] == null || typeof current[childPath] === "boolean") {
          current[childPath] = {};
        }
        current = current[childPath];
      } else {
        if (value == null) {
          current[childPath] = undefined;
        } else if (formKey.endsWith("_enabled")) {
          current[childPath] = value ? current[childPath] || {enabled: true} : undefined;
        } else if (formKey === "ice_servers") {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forEach(value, (ice_server: any) => {
            if (!ice_server.host) {
              return;
            }
            if (ice_server.username || ice_server.authSecret) {
              current[childPath].push({
                urls: JSON.parse(ice_server.host),
                username: ice_server.username,
                credential: ice_server.password,
                authSecret: ice_server.authSecret
              });
            } else {
              current[childPath].push({ urls: JSON.parse(ice_server.host) });
            }
          });
        } else if (formKey === "web_redirect_paths" || formKey === "web_proxy_paths") {
          if (_.isEmpty(value)) {
            current[childPath] = undefined;
            continue;
          }
          current[childPath] = [];
          _.forOwn(value, (target: string, source: string) => {
            current[childPath].push({ source: source, target: target });
          });
        } else {
          current[childPath] = value;
        }
      }
    }
  }
  public static setFormValuesByConfigData(
    form: {
      [key: string]: IParameter;
    },
    currentDataEntry: any,
    currentEntryPath: string = ""
  ) {
    if (!form || !currentDataEntry) {
      return;
    }
    if (!this.configToForm) {
      this.register();
    }
    for (var property in currentDataEntry) {
      if (currentDataEntry.hasOwnProperty(property)) {
        if (
          this.configToForm[currentEntryPath + "." + property] &&
          form[this.configToForm[currentEntryPath + "." + property]]
        ) {
          form[this.configToForm[currentEntryPath + "." + property]].value = currentDataEntry[property];
        }
        if (typeof currentDataEntry[property] === "object") {
          if (form[this.configToForm[currentEntryPath + "." + property]]) {
            if (currentEntryPath + "." + property === ".ICEConfig") {
              if (currentDataEntry[property] && !_.isEmpty(currentDataEntry[property])) {
                form[this.configToForm[currentEntryPath + "." + property]].value = [];
                _.forEach(currentDataEntry[property], (iceServer: any) => {
                  iceServer.urls = iceServer.urls || iceServer.url || "";
                  form[this.configToForm[currentEntryPath + "." + property]].value.push({
                    host: JSON.stringify(iceServer.urls),
                    username: iceServer.username,
                    password: iceServer.credential,
                    authSecret: iceServer.authSecret
                  });
                });
              }
            } else if (
              currentEntryPath + "." + property === ".WebConfig.PathRedirects" ||
              currentEntryPath + "." + property === ".WebConfig.AppProxy"
            ) {
              form[this.configToForm[currentEntryPath + "." + property]].value = {};
              _.forEach(currentDataEntry[property], (redirectPath: { source: string; target: string }) => {
                form[this.configToForm[currentEntryPath + "." + property]].value[redirectPath.source] =
                  redirectPath.target;
              });
            } else {
              this.setFormValuesByConfigData(form, currentDataEntry[property], currentEntryPath + "." + property);
            }
          } else {
            this.setFormValuesByConfigData(form, currentDataEntry[property], currentEntryPath + "." + property);
          }
        }
      }
    }
  }
}
