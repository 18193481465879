/**
 * Copyright Compunetix Incorporated 2018-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

/**
 * interface for customer report
 */
export interface IReportDefinition {
  reportType: ReportType;
  fields: IReportField[];
}
/**
 * interface for customer report field
 */
export interface IReportField {
  fieldName: string;
  displayName: string;
}

export interface ISystemReportRecord {
  date?: string;
  timeslot?: string;
  calloffered?: number;
  videoCallOffered?: number;
  audioCallOffered?: number;
  callanswered?: number;
  videoCallAnswered?: number;
  audioCallAnswered?: number;
  abandonedcall?: number;
  videoAbandonedCall?: number;
  audioAbandonedCall?: number;
  onlineagents?: number;
  totalholdtime?: string;
  totalhandletime?: string;
  averagehandletime?: string;
  averagewaittime?: string;
  transferredcall: number;
  partialData?: string;
}

export interface IAgentSummaryRecord {
  date?: string;
  username?: string;
  realName?: string;
  companyName: string;
  loginat?: string;
  logoutat?: string;
  availabletime?: string;
  callanswered?: number;
  callsrejected?: number;
  transferReceived?: number;
  totalholdtime?: string;
  idletime?: string;
  numberoftransfer?: number;
  averagehandletime?: string;
  totalhandletime?: string;
  totalcustom1time?: string;
  totalcustom2time?: string;
  totalcustom3time?: string;
  totalcustom4time?: string;
  totalreadytime?: string;
  language?: string;
  category?: string;
}

export interface IClientSummaryRecord {
  date?: string;
  name?: string;
  rtcId?: string;
  phoneNumber?: string;
  callType?: string;
  mobilenumber?: string;
  policynumber?: string;
  callringat?: string;
  callstartat?: string;
  callendat?: string;
  totalholdtime?: string;
  // device info
  browser?: string;
  version?: string;
  os?: string;
  isMobile?: string;
  totalinteractiontime?: string;
  totalhandletime?: string;
  totalidletime?: string;
  totalwaittime?: string;
  answeredby?: string;
  answeredbyRtcId?: string;
  language?: string;
  recordingid?: string;
  calldisconnectedby?: string;
  calldisconnectedreason?: string;
  formData?: string;
  note?: string;
  guestFormData?: string;
  transferredto?: string;
  sortField?: Date;
  category?: string;
}

export interface IKioskSummaryRecord {
  date?: string;
  name?: string;
  callinitiated?: number;
  callconnected?: number;
  callabandoned?: number;
  averagehandletime?: string;
  averagewaittime?: string;
}

export interface IConferenceReportRecord {
  // system Id
  confId?: string;
  // conf name
  confName?: string;
  // group
  groupName?: string;
  // endpoint join time
  connectTime?: Date;
  // endpoint left time
  disconnectTime?: Date;
  // bill code
  billingCode?: string;
  // ep name
  endpointName?: string;
}

export interface IReservationEventRecord {
  agentName?: string;
  company?: string;
  appId?: string;
  name?: string;
  startTime?: string;
  duration?: string;
  status?: string;
  reason?: string;
  Logged?: string;
  group?: string;
  memo?: string;
}

/**
 * enum for report types
 */
export enum ReportType {
  VCCSystemReport,
  VCCAgentReport,
  VCCCustomerReport,
  OneClickReservationReport,
  ConferenceReport,
  VCCKioskReport,
  ChatReport
}

export interface IDevice {
  userAgent?: string;
  os?: string;
  browser?: string;
  version?: number;
  isMobile?: boolean;
}

export interface IBrowserResourceAccessEvent {
  timestamp?: Date;
  resource?: string;
  event?: string;
}

export enum BrowserResource {
  media = "MEDIA",
  geo = "GEOLOCATION"
}

export enum BrowserResourceAccessEvent {
  requesting = "REQUESTING",
  accessed = "ACCESSED",
  rejected = "REJECTED",
  failed = "FAILED"
}

export enum SystemReportFrequency {
  ByHour = "ByHour",
  ByDay = "ByDay",
  ByWeek = "ByWeek",
  ByMonth = "ByMonth"
}

