/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */

import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { TreeViewItem } from "./tree-item";

@Component({
  selector: "tree-view",
  templateUrl: "./tree-view.template.html"
})
export class TreeViewComponent {
  @Input()
  items: TreeViewItem[];

  @Input()
  unselectAvailable: boolean = false;

  @Input()
  multiSelectAvailable: boolean = false;

  @Input()
  deleteItemAvailable: boolean = false;

  @Output("select")
  onSelectEventEmitter: EventEmitter<TreeViewItem> = new EventEmitter<TreeViewItem>();

  @Output("remove")
  onRemoveEventEmitter: EventEmitter<TreeViewItem> = new EventEmitter<TreeViewItem>();

  @Input() levelSelectionAvailable: boolean = false;

  @Output("levelSelectionChanged")
  onLevelSelectionChangedEventEmitter: EventEmitter<TreeViewItem> = new EventEmitter<TreeViewItem>();

  @Input() levelOptions: any[];

  resetSelectionOnAllItems(items: TreeViewItem[], excludeItem: TreeViewItem) {
    _.forEach(items, (item: TreeViewItem) => {
      if (!excludeItem ||
        (excludeItem && excludeItem.text != item.text)) {
        item.selected = false;
      }
      if (item.children) {
        this.resetSelectionOnAllItems(item.children, excludeItem);
      }
    });
  }

  onSelect(selectedItem: TreeViewItem) {
    let saveSelection: boolean = selectedItem.selected ? selectedItem.selected : false;
    if (!this.multiSelectAvailable) {
      this.resetSelectionOnAllItems(this.items, selectedItem);
    }
    if (_.find(this.items, (item: TreeViewItem) => { return (item.text == selectedItem.text)})) {
      if (this.unselectAvailable) {
        selectedItem.selected = !saveSelection;
      }
      else {
        selectedItem.selected = true;
      }
    }
    this.onSelectEventEmitter.emit(selectedItem);
  }

  treeViewRemoveTreeViewItem(removeItem: TreeViewItem) {
    // First clear selection on all items
    this.resetSelectionOnAllItems(this.items, null);
    let itemIndex = _.findIndex(this.items, (item: TreeViewItem) => { return (item.text == removeItem.text) });
    if (itemIndex != -1) {
      // remove the item from the tree view (deletes children as well)
      this.items.splice(itemIndex, 1);
    }
    else {
      console.log("treeviewcomponent treeViewRemoveTreeViewItem no removeItem in items:", this.items);
    }
    this.onRemoveEventEmitter.emit(removeItem);
  }

  treeItemLevelSelectionChangedTreeViewItem(levelSelectionChangedItem: TreeViewItem, value: number) {
    let itemIndex = _.findIndex(this.items, (item: TreeViewItem) => { return (item.text == levelSelectionChangedItem.text) });
    if (itemIndex != -1) {
      // Update the level on the item from the tree view
      this.items[itemIndex].value.proficiencyLevel = value;
    }
    levelSelectionChangedItem.value.proficiencyLevel = value;
    this.onLevelSelectionChangedEventEmitter.emit(levelSelectionChangedItem);
  }

  treeViewLevelSelectionChangedTreeViewItem(levelSelectionChangedItem: TreeViewItem) {
    let itemIndex = _.findIndex(this.items, (item: TreeViewItem) => { return (item.text == levelSelectionChangedItem.text) });
    if (itemIndex != -1) {
      // Update the level on the item from the tree view
      this.items[itemIndex].value.proficiencyLevel = levelSelectionChangedItem.value.proficiencyLevel;
    }
    this.onLevelSelectionChangedEventEmitter.emit(levelSelectionChangedItem);
  }

}
